

// const Success = () => (
//     <div className='overflow-y-hidden'>
//       <div className="px-6 pb-6" style={{ fontFamily: '"Lato", sans-serif' }}>
//         {/* Code block starts */}
//         <dh-component>
//           {/* <section className="mx-auto container bg-white pt-16">
//             <div className="px-4 lg:px-0">
//               <div role="contentinfo" className="flex items-center flex-col px-4">
//                 <p tabIndex={0} className="focus:outline-none uppercase text-sm text-center text-gray-500 leading-none">in few easy steps</p>
//                 <h1 tabIndex={0} className="focus:outline-none text-4xl lg:text-4xl pt-4 font-extrabold text-center leading-tight text-gray-800 lg:w-7/12 md:w-9/12 xl:w-5/12">Create Beautiful Landing Pages Web Apps in a Jiffy</h1>
//               </div>
//             </div>
//           </section> */}
//           <section className="bg-slate-950 py-12 shadow-lg rounded">
//             <div tabIndex={0} aria-label="group of cards" className="focus:outline-none px-4 lg:px-0">
//               <div className="mx-auto container flex flex-wrap px-2 lg:px-24">
//                 <div tabIndex={0} aria-label="card 1" className="focus:outline-none flex sm:w-full md:w-1/2 lg:border-r md:border-r sm:border-r-0 border-black pb-10 lg:pt-10">
//                   <div className=" flex flex-shrink-0 mr-5 text-white">
//                     <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg1.svg" alt="drawer" />
//                   </div>
//                   <div className="md:w-9/12 lg:w-9/12">
//                     <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-5 text-gray-200">Ready to use components</h2>
//                     <p tabIndex={0} className="focus:outline-none text-base text-gray-400 text-sm leading-normal xl:w-10/12 pt-2">It provides a very simple start, no need to write a lot of code, you just import it and start the primitive components and create the ones you need.</p>
//                   </div>
//                 </div>
//                 <div tabIndex={0} aria-label="card 2" className="focus:outline-none flex sm:w-full md:w-1/2 lg:pb-10 lg:pt-10">
//                   <div className=" flex flex-shrink-0 sm:ml-0 md:ml-10 lg:ml-10 mr-5 text-white">
//                     <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg2.svg" alt="check" />
//                   </div>
//                   <div className="md:w-9/12 lg:w-9/12 ">
//                     <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-5 text-gray-200">Hight Quality UI you can reply on</h2>
//                     <p tabIndex={0} className="focus:outline-none text-base text-gray-400 text-sm leading-normal xl:w-10/12 pt-2">Modify the visual appearance of your site – including colors, fonts, margins and other style-related properties – with a sophisticated style.</p>
//                   </div>
//                 </div>
//                 <div tabIndex={0} aria-label="card 3" className="focus:outline-none flex sm:w-full md:w-1/2 lg:border-t md:border-t sm:border-t-0 lg:border-r md:border-r sm:border-r-0 border-white pt-10 lg:pb-20">
//                   <div className=" flex flex-shrink-0 mr-5 text-white">
//                     <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg3.svg" alt="html-tag" />
//                   </div>
//                   <div className="md:w-9/12 lg:w-9/12 ">
//                     <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-5 text-gray-200">Coded by Developers for Developers</h2>
//                     <p tabIndex={0} className="focus:outline-none text-base text-gray-400 text-sm leading-normal xl:w-10/12 pt-2">Instead of just giving you the tools to create your own site, they offer you a list of themes you can choose from. Thus a handy product.</p>
//                   </div>
//                 </div>
//                 <div tabIndex={0} aria-label="card 4" className="focus:outline-none flex sm:w-full md:w-1/2 lg:border-t md:border-t sm:border-t-0 border-black pt-10 lg:pb-20">
//                   <div className=" flex flex-shrink-0 sm:ml-0 md:ml-10 lg:ml-10 mr-5 text-white">
//                     <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg4.svg" alt="display" />
//                   </div>
//                   <div className="md:w-9/12 lg:w-9/12 ">
//                     <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-5 text-gray-200">The Last UI kit you’ll ever need</h2>
//                     <p tabIndex={0} className="focus:outline-none text-base text-gray-400 text-sm leading-normal xl:w-10/12 pt-2">We have chosen the bright color palettes that arouse the only positive emotions. The kit that simply assures to be loved by everyone.</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </dh-component>
//         {/* Code block ends */}
//       </div>
  
//     </div>
  
  
  
  
  
//   );
//   export default Success;
  
  
  
  
  
const Success = () => (
  <div className='overflow-y-hidden'>
    <div className="px-6 pb-6" style={{ fontFamily: '"Lato", sans-serif' }}>
      {/* Code block starts */}
      <dh-component>
        {/* <section className="mx-auto container bg-white pt-16">
          <div className="px-4 lg:px-0">
            <div role="contentinfo" className="flex items-center flex-col px-4">
              <p tabIndex={0} className="focus:outline-none uppercase text-sm text-center text-gray-500 leading-none">in few easy steps</p>
              <h1 tabIndex={0} className="focus:outline-none text-4xl lg:text-4xl pt-4 font-extrabold text-center leading-tight text-gray-800 lg:w-7/12 md:w-9/12 xl:w-5/12">Create Beautiful Landing Pages Web Apps in a Jiffy</h1>
            </div>
          </div>
        </section> */}
        <section className="bg-gray-900 py-12 shadow-lg rounded">
          <div tabIndex={0} aria-label="group of cards" className="focus:outline-none px-4 lg:px-0">
            <div className="mx-auto container flex flex-wrap px-2 lg:px-24">
              <div tabIndex={0} aria-label="card 1" className="p-6 focus:outline-none flex lg:w-full xl:w-1/2 lg:border-r md:border-r sm:border-r-0 border-black pb-10 lg:pt-10 transform transition duration-300 ease-in-out hover:bg-blue-500 hover:shadow-lg hover:translate-y-[-5px] rounded">
                <div className=" flex flex-shrink-0 mr-5 text-white">
                  <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg1.svg" alt="drawer" />
                </div>
                <div className="md:w-9/12 lg:w-9/12">
                  <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-5 text-gray-200">Ready to upload content</h2>
                  <p tabIndex={0} className="focus:outline-none text-base text-gray-300 text-sm leading-normal xl:w-10/12 pt-2">No need to get your payment method verified; start by uploading your content for free and start growing your audience.</p>
                </div>
              </div>
              <div tabIndex={0} aria-label="card 2" className="p-6 focus:outline-none flex lg:w-full xl:w-1/2 lg:pb-10 lg:pt-10 transform transition duration-300 ease-in-out hover:bg-blue-500 hover:shadow-lg hover:translate-y-[-5px] rounded">
                <div className=" flex flex-shrink-0 sm:ml-0 xl:ml-10 mr-5 text-white">
                  <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg2.svg" alt="check" />
                </div>
                <div className="md:w-9/12 lg:w-9/12 ">
                  <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-5 text-gray-200">Targeted SEO</h2>
                  <p tabIndex={0} className="focus:outline-none text-base text-gray-300 text-sm leading-normal xl:w-10/12 pt-2">Promote your projects, articles, and research papers to elevate their visibility on search engines and improve their rankings on search consoles.</p>
                </div>
              </div>
              <div tabIndex={0} aria-label="card 3" className="p-6 mt-2 focus:outline-none flex lg:w-full xl:w-1/2 border-l border-t border-blue-500 pt-10 transform transition duration-300 ease-in-out hover:bg-blue-500 hover:shadow-lg hover:translate-y-[-5px] rounded">
                <div className=" flex flex-shrink-0 mr-5 text-white">
                  <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg3.svg" alt="html-tag" />
                </div>
                <div className="md:w-9/12 lg:w-9/12 ">
                  <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-5 text-gray-200">Coded by Developers for Developers</h2>
                  <p tabIndex={0} className="focus:outline-none text-base text-gray-300 text-sm leading-normal xl:w-10/12 pt-2">Instead of just working to create your own, use the coded components already available on the platform to design your next master piece.</p>
                </div>
              </div>
              <div tabIndex={0} aria-label="card 4" className="p-6 focus:outline-none flex lg:w-full xl:w-1/2 lg:border-t md:border-t sm:border-t-0 border-black pt-10 transform transition duration-300 ease-in-out hover:bg-blue-500 hover:shadow-lg hover:translate-y-[-5px] rounded">
                <div className=" flex flex-shrink-0 sm:ml-0 xl:ml-10 mr-5 text-white">
                  <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg4.svg" alt="display" />
                </div>
                <div className="md:w-9/12 lg:w-9/12 ">
                  <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-5 text-gray-200">Community</h2>
                  <p tabIndex={0} className="focus:outline-none text-base text-gray-300 text-sm leading-normal xl:w-10/12 pt-2">Elevate your profile and establish meaningful connections within the community by effectively managing your presence and maximizing your value.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </dh-component>
      {/* Code block ends */}
    </div>
  </div>
);

export default Success;
