import React, { useState, useEffect } from 'react';
import { HiOutlineDownload } from 'react-icons/hi';
import { SiGoogledrive, SiShadow } from 'react-icons/si';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import Pagination from '../partials/Pagination';
import AddDevProject from '../partials/AddDevProjects'
import { SearchIcon } from '@heroicons/react/solid';
import { FaCheckDouble, FaCloudDownloadAlt, FaRegEye, FaRegFilePowerpoint, FaUniversalAccess, FaUpload, FaWindowClose } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { FadeLoader } from 'react-spinners';
import { Interweave, Markup } from 'interweave';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';

const EmployeeData = () => {

  const websitePrefix = 'www.gigadevden.com';
  const adminId = localStorage.getItem('${websitePrefix}-id');

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm]);


  // Total number of pages
  const [totalPages, setTotalPages] = useState(1);
  const [offset, setOffset] = useState(0);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://gigadevden.com/webmyprojects.php?search=${encodeURIComponent(searchTerm)}&offset=${encodeURIComponent(offset)}&id=${encodeURIComponent(adminId)}`
      );
      const json = await response.json();
      console.log(json);

      if (Object.keys(json).length === 0) {
        setTotalPages(0);
      }
      else {

        if (json[0].total_rows % 12 === 0) {
          setTotalPages((json[0].total_rows) / 12);
        }
        else {
          setTotalPages(Math.floor(json[0].total_rows / 12) + 1);
        }
        console.log(json);
        setData(json);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };


  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData();
  }, [offset]);




  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setOffset((page - 1) * 12);
      setCurrentPage(page);
    }

    // Perform any other actions or fetch data based on the new page
  };


  const reloadPage = () => {

    fetchData();

  };


  const getSelectedData = async (id) => {
    try {
      const response = await fetch(
        `https://gigadevden.com/webdevprojectpage.php?id=${encodeURIComponent(id)}`
      );

      const json = await response.json();
      if (Object.keys(json).length === 0) {

      }
      else {

        setOwnerName(json[0].title)
        setExpiryDate(json[0].driveLink);
        setCVC(json[0].description.replace(/\\r\\n/g, '\n').replace(/\\/g, ''));
        setPreview("https://gigadevden.com" + json[0].image);

        setEdit(1);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [edit, setEdit] = useState(0);
  const [selectedId, setSelectedId] = useState(0);

  function setCurrentProjectId(id, title) {
    setSelectedId(id);
    getSelectedData(id);

    // title = title.replace(/[\s/]/g, "-");
    // window.location.href = '/project-page/' + +id + '/' + title;
  }


  const [ownerName, setOwnerName] = useState(''); // title
  const [cardNumber, setCardNumber] = useState(''); // image
  const [expiryDate, setExpiryDate] = useState(''); // download link
  const [cvc, setCVC] = useState('');  // description
  const [showModal, setShowModal] = useState(true);
  const [errors, setErrors] = useState({}); // Store errors for each field
  const [submissionOpen, setSubmissionOpen] = useState(true);
  const [added, setAdded] = useState(2);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(0);

  const handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };


  const updateData = async () => {


    const websitePrefix = 'www.gigadevden.com';
    const adminId = localStorage.getItem('${websitePrefix}-id');

    try {
      const formData = new FormData();
      formData.append('title', ownerName);
      formData.append('image', image);
      formData.append('driveLink', expiryDate);
      formData.append('description', cvc);
      formData.append('id', selectedId);

      const response = await fetch('https://gigadevden.com/webupdateproject.php', {
        method: 'POST',
        body: formData,
      });

      const json = await response.json();

      if (json.success === true) {
        // Added successfully

        toast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaCheckDouble className="mr-2 text-green-600" />
            <span>Project updated successfully</span>
          </div>,
          {
            className: 'custom-toast-success', // Add a custom CSS class for styling
          }
        );


      } else {
        // Failed to add

        toast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaWindowClose className="mr-2 text-red-600" />
            <span>Project updation failed</span>
          </div>,
          {
            className: 'custom-toast-success', // Add a custom CSS class for styling
          }
        );

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  };



  const handleSubmit = (e) => {
    e.preventDefault();

    updateData();

  };

  const handleSubmite = (e) => {
    e.preventDefault();

    //Empty handler for form

  };

  const handleSuccessfulAddition = () => {
    // Perform any necessary actions after successful addition

    // Call the reloadPage function to reload the page
    this.props.reloadPage();
  }

  const deleteProject = async () => {
    try {

      const response = await fetch(
        `https://gigadevden.com/webdeleteproject.php?id=${encodeURIComponent(selectedId)}`
      );
      const json = await response.json();
      console.log(json);

      if (json.success === true) {
        toast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaCheckDouble className="mr-2 text-red-600" />
            <span>Project deleted successfully</span>
          </div>,
          {
            className: 'custom-toast-success', // Add a custom CSS class for styling
          }
        );
        setEdit(0);
        reloadPage();
        closeOverlay();
      }
      else {

        toast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaWindowClose className="mr-2 text-red-600" />
            <span>Could not delete project</span>
          </div>,
          {
            className: 'custom-toast-success', // Add a custom CSS class for styling
          }
        );

      }
    } catch (error) {
      console.error('Error fetching data:', error);

    }
  };


  const [isOpen, setIsOpen] = useState(false);

  const openOverlay = () => {
    setIsOpen(true);
  };

  const closeOverlay = () => {
    setIsOpen(false);
  };


  // const getTimeDifference = (timestamp, time) => {
  //   const currentTime = moment(timestamp);
  //   const oldTime = moment(time);
  //   const duration = moment.duration(currentTime.diff(oldTime));

  //   if (duration.asMinutes() < 60) {
  //     return `${Math.round(duration.asMinutes())} min ago`;
  //   } else if (duration.asHours() < 24) {
  //     return `${Math.round(duration.asHours())} hour ago`;
  //   } else if (duration.asDays() < 7) {
  //     return `${Math.round(duration.asDays())}d ago`;
  //   } else if (duration.asMonths() < 1) {
  //     return `${Math.round(duration.asWeeks())}w ago`;
  //   } else if (duration.asYears() < 1) {
  //     return `${Math.round(duration.asMonths())} month ago`;
  //   } else {
  //     return `${Math.round(duration.asYears())} year ago`;
  //   }
  // };

  const getTimeDifference = (timestamp, time) => {
    const currentTime = moment.utc(timestamp);
    const oldTime = moment.utc(time, "YYYY-MM-DD HH:mm:ss");
    const duration = moment.duration(currentTime.diff(oldTime));

    if (duration.asMinutes() < 60) {
      return `${Math.round(duration.asMinutes())} min ago`;
    } else if (duration.asHours() < 24) {
      return `${Math.round(duration.asHours())} hour ago`;
    } else if (duration.asDays() < 7) {
      return `${Math.round(duration.asDays())}d ago`;
    } else if (duration.asMonths() < 1) {
      return `${Math.round(duration.asWeeks())}w ago`;
    } else if (duration.asYears() < 1) {
      return `${Math.round(duration.asMonths())} month ago`;
    } else {
      return `${Math.round(duration.asYears())} year ago`;
    }
  };



  return (
    <div id="devscrollblack" className="overflow-y-auto bg-gray-200" style={{ scrollbarWidth: 'thin' }}>

      {edit === 0 ? (
        <div>
          <header className="bg-white sticky top-0 z-40 h-14 pl-5 shadow">
            <div className="flex flex-row">

              <nav className="flex justify-center py-2 ml-6 mt-1">
                {/* other navbar content */}
                <form onSubmit={handleSearchSubmit} className="flex items-center">
                  <SearchIcon className="h-4 w-4 text-gray-500 hidden md:block" />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="ml-2 pl-3 pr-14 py-1 rounded border border-gray-300 w-48 sm:w-64"
                  />
                </form>
              </nav>

              <div className="mt-1">
                <AddDevProject reloadPage={reloadPage} />
              </div>


            </div>

          </header>

          <div className="container mx-auto min-h-screen mt-16 md:mt-0 md:py-16 px-4 md:px-8">

            {loading ? (
              <div className="flex justify-center items-center h-screen">
                {/* <div className="w-8 h-8 border-2 border-t-2 border-gray-300 rounded-full animate-spin"></div> */}
                <>
                  <div className="">
                    <div className="px-4 py-12 bg-transparent">
                      <div className="mx-auto flex justify-center">
                        <div className="relative">
                          <div className="w-[160px] h-[160px] border border-indigo-400 rounded-full" />
                          <div className="w-[140px] h-[140px] border border-indigo-400 rounded-full absolute top-2.5 right-2.5" />
                          <div>
                            <svg
                              className="absolute top-[22px] right-[26px] animate-spin infinite"
                              width={113}
                              height={113}
                              viewBox="0 0 113 113"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M56.7631 110.374C46.061 110.374 35.5993 107.2 26.7008 101.255C17.8023 95.3088 10.8668 86.8579 6.77128 76.9704C2.67576 67.083 1.60419 56.2031 3.69207 45.7066C5.77994 35.2102 10.9335 25.5686 18.501 18.001C26.0686 10.4335 35.7102 5.27994 46.2066 3.19207C56.7031 1.10419 67.583 2.17576 77.4704 6.27128C87.3579 10.3668 95.8088 17.3023 101.755 26.2008C107.7 35.0993 110.874 45.561 110.874 56.2631"
                                stroke="#4338CA"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeDasharray="16 16"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      {/* <p className="text-center text-gray-600 text-base mt-4">
              Your Request Is Being Loaded, Please Wait
            </p> */}
                    </div>
                  </div>
                </>
              </div>
            ) : (

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
                {data.map((item) => (



                  <div key={item.id} className="duration-300 hover:-translate-y-2 -translate-y-1 border p-4 rounded-lg shadow relative mt-12 mb-12 bg-white">
                    <div className="flex flex-col pt-8">
                      {/* Flex container */}
                      <div className="h-20p relative -mt-28">
                        {/* Divider one - 15% height */}
                        <div className="w-24 h-24 mx-auto">
                          <div className="w-full h-full rounded-full overflow-hidden shadow-lg">
                            <img
                              src={`https://www.gigadevden.com${item.image}`}
                              alt="Sample Image"
                              className="object-cover h-full w-full bg-white"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="h-80p">
                        {/* Divider two - 85% height */}

                        <div onClick={() => setCurrentProjectId(item.id, item.title)}>
                          <div className="flex items-center text-xs text-gray-500 mt-8">
                            <div className="flex items-center mr-4">
                              <FaCloudDownloadAlt />
                              <p className="ml-2">{item.downloads} downloads</p>
                            </div>

                            <div className="flex items-center">
                              <FaRegEye />
                              <p className="ml-2">{item.views} views</p>
                            </div>
                          </div>

                          <button className="text-left">
                            <h2 className="text-l font-bold mb-2 hover:underline mt-4">{item.title}</h2>
                            <p className="mb-4 text-sm">

                              {
                                ((<Markup content={item.description.replace(/\\/g, '')} />).props.content).length > 242
                                  ? <Markup content={((<Markup content={item.description.replace(/\\/g, '')} />).props.content).substring(0, 242).split(' ').slice(0, -1).join(' ') + ' ...'} />
                                  : <Markup content={item.description.replace(/\\/g, '')} />
                              }



                              {/* {item.description.replace(/\\r\\n|\\(?=("|\'))/g, '').replace(/\r\n/g, ' ').length > 242 ? item.description.replace(/\\r\\n|\\(?=("|\'))/g, '').replace(/\r\n/g, ' ').substring(0, 242).split(' ').slice(0, -1).join(' ') + ' ...' : item.description.replace(/\\r\\n|\\(?=("|\'))/g, '').replace(/\r\n/g, ' ')} */}
                            </p>
                          </button>

                          <div className="absolute top-1 right-1 mt-2 mr-2 bg-blue-500 text-white rounded-full w-auto p-2 h-6 flex items-center justify-center">
                            <p className="text-xs font-bold text-white">{getTimeDifference(item.timestamp, item.time)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                ))}
              </div>


            )}
          </div>


          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>

      ) : (

        <div>
          {showModal && (
            <div className="" id="modal">
              <div role="alert" className="container">
                <div className="pt-16 md:pt-8 relative py-8 px-5 bg-gray-100 shadow-md rounded border border-gray-400 w-full min-h-screen">
                  <div className="w-full flex justify-start text-gray-600 mb-3">



                    <FaRegFilePowerpoint className="h-12 w-12" />
                    {added === 1 && (
                      <p className="text-green-500 mt-4 ml-5 rounded pl-2 pr-2 pt-1 border border-green-500">Successfully added</p>
                    )
                    }

                    {added === 0 && (
                      <p className="text-red-500 mt-4 ml-5 rounded pl-2 pr-2 pt-1 border border-red-500">Failed to add</p>
                    )
                    }

                  </div>
                  <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">Project Details</h1>
                  <form onSubmit={handleSubmite}>



                    <div className="container mx-auto">
                      <form onSubmit={handleSubmite} className="text-center">
                        <label htmlFor="file-input" className="cursor-pointer">
                          <div className={`w-32 h-32 mx-auto my-4 border-4 border-dashed rounded-lg flex items-center justify-center ${error === 0 ? 'text-gray-500 border-gray-500' : 'text-red-500 border-red-500'} overflow-hidden`}>
                            {preview ? (
                              <img
                                src={preview}
                                alt="Preview"
                                className="object-cover w-full h-full"
                              />
                            ) : (
                              <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.5 12H22.515" stroke="#94A3B8" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M25.5 6H10.5C8.01472 6 6 8.01472 6 10.5V25.5C6 27.9853 8.01472 30 10.5 30H25.5C27.9853 30 30 27.9853 30 25.5V10.5C30 8.01472 27.9853 6 25.5 6Z" stroke="#94A3B8" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6 22.4999L12 16.4999C12.6841 15.8417 13.4601 15.4951 14.25 15.4951C15.0399 15.4951 15.8159 15.8417 16.5 16.4999L24 23.9999" stroke="#94A3B8" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M21 20.9999L22.5 19.4999C23.1841 18.8417 23.9601 18.4951 24.75 18.4951C25.5399 18.4951 26.3159 18.8417 27 19.4999L30 22.4999" stroke="#94A3B8" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>

                            )}
                          </div>
                        </label>
                        <input
                          id="file-input"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            const fileSize = file.size / 1024; // Size in KB

                            if (fileSize > 100) {
                              // Reset the file input value and show an error message
                              e.target.value = null;
                              // Update the state or display an error message to the user
                              // For example, you can update the `error` state to indicate the image size exceeded the limit
                              toast(
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <FaWindowClose className="mr-2 text-red-600" />
                                  <span>Project picture max size: 100KB</span>
                                </div>,
                                {
                                  className: 'custom-toast-success', // Add a custom CSS class for styling
                                }
                              );
                            }
                            else {
                              setImage(e.target.files[0]);
                              setPreview(URL.createObjectURL(e.target.files[0]));
                            }

                          }}

                          className="hidden"
                        />

                      </form>
                    </div>

                    <label htmlFor="ownerName" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                      Title
                    </label>
                    <input
                      id="ownerName"
                      className={`mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border ${errors.ownerName ? 'border-red-500' : ''
                        }`}
                      placeholder=""
                      value={ownerName}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= 50) {
                          setOwnerName(inputValue);
                        } else {
                          setOwnerName(inputValue.slice(0, 50));
                        }
                      }}
                    />

                    <label htmlFor="expiryDate" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                      File (download link)
                    </label>
                    <div className="relative mb-5 mt-2">
                      <div className="absolute right-0 text-gray-600 flex items-center pr-3 h-full cursor-pointer">

                      </div>
                      <input
                        id="expiryDate"
                        className={`text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border ${errors.expiryDate ? 'border-red-500' : ''
                          }`}
                        placeholder="https://drive.google.com/file/"
                        value={expiryDate}
                        onChange={(e) => setExpiryDate(e.target.value)}
                      />
                    </div>
                    <label htmlFor="cvc" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                      Description
                    </label>
                    {/* <div className="overflow-y-auto h-34 mb-5 mt-2">
                      <textarea
                        className={`mb-8 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-32 flex items-center pl-3 text-sm border-gray-300 rounded border ${errors.cvc ? 'border-red-500' : ''
                          }`}
                        id="cvc"
                        placeholder=""
                        
                        value={cvc}
                        onChange={(e) => setCVC(e.target.value)}
                        // onKeyDown={(e) => {
                        //   if (e.key === "Enter") {
                        //     e.preventDefault();
                        //   }
                        // }}

                      />
                    </div> */}
                    


                    <ReactQuill
                      value={cvc}
                      onChange={(value) => setCVC(value)}
                      className={`mb-16 mt-2 text-gray-600 font-normal w-full h-32 text-sm ${errors.cvc ? 'border-red-500' : ''}`}
                    />




                    <div className="flex items-center justify-between w-full mt-8">
                      <button
                        className="focus:outline-none transition duration-150 ease-in-out hover:bg-green-500 bg-green-600 rounded text-white px-8 py-2 text-sm"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>

                      <button
                        className="focus:outline-none transition duration-150 ease-in-out hover:bg-red-500 bg-red-600 rounded text-white px-8 py-2 text-sm"
                        onClick={openOverlay}
                      >
                        Delete Project
                      </button>

                    </div>
                  </form>

                  <div
                    className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out"
                    // onClick={async () => {
                    //   setEdit(0);
                    //   await new Promise((resolve) => setTimeout(resolve, 0)); // Wait for the next tick
                    //   reloadPage();
                    // }}

                    onClick={() => {
                      reloadPage(); setEdit(0);
                    }}

                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-label="Close"
                      className="icon icon-tabler icon-tabler-x"
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      strokeWidth="2.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <line x1={18} y1={6} x2={6} y2={18} />
                      <line x1={6} y1={6} x2={18} y2={18} />
                    </svg>
                  </div>

                </div>

              </div>
            </div>
          )}





          {/* delete project overlay */}

          {isOpen && (
            <div
              className="overlay"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 9998,
                boxShadow: '0 0 10px rgba(255, 255, 255, 0.8)'
              }}
            >
              <div
                className="overlay-content"
                style={{
                  backgroundColor: 'black',
                  padding: '20px',
                  borderRadius: '4px',
                  maxWidth: '400px'
                }}
              >
                <div className="flex pb-3 items-center">
                  <div className="-ml-1 text-gray-600 dark:text-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <line x1="4" y1="7" x2="20" y2="7" />
                      <line x1="10" y1="11" x2="10" y2="17" />
                      <line x1="14" y1="11" x2="14" y2="17" />
                      <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                      <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                    </svg>
                  </div>
                  <p className="text-lg text-gray-800 dark:text-gray-100 font-semibold pl-2">Delete project permanently?</p>
                  <button
                    className="close-overlay"
                    onClick={closeOverlay}
                    style={{
                      marginLeft: 'auto',
                      cursor: 'pointer',
                      color: 'white'
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <line x1="18" y1="6" x2="6" y2="18" />
                      <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                  </button>
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-400 pb-3 font-normal mb-2">Once the project is deleted, it can't be recovered. Are you sure you want to delete this project?</p>
                <button onClick={() => deleteProject()}
                  className="delete-account-btn focus:outline-none bg-red-400 transition duration-150 ease-in-out hover:bg-red-500 rounded text-white px-3 py-2 text-xs">Delete Project</button>
              </div>
            </div>
          )}



        </div>



      )}


      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />


    </div>


    //     <div className="">
    //     <div className="mx-auto container bg-white shadow rounded">
    //         <div className="flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-start lg:items-stretch w-full">
    //             <div className="w-full lg:w-1/3 flex flex-col lg:flex-row items-start lg:items-center">
    //                 <div className="flex items-center">
    //                     <a className="text-gray-600 dark:text-gray-400 p-2 border-transparent border bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-gray-200 cursor-pointer rounded focus:outline-none focus:border-gray-800 focus:shadow-outline-gray" href="javascript: void(0)">
    //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon cursor-pointer icon-tabler icon-tabler-edit" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                             <path stroke="none" d="M0 0h24v24H0z" />
    //                             <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
    //                             <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
    //                             <line x1={16} y1={5} x2={19} y2={8} />
    //                         </svg>
    //                     </a>
    //                     <a className="text-gray-600 dark:text-gray-400 mx-2 p-2 border-transparent border bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-gray-200 cursor-pointer rounded focus:outline-none focus:border-gray-800 focus:shadow-outline-gray" href="javascript: void(0)">
    //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon cursor-pointer icon-tabler icon-tabler-settings" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                             <path stroke="none" d="M0 0h24v24H0z" />
    //                             <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
    //                             <circle cx={12} cy={12} r={3} />
    //                         </svg>
    //                     </a>
    //                     <a className="text-gray-600 dark:text-gray-400 mr-2 p-2 border-transparent border bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-gray-200 cursor-pointer rounded focus:outline-none focus:border-gray-800 focus:shadow-outline-gray" href="javascript: void(0)">
    //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bookmark" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                             <path stroke="none" d="M0 0h24v24H0z" />
    //                             <path d="M9 4h6a2 2 0 0 1 2 2v14l-5-3l-5 3v-14a2 2 0 0 1 2 -2" />
    //                         </svg>
    //                     </a>
    //                     <a className="text-gray-600 dark:text-gray-400 mr-2 p-2 border-transparent border bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-gray-200 cursor-pointer rounded focus:outline-none focus:border-gray-800 focus:shadow-outline-gray" href="javascript: void(0)">
    //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                             <path stroke="none" d="M0 0h24v24H0z" />
    //                             <rect x={8} y={8} width={12} height={12} rx={2} />
    //                             <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
    //                         </svg>
    //                     </a>
    //                     <a className="text-red-500 p-2 border-transparent border bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-gray-200 cursor-pointer rounded focus:outline-none focus:border-gray-800 focus:shadow-outline-gray" href="javascript: void(0)">
    //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon cursor-pointer icon-tabler icon-tabler-trash" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                             <path stroke="none" d="M0 0h24v24H0z" />
    //                             <line x1={4} y1={7} x2={20} y2={7} />
    //                             <line x1={10} y1={11} x2={10} y2={17} />
    //                             <line x1={14} y1={11} x2={14} y2={17} />
    //                             <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
    //                             <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
    //                         </svg>
    //                     </a>
    //                 </div>
    //             </div>
    //             <div className="w-full lg:w-2/3 flex flex-col lg:flex-row items-start lg:items-center justify-end">
    //                 <div className="flex items-center lg:border-l lg:border-r border-gray-300 dark:border-gray-200 py-3 lg:py-0 lg:px-6">
    //                     <p className="text-base text-gray-600 dark:text-gray-400" id="page-view">
    //                         Viewing 1 - 20 of 60
    //                     </p>
    //                     <a className="text-gray-600 dark:text-gray-400 ml-2 border-transparent border cursor-pointer rounded" onclick="pageView(false)">
    //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                             <path stroke="none" d="M0 0h24v24H0z" />
    //                             <polyline points="15 6 9 12 15 18" />
    //                         </svg>
    //                     </a>
    //                     <a className="text-gray-600 dark:text-gray-400 border-transparent border rounded focus:outline-none cursor-pointer" onclick="pageView(true)">
    //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                             <path stroke="none" d="M0 0h24v24H0z" />
    //                             <polyline points="9 6 15 12 9 18" />
    //                         </svg>
    //                     </a>
    //                 </div>
    //                 <div className="flex items-center lg:border-r border-gray-300 dark:border-gray-200 pb-3 lg:pb-0 lg:px-6">
    //                     <div className="relative w-32 z-10">
    //                         <div className="pointer-events-none text-gray-600 dark:text-gray-400 absolute inset-0 m-auto mr-2 xl:mr-4 z-0 w-5 h-5">
    //                             <svg xmlns="http://www.w3.org/2000/svg" className="icon cursor-pointer icon-tabler icon-tabler-chevron-down" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                                 <path stroke="none" d="M0 0h24v24H0z" />
    //                                 <polyline points="6 9 12 15 18 9" />
    //                             </svg>
    //                         </div>
    //                         <select aria-label="Selected tab" className="focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray text-base form-select block w-full py-2 px-2 xl:px-3 rounded text-gray-600 dark:text-gray-400 appearance-none bg-transparent">
    //                             <option>List View</option>
    //                             <option>Grid View</option>
    //                         </select>
    //                     </div>
    //                 </div>
    //                 <div className="lg:ml-6 flex items-center">
    //                     <button className="bg-gray-200 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-gray-300 rounded text-indigo-700 px-5 h-8 flex items-center text-sm">Download All</button>
    //                     <div className="text-white ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 w-8 h-8 rounded flex items-center justify-center">
    //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                             <path stroke="none" d="M0 0h24v24H0z" />
    //                             <line x1={12} y1={5} x2={12} y2={19} />
    //                             <line x1={5} y1={12} x2={19} y2={12} />
    //                         </svg>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="w-full overflow-x-scroll xl:overflow-x-hidden">
    //             <table className="min-w-full bg-white dark:bg-gray-800">
    //                 <thead>
    //                     <tr className="w-full h-16 border-gray-300 dark:border-gray-200 border-b py-8">
    //                         <th className="pl-8 text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">
    //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="checkAll(this)" />
    //                         </th>
    //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">
    //                             <div className="text-gray-600 dark:text-gray-400 opacity-0 cursor-default relative w-10">
    //                                 <div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-1 rounded-full bg-indigo-700 text-white flex justify-center items-center text-xs">3</div>
    //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                                     <path stroke="none" d="M0 0h24v24H0z" />
    //                                     <path d="M14 3v4a1 1 0 0 0 1 1h4" />
    //                                     <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
    //                                 </svg>
    //                             </div>
    //                         </th>
    //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">Invoice Number</th>
    //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">Client</th>
    //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">Company Contact</th>
    //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">Amount</th>
    //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">Date</th>
    //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">
    //                             <div className="opacity-0 w-2 h-2 rounded-full bg-indigo-400" />
    //                         </th>
    //                         <td className="text-gray-600 dark:text-gray-400 font-normal pr-8 text-left text-sm tracking-normal leading-4">More</td>
    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     <tr className="h-24 border-gray-300 dark:border-gray-200 border-b">
    //                         <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4">
    //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="tableInteract(this)" />
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
    //                             <div className="relative w-10 text-gray-600 dark:text-gray-400">
    //                                 <div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-1 rounded-full bg-indigo-700 text-white flex justify-center items-center text-xs">3</div>
    //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                                     <path stroke="none" d="M0 0h24v24H0z" />
    //                                     <path d="M14 3v4a1 1 0 0 0 1 1h4" />
    //                                     <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
    //                                 </svg>
    //                             </div>
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">#MC10023</td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">Toyota Motors</td>
    //                         <td className="pr-6 whitespace-no-wrap">
    //                             <div className="flex items-center">
    //                                 <div className="h-8 w-8">
    //                                     <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/advance_tables/at_1.png" alt className="h-full w-full rounded-full overflow-hidden shadow" />
    //                                 </div>
    //                                 <p className="ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm">Carrie Anthony</p>
    //                             </div>
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">$2,500</td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">02.03.20</td>
    //                         <td className="pr-6">
    //                             <div className="w-2 h-2 rounded-full bg-indigo-400" />
    //                         </td>
    //                         <td className="pr-8 relative">
    //                             <div className="dropdown-content mt-8 absolute left-0 -ml-12 shadow-md z-10 hidden w-32">
    //                                 <ul className="bg-white dark:bg-gray-800 shadow rounded py-1">
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Edit</li>
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Delete</li>
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Duplicate</li>
    //                                 </ul>
    //                             </div>
    //                             <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
    //                                 <svg xmlns="http://www.w3.org/2000/svg" onclick="dropdownFunction(this)" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                                     <path stroke="none" d="M0 0h24v24H0z" />
    //                                     <circle cx={12} cy={12} r={1} />
    //                                     <circle cx={12} cy={19} r={1} />
    //                                     <circle cx={12} cy={5} r={1} />
    //                                 </svg>
    //                             </button>
    //                         </td>
    //                     </tr>
    //                     <tr className="h-24 border-gray-300 dark:border-gray-200 border-b">
    //                         <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4">
    //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="tableInteract(this)" />
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
    //                             <div className="text-gray-400 relative w-10">
    //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                                     <path stroke="none" d="M0 0h24v24H0z" />
    //                                     <path d="M14 3v4a1 1 0 0 0 1 1h4" />
    //                                     <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
    //                                 </svg>
    //                             </div>
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">#MC10023</td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">Toyota Motors</td>
    //                         <td className="pr-6 whitespace-no-wrap">
    //                             <div className="flex items-center">
    //                                 <div className="h-8 w-8">
    //                                     <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/advance_tables/at_2.png" alt className="h-full w-full rounded-full overflow-hidden shadow" />
    //                                 </div>
    //                                 <p className="ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm">Carrie Anthony</p>
    //                             </div>
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">$2,500</td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">02.03.20</td>
    //                         <td className="pr-6">
    //                             <div className="w-2 h-2 rounded-full bg-red-400" />
    //                         </td>
    //                         <td className="pr-8 relative">
    //                             <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none ">
    //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" onclick="dropdownFunction(this)">
    //                                     <path stroke="none" d="M0 0h24v24H0z" />
    //                                     <circle cx={12} cy={12} r={1} />
    //                                     <circle cx={12} cy={19} r={1} />
    //                                     <circle cx={12} cy={5} r={1} />
    //                                 </svg>
    //                             </button>
    //                             <div className="dropdown-content mt-1 absolute left-0 -ml-12 shadow-md z-10 hidden w-32">
    //                                 <ul className="bg-white dark:bg-gray-800 shadow rounded py-1">
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Edit</li>
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Delete</li>
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Duplicate</li>
    //                                 </ul>
    //                             </div>
    //                         </td>
    //                     </tr>
    //                     <tr className="h-24 border-gray-300 dark:border-gray-200 border-b">
    //                         <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4">
    //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="tableInteract(this)" />
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
    //                             <div className="text-gray-400 relative w-10">
    //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                                     <path stroke="none" d="M0 0h24v24H0z" />
    //                                     <path d="M14 3v4a1 1 0 0 0 1 1h4" />
    //                                     <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
    //                                 </svg>
    //                             </div>
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">#MC10023</td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">Toyota Motors</td>
    //                         <td className="pr-6 whitespace-no-wrap">
    //                             <div className="flex items-center">
    //                                 <div className="h-8 w-8">
    //                                     <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/advance_tables/at_3.png" alt className="h-full w-full rounded-full overflow-hidden shadow" />
    //                                 </div>
    //                                 <p className="ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm">Carrie Anthony</p>
    //                             </div>
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">$2,500</td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">02.03.20</td>
    //                         <td className="pr-6">
    //                             <div className="w-2 h-2 rounded-full bg-indigo-400" />
    //                         </td>
    //                         <td className="pr-8 relative">
    //                             <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
    //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" onclick="dropdownFunction(this)">
    //                                     <path stroke="none" d="M0 0h24v24H0z" />
    //                                     <circle cx={12} cy={12} r={1} />
    //                                     <circle cx={12} cy={19} r={1} />
    //                                     <circle cx={12} cy={5} r={1} />
    //                                 </svg>
    //                             </button>
    //                             <div className="dropdown-content mt-1 absolute left-0 -ml-12 shadow-md z-10 hidden w-32">
    //                                 <ul className="bg-white dark:bg-gray-800 shadow rounded py-1">
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Edit</li>
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Delete</li>
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Duplicate</li>
    //                                 </ul>
    //                             </div>
    //                         </td>
    //                     </tr>
    //                     <tr className="h-24 border-gray-300 dark:border-gray-200 border-b">
    //                         <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4">
    //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="tableInteract(this)" />
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
    //                             <div className="text-gray-400 relative w-10">
    //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                                     <path stroke="none" d="M0 0h24v24H0z" />
    //                                     <path d="M14 3v4a1 1 0 0 0 1 1h4" />
    //                                     <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
    //                                 </svg>
    //                             </div>
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">#MC10023</td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">Toyota Motors</td>
    //                         <td className="pr-6 whitespace-no-wrap">
    //                             <div className="flex items-center">
    //                                 <div className="h-8 w-8">
    //                                     <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/advance_tables/at_1.png" alt className="h-full w-full rounded-full overflow-hidden shadow" />
    //                                 </div>
    //                                 <p className="ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm">Carrie Anthony</p>
    //                             </div>
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">$2,500</td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">02.03.20</td>
    //                         <td className="pr-6">
    //                             <div className="w-2 h-2 rounded-full bg-indigo-400" />
    //                         </td>
    //                         <td className="pr-8 relative">
    //                             <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
    //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" onclick="dropdownFunction(this)">
    //                                     <path stroke="none" d="M0 0h24v24H0z" />
    //                                     <circle cx={12} cy={12} r={1} />
    //                                     <circle cx={12} cy={19} r={1} />
    //                                     <circle cx={12} cy={5} r={1} />
    //                                 </svg>
    //                             </button>
    //                             <div className="dropdown-content mt-1 absolute left-0 -ml-12 shadow-md z-10 hidden w-32">
    //                                 <ul className="bg-white dark:bg-gray-800 shadow rounded py-1">
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Edit</li>
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Delete</li>
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Duplicate</li>
    //                                 </ul>
    //                             </div>
    //                         </td>
    //                     </tr>
    //                     <tr className="h-24 border-gray-300 dark:border-gray-200 border-b">
    //                         <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4">
    //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="tableInteract(this)" />
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
    //                             <div className="relative w-10">
    //                                 <div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-1 rounded-full bg-indigo-700 text-white flex justify-center items-center text-xs">1</div>
    //                                 <div className="text-gray-600 dark:text-gray-400">
    //                                     <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                                         <path stroke="none" d="M0 0h24v24H0z" />
    //                                         <path d="M14 3v4a1 1 0 0 0 1 1h4" />
    //                                         <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
    //                                     </svg>
    //                                 </div>
    //                             </div>
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">#MC10023</td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">Toyota Motors</td>
    //                         <td className="pr-6 whitespace-no-wrap">
    //                             <div className="flex items-center">
    //                                 <div className="h-8 w-8">
    //                                     <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/advance_tables/at_2.png" alt className="h-full w-full rounded-full overflow-hidden shadow" />
    //                                 </div>
    //                                 <p className="ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm">Carrie Anthony</p>
    //                             </div>
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">$2,500</td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">02.03.20</td>
    //                         <td className="pr-6">
    //                             <div className="w-2 h-2 rounded-full bg-red-400" />
    //                         </td>
    //                         <td className="pr-8 relative">
    //                             <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
    //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" onclick="dropdownFunction(this)">
    //                                     <path stroke="none" d="M0 0h24v24H0z" />
    //                                     <circle cx={12} cy={12} r={1} />
    //                                     <circle cx={12} cy={19} r={1} />
    //                                     <circle cx={12} cy={5} r={1} />
    //                                 </svg>
    //                             </button>
    //                             <div className="dropdown-content mt-1 absolute left-0 -ml-12 shadow-md z-10 hidden w-32">
    //                                 <ul className="bg-white dark:bg-gray-800 shadow rounded py-1">
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Edit</li>
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Delete</li>
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Duplicate</li>
    //                                 </ul>
    //                             </div>
    //                         </td>
    //                     </tr>
    //                     <tr className="h-24 border-gray-300 dark:border-gray-200 border-b">
    //                         <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4">
    //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="tableInteract(this)" />
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
    //                             <div className="relative w-10">
    //                                 <div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-1 rounded-full bg-indigo-700 text-white flex justify-center items-center text-xs">5</div>
    //                                 <div className="text-gray-600 dark:text-gray-400">
    //                                     <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                                         <path stroke="none" d="M0 0h24v24H0z" />
    //                                         <path d="M14 3v4a1 1 0 0 0 1 1h4" />
    //                                         <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
    //                                     </svg>
    //                                 </div>
    //                             </div>
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">#MC10023</td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">Toyota Motors</td>
    //                         <td className="pr-6 whitespace-no-wrap">
    //                             <div className="flex items-center">
    //                                 <div className="h-8 w-8">
    //                                     <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/advance_tables/at_3.png" alt className="h-full w-full rounded-full overflow-hidden shadow" />
    //                                 </div>
    //                                 <p className="ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm">Carrie Anthony</p>
    //                             </div>
    //                         </td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">$2,500</td>
    //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">02.03.20</td>
    //                         <td className="pr-6">
    //                             <div className="w-2 h-2 rounded-full bg-gray-600" />
    //                         </td>
    //                         <td className="pr-8 relative">
    //                             <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
    //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" onclick="dropdownFunction(this)">
    //                                     <path stroke="none" d="M0 0h24v24H0z" />
    //                                     <circle cx={12} cy={12} r={1} />
    //                                     <circle cx={12} cy={19} r={1} />
    //                                     <circle cx={12} cy={5} r={1} />
    //                                 </svg>
    //                             </button>
    //                             <div className="dropdown-content mt-1 absolute left-0 -ml-12 shadow-md z-10 hidden w-32">
    //                                 <ul className="bg-white dark:bg-gray-800 shadow rounded py-1">
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Edit</li>
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Delete</li>
    //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Duplicate</li>
    //                                 </ul>
    //                             </div>
    //                         </td>
    //                     </tr>
    //                 </tbody>
    //             </table>
    //         </div>
    //     </div>
    // </div>
  );
};

export default EmployeeData;