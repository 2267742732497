import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import HeaderBlack from '../partials/HeaderBlack.jsx';
import PageIllustration from '../partials/PageIllustration.jsx';
import Banner from '../partials/Banner.jsx';
import Footer from '../partials/Footer.jsx'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { FaCheckDouble, FaWindowClose } from 'react-icons/fa';

function SignUp() {

  const websitePrefix = 'www.gigadevden.com';

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(0);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const fileSize = file.size / 1024; // Size in KB

    if (fileSize > 100) {
      // Reset the file input value and show an error message
      e.target.value = null;
      // Update the state or display an error message to the user
      // For example, you can update the `error` state to indicate the image size exceeded the limit
      toast(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaWindowClose className="mr-2 text-red-600" />
          <span>Profile picture max size: 100KB</span>
        </div>,
        {
          className: 'custom-toast-success', // Add a custom CSS class for styling
        }
      );
  
    }
    else
    {
      setImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
      setError(0);
    }
  };



  const fetchData = async () => {
    if (image !== null) {
      try {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('image', image);

        const response = await fetch('https://gigadevden.com/webpublicsignup.php', {
          method: 'POST',
          body: formData,
        });

        const text = await response.text();
        
        if (text === '"Data inserted"') {
          localStorage.setItem('${websitePrefix}-temppublicemail', email);
          localStorage.setItem('${websitePrefix}-temppublicpassword', password);
          toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaCheckDouble className="mr-2 text-green-600" />
              <span>Successfully signed up</span>
            </div>,
            {
              className: 'custom-toast-success', // Add a custom CSS class for styling
            }
          );
          //localStorage.setItem('${websitePrefix}-publicemail', email);
         // localStorage.setItem('${websitePrefix}-publicpassword', password);
          //setMessage('Successfully registered');
        } else if (text === '"Email already exists"') {
          toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaWindowClose className="mr-2 text-red-600" />
              <span>Email already exists</span>
            </div>,
            {
              className: 'custom-toast-success', // Add a custom CSS class for styling
            }
          );
        } else {
          toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaWindowClose className="mr-2 text-red-600" />
              <span>Sign up failed</span>
            </div>,
            {
              className: 'custom-toast-success', // Add a custom CSS class for styling
            }
          );
          //setMessage('Registration failed');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      setError(1);
    }
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    
    fetchData();

  };


  return (

    <div
  className="bg-black font-inter antialiased text-gray-200 tracking-tight font-urbanist"
  style={{
    backgroundImage: `url('https://images.pexels.com/photos/14434461/pexels-photo-14434461.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`,
    backgroundSize: 'cover', // or 'contain' if you prefer
    backgroundPosition: 'center', // or any other position
  }}
>


      <div className="flex flex-col min-h-screen overflow-hidden">

        {/*  Site header */}
        <HeaderBlack />



        {/*  Page content */}
        <main className="grow">

          {/*  Page illustration */}
          <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
            <PageIllustration />
          </div>

          <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="pt-12 pb-12 md:pt-16 md:pb-20">


                {/* Page header */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                  <h1 className="h1">Welcome. We exist to make entrepreneurship easier.</h1>
                </div>

                {/* Form */}
                <div className="md:mx-auto bg-white bg-opacity- px-6 md:px-48 lg:px-80 py-6 rounded-lg">
                  {/* <form>
                    <div className="flex flex-wrap -mx-3">
                      <div className="w-full px-3">
                        <button className="btn px-0 text-white bg-red-600 hover:bg-red-700 w-full relative flex items-center">
                          <svg className="w-4 h-4 fill-current text-white opacity-75 shrink-0 mx-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z" />
                          </svg>
                          <span className="h-6 flex items-center border-r border-white border-opacity-25 mr-4" aria-hidden="true"></span>
                          <span className="flex-auto pl-16 pr-8 -ml-16">Sign up with Google</span>
                        </button>
                      </div>
                    </div>
                  </form> */}
                  {/* <div className="flex items-center my-6">
                    <div className="border-t border-gray-700 border-dotted grow mr-3" aria-hidden="true"></div>
                    <div className="text-gray-400">Or, register with your email</div>
                    <div className="border-t border-gray-700 border-dotted grow ml-3" aria-hidden="true"></div>
                  </div> */}
                  <form onSubmit={handleSubmit}>
                  <label htmlFor="file-input" className="cursor-pointer">
                      <div className={`w-32 h-32 mx-auto my-4 border-4 border-dashed rounded-full flex items-center justify-center ${error === 0 ? 'text-blue-500 border-blue-500' : 'text-red-500 border-red-500'} overflow-hidden`}>
                        {preview ? (
                          <img
                            src={preview}
                            alt="Preview"
                            className="object-cover w-full h-full"
                          />
                        ) : (
                          <span className="text-4xl">+</span>
                        )}
                      </div>
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label className="block text-gray-950 text-sm font-medium mb-1" htmlFor="full-name">Full Name <span className="text-red-600">*</span></label>
                        <input value={name} onChange={(e) => setName(e.target.value)} id="full-name" type="text" className="form-input w-full text-gray-950 rounded" placeholder="" required />
                      </div>
                    </div>
                    {/* <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="company-name">Company Name <span className="text-red-600">*</span></label>
                        <input id="company-name" type="text" className="form-input w-full text-gray-300" placeholder="Your company or app name" required />
                      </div>
                    </div> */}
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label className="block text-gray-950 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} id="email" type="email" className="form-input w-full text-gray-950 rounded" placeholder="" required />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label className="block text-gray-950 text-sm font-medium mb-1" htmlFor="password">Password <span className="text-red-600">*</span></label>
                        <input value={password} onChange={(e) => setPassword(e.target.value)} id="password" type="password" className="form-input w-full text-gray-950 rounded" placeholder="" required />
                      </div>
                    </div>
                    <div className="text-sm text-gray-500 text-center">
                      I agree to GigaDevDen's terms and conditions as mentioned in the <Link to="#" className="underline text-gray-400 hover:text-gray-200 hover:no-underline transition duration-150 ease-in-out">Privacy Policy</Link>.
                    </div>
                    <div className="flex flex-wrap -mx-3 mt-6">
                      <div className="w-full px-3">
                        <button className="btn text-white bg-red-600 hover:bg-red-700 w-full">Sign up</button>
                      </div>
                    </div>
                  </form>
                  <div className="text-gray-400 text-center mt-6">
                    Already using GigaDevDen? <Link to="/signin" className="text-blue-600 hover:text-gray-200 transition duration-150 ease-in-out">Sign in</Link>
                  </div>
                </div>

              </div>
            </div>
          </section>

        </main>

        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />


        <Footer />

        <Banner />

      </div>
    </div>
  );
}

export default SignUp;