import React from 'react';

import HeaderBlack from '../partials/HeaderBlack.jsx';
import Testimonials from '../partials/Testimonials.jsx';
import Footer from '../partials/Footer.jsx';


function About() {
    return (


        <div className='flex flex-col min-h-screen border-red-500 border-t-4'>
            <div className="flex flex-col md:flex-row h-auto min-h-0">

                {/* Main content */}
                <div className="bg-black w-full md:w-[100%] min-h-screen flex-grow">
                  <HeaderBlack />
{/* 
                  <div className="relative pb-[56.25%] h-0 overflow-hidden" itemscope itemType="https://schema.org/VideoObject">
      <meta itemProp="name" content="“Mr. Chadda ke Masoom Sawal” One minute laughter ride (Punjabi)" />
      <meta itemProp="description" content="“Mr. Chadda ke Masoom Sawal” One minute laughter ride (Punjabi)" />
      <meta itemProp="uploadDate" content="2023-07-28T06:02:55.000Z" />
      <meta itemProp="thumbnailUrl" content="https://s1.dmcdn.net/v/V7duO1amreFi3d4bm/x120" />
      <meta itemProp="duration" content="P49S" />
      <meta itemProp="embedUrl" content="https://www.dailymotion.com/embed/video/x8mu8t4" />
      <iframe
        className="w-full h-full absolute left-0 top-0 overflow-hidden"
        frameBorder="0"
        type="text/html"
        src="https://www.dailymotion.com/embed/video/x8mu8t4?autoplay=1"
        width="100%"
        height="100%"
        allow="autoplay"
        allowFullScreen
      ></iframe>
    </div> */}
                  <Testimonials />
                  <Footer />
                    

                </div>
            </div>
        </div>
    );
}

export default About;