import React, { useState, useEffect } from "react";
import Header from "../partials/ConsoleHeader"
import Footer from "../partials/Footer";
import Banner from '../partials/Banner.jsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { FaWindowClose } from "react-icons/fa";

const CreateConsole = () => {

  const websitePrefix = 'www.gigadevden.com';

  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(0);


  const [region, setRegion] = useState('');
  const [country, setCountry] = useState('');
  const [bio, setBio] = useState('');
  const countries = [
    { code: 'US', name: 'United States' },
    { code: 'CA', name: 'Canada' },
    { code: 'UK', name: 'United Kingdom' },
    { code: 'US', name: 'United States' },
    { code: 'CA', name: 'Canada' },
    { code: 'UK', name: 'United Kingdom' },
    { code: 'US', name: 'United States' },
    { code: 'CA', name: 'Canada' },
    { code: 'UK', name: 'United Kingdom' },
    { code: 'US', name: 'United States' },
    { code: 'CA', name: 'Canada' },
    { code: 'UK', name: 'United Kingdom' },
    { code: 'US', name: 'United States' },
    { code: 'CA', name: 'Canada' },
    { code: 'UK', name: 'United Kingdom' },
    { code: 'US', name: 'United States' },
    { code: 'CA', name: 'Canada' },
    { code: 'UK', name: 'United Kingdom' },
    { code: 'US', name: 'United States' },
    { code: 'CA', name: 'Canada' },
    { code: 'UK', name: 'United Kingdom' },

    // Add more countries here...
  ];


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const fileSize = file.size / 1024; // Size in KB

    if (fileSize > 250) {
      // Reset the file input value and show an error message
      e.target.value = null;
      // Update the state or display an error message to the user
      // For example, you can update the `error` state to indicate the image size exceeded the limit
      toast(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaWindowClose className="mr-2 text-red-600" />
          <span>Profile picture max size: 250KB</span>
        </div>,
        {
          className: 'custom-toast-success', // Add a custom CSS class for styling
        }
      );
  
    }
    else
    {
      setImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
      setError(0);
    }
  };


  const [name, setName] = useState("");
  const [cnic, setCNIC] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [education, setEducation] = useState("");
  const [accountType, setAccountType] = useState("");

  const [employees, setEmployees] = useState([]);
  const [query, setQuery] = useState('');
  const [show, setshow] = useState(false);

  const [response, setResponse] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const [message, setMessage] = useState('');

  const [bannerOpen, setBannerOpen] = useState(false);

  useEffect(() => {
    if (message !== '') {
      setBannerOpen(true);
    }
  }, [message]);


  const fetchData = async () => {
    if (image !== null) {
      try {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('occupation', cnic);
        formData.append('contact', contact);
        formData.append('address', address);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('image', image);
        formData.append('regioncity', region); // New variable
        formData.append('country', country); // New variable
        formData.append('bio', bio); // New variable
        formData.append('education', education); // New variable

        const response = await fetch('https://gigadevden.com/webdevelopersignup.php', {
          method: 'POST',
          body: formData,
        });

        const text = await response.text();
    
        if (text === '"Data inserted"') {
       
          localStorage.setItem('${websitePrefix}-tempdeveloperemail', email);
          localStorage.setItem('${websitePrefix}-tempdeveloperpassword', password);
          setMessage('Successfully registered');
        } else if (text === '"Email already exists"') {
          setMessage('Email already exists');
        } else {
          setMessage('Registration failed');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      setError(1);
    }
  };



  return (
    <div><Header />


      <div className="bg-black min-h-screen">
        <div className="container mx-auto py-10">

          <div className="flex flex-row justify-center items-center">
            {/* <h1 className="text-white text-3xl font-bold text-center mb-8">Sign Up</h1> */}
            <img src="https://www.gigadevden.com/dotden.png" alt="Logo" onClick={() => window.location.href = '/'} className="w-auto h-[80px] hover:cursor-pointer" />
            <img src="https://www.gigadevden.com/developer-icon.png" alt="Logo" onClick={() => window.location.href = '/'} className="w-auto h-[80px] hover:cursor-pointer" />

          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-gray-200 py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form onSubmit={handleSubmit} className="max-w-lg mx-auto">


                <div className="container mx-auto">
                  <form onSubmit={handleSubmit} className="text-center">
                    <label htmlFor="file-input" className="cursor-pointer">
                      <div className={`w-32 h-32 mx-auto my-4 border-4 border-dashed rounded-full flex items-center justify-center ${error === 0 ? 'text-blue-500 border-blue-500' : 'text-red-500 border-red-500'} overflow-hidden`}>
                        {preview ? (
                          <img
                            src={preview}
                            alt="Preview"
                            className="object-cover w-full h-full"
                          />
                        ) : (
                          <span className="text-4xl">+</span>
                        )}
                      </div>
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                    {/* <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Upload Image
        </button> */}
                  </form>
                </div>


                <div className="mb-4">
                  <label className="block font-bold mb-2" htmlFor="name">
                    Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Ali Rubass"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  //   pattern="[^',;]*"
                  //   title="Invalid input. Please avoid single quotation, comma, and semi-colon characters."
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold mb-2" htmlFor="name">
                    Occupation <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="occupation"
                    type="text"
                    placeholder="eg., Software Engineer"
                    name="occupation"
                    value={cnic}
                    onChange={(e) => setCNIC(e.target.value)}
                    required
                    min={0}
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold mb-2" htmlFor="name">
                    Education <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="education"
                    type="text"
                    placeholder="eg., University of Computer & Emerging Sciences"
                    name="education"
                    value={education}
                    onChange={(e) => setEducation(e.target.value)}
                    required
                  />
                </div>
                <div className="w-full mb-4">
                  <label className="block font-bold mb-2" htmlFor="bio">
                    Bio <span className="text-red-500">*</span>
                  </label>
                  <div className="overflow-y-auto h-34">
                    <textarea
                      className="appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none"
                      id="bio"
                      name="bio"
                      placeholder="Enter your bio"
                      value={bio}
                      onChange={(e) => {
                        const input = e.target.value;
                        if (input.length <= 250) {
                          setBio(input);
                        } else {
                          setBio(input.slice(0, 250));
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label className="block font-bold mb-2" htmlFor="contact">
                    Contact <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="contact"
                    type="text"
                    placeholder="+92 3077264806"
                    name="contact"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    required
                  />
                </div>


                {/* <div className="mb-4 flex space-x-4">
  <div className="w-1/2">
    <label className="block font-bold mb-2" htmlFor="region">
      Region or City <span className="text-red-500">*</span>
    </label>
    <input
      className="appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      id="region"
      type="text"
      name="region"
      placeholder="Enter region or city"
      value={region}
      onChange={(e) => setRegion(e.target.value)}
      required
    />
  </div>
  <div className="w-1/2">
    <label className="block font-bold mb-2" htmlFor="country">
      Country <span className="text-red-500">*</span>
    </label>
    <div className="relative">
      <select
        className="appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="country"
        name="country"
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        required
        
      >
        <option value="">Select Country</option>
        {countries.map((country) => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </select>
      <div className="absolute right-0 top-0 h-full pointer-events-none flex items-center">
        <svg
          className="h-4 w-4 text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10.293 14.95a1 1 0 0 1-1.414 0l-4-4A1 1 0 1 1 6.708 8.364L10 11.657l3.293-3.293a1 1 0 0 1 1.414 1.414l-4 4z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  </div>
</div> */}


                <div className="flex flex-wrap -mx-2">
                  <div className="w-full md:w-1/2 px-2 mb-4">
                    <label className="block font-bold mb-2" htmlFor="region">
                      Region/City <span className="text-red-500">*</span>
                    </label>
                    <input
                      className="appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="region"
                      type="text"
                      placeholder="Enter region or city"
                      name="region"
                      value={region}
                      onChange={(e) => setRegion(e.target.value)}
                      required
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-2 mb-4">
                    <label className="block font-bold mb-2" htmlFor="country">
                      Country <span className="text-red-500">*</span>
                    </label>
                    <input
                      className="appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="country"
                      type="text"
                      placeholder="Enter country"
                      name="country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required
                    />
                  </div>

                </div>



                <div className="mb-4">
                  <label className="block font-bold mb-2" htmlFor="address">
                    Address <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="address"
                    type="text"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  //   pattern="[^',;]*"
                  //   title="Invalid input. Please avoid single quotation, comma, and semi-colon characters."
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold mb-2" htmlFor="email">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  //   pattern="[^',;]*"
                  //   title="Invalid input. Please avoid single quotation, comma, and semi-colon characters."
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold mb-2" htmlFor="password">
                    Password <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  //   pattern="[^',;]*"
                  //   title="Invalid input. Please avoid single quotation, comma, and semi-colon characters."
                  />
                </div>
                {/* <div className="mb-4">
        
          <label className="block font-bold mb-2" htmlFor="accountType">
            Account Type
          </label>
          <div className="relative">
            <select
              name="accountType"
              value={accountType}
              onChange={(e) => setAccountType(e.target.value)}
              required
              className="appearance-none rounded-full w-32 py-2 px-4 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">Account Type</option>
              <option value="buyer">Buyer</option>
              <option value="seller">Seller</option>
              <option value="vendor">Vendor</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 16a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 13.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 16z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div> */}
                <div className="mb-6">
                  <label className="block font-bold mb-2" htmlFor="password">
                    Confirm Password <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="password"
                    type="password"
                    placeholder=""
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  // pattern="[^',;]*"
                  // title="Invalid input. Please avoid single quotation, comma, and semi-colon characters."
                  />
                </div>
                <div className="flex justify-between items-center">
                  <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Sign Up
                  </button>
                  {/* <Link
            className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
            to="/signin"
          >
            Already have an account? Sign in
          </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <>
        {bannerOpen && (
          <div className="fixed bottom-0 right-0 w-full md:bottom-8 md:right-12 md:w-auto z-60">
            <div className="bg-slate-800 text-slate-50 text-sm p-3 md:rounded shadow-lg flex justify-between">
              <div className="text-slate-500 inline-flex">
                {/* <a
                className="font-medium hover:underline text-slate-50"
                href="https://mail.google.com/mail/u/0/#inbox?compose=GTvVlcRzCMpwnjdQRpjnscWKbBGgZhhRMqbXSTkpfKZmDkGCrCdbJbQwpQxLddQZFWwzJWWvFCDGd"
                target="_blank"
                rel="noreferrer"
              >
                Contact<span className="hidden sm:inline"> us</span>
              </a>{' '} */}

                {/* {' '} */}

                {message === 'Successfully registered' && (
                  <span className="italic px-1.5 text-green-500">{message}</span>
                )}

                {message === 'Successfully registered' && (
                  <a className="font-medium hover:underline text-white ml-4" href="/console-login" target="_blank" rel="noreferrer">
                    Login Now
                  </a>
                )}

                {message === 'Email already exists' && (
                  <span className="italic px-1.5 text-red-500">{message}</span>
                )}

                {message === 'Registration failed' && (
                  <span className="italic px-1.5 text-red-500">{message}</span>
                )}
              </div>
              <button className="text-slate-500 hover:text-slate-400 pl-2 ml-3 border-l border-gray-700" onClick={() => setBannerOpen(false)}>
                <span className="sr-only">Close</span>
                <svg className="w-4 h-4 shrink-0 fill-current" viewBox="0 0 16 16">
                  <path d="M12.72 3.293a1 1 0 00-1.415 0L8.012 6.586 4.72 3.293a1 1 0 00-1.414 1.414L6.598 8l-3.293 3.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L9.426 8l3.293-3.293a1 1 0 000-1.414z" />
                </svg>
              </button>
            </div>
          </div>
        )}
      </>

      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />

      <Footer />
    </div>
  );
};

export default CreateConsole;