// import React, { useState } from 'react';
// import { FaBootstrap } from 'react-icons/fa';
// import favicon from '../images/favicon.png'

// function Banner() {

//   const [bannerOpen, setBannerOpen] = useState(true);

//   return (
//     <>
//       {bannerOpen && (
//         <div className="fixed bottom-8 right-8 md:bottom-8 md:right-12 z-60">










//           <div class="pb-24 duration-300 opacity-0 hover:opacity-100">
//             <div class="mx-auto container max-w-[228px] px-4 py-4 bg-white rounded relative shadow-md">
//               <p class="text-sm font-semibold leading-none text-gray-800">AI Chatbot</p>

//               <p className="block antialiased font-sans text-xs font-light text-gray-800 lg:pr-12">

//                         <b className="text-gray-950 font-poppins">
//                             <b className="text-red-600"> .</b>
//                             den</b>
//                         , our AI-powered chatbot, for assistance!
//                     </p>
//               <svg class="absolute z-10 right-4  bottom-[-10px] shadow-md" width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <path d="M8 10L0 0L16 1.41326e-06L8 10Z" fill="white" />
//               </svg>
//               {/* <svg class="absolute   bottom-[-30px]  z-10 cursor-pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <path d="M7.75 2C4.57469 2 2 4.57469 2 7.75C2 10.9253 4.57469 13.5 7.75 13.5C10.9253 13.5 13.5 10.9253 13.5 7.75C13.5 4.57469 10.9253 2 7.75 2Z" stroke="#1F2937" stroke-miterlimit="10" />
//                 <path d="M6.875 6.875H7.875V10.5" stroke="#1F2937" stroke-linecap="round" stroke-linejoin="round" />
//                 <path d="M6.5 10.625H9.25" stroke="#1F2937" stroke-miterlimit="10" stroke-linecap="round" />
//                 <path d="M7.75 4.0625C7.5893 4.0625 7.43222 4.11015 7.2986 4.19943C7.16499 4.28871 7.06084 4.41561 6.99935 4.56407C6.93785 4.71254 6.92176 4.8759 6.95311 5.03351C6.98446 5.19112 7.06185 5.3359 7.17548 5.44953C7.28911 5.56316 7.43388 5.64054 7.59149 5.67189C7.7491 5.70324 7.91247 5.68715 8.06093 5.62566C8.2094 5.56416 8.3363 5.46002 8.42557 5.3264C8.51485 5.19279 8.5625 5.0357 8.5625 4.875C8.5625 4.65951 8.4769 4.45285 8.32453 4.30048C8.17215 4.1481 7.96549 4.0625 7.75 4.0625Z" fill="#1F2937" />
//               </svg> */}

//             </div>




//           </div>






//           <a href="/chat.den.ai"

//             className="bg-gray-100 text-slate-50 text-sm shadow-sm flex justify-between w-16 h-16 rounded-full shadow-gray-900 absolute right-0 bottom-0 opacity-100">


//             <img src="https://cdn-icons-png.flaticon.com/512/5429/5429606.png" className="w-full h-full object-cover rounded-full" />
//             {/* <div className="text-slate-500 inline-flex">
//               <a
//                 className="font-medium hover:underline text-slate-50"
//                 href="https://mail.google.com/mail/u/0/#inbox?compose=GTvVlcRzCMpwnjdQRpjnscWKbBGgZhhRMqbXSTkpfKZmDkGCrCdbJbQwpQxLddQZFWwzJWWvFCDGd"
//                 target="_blank"
//                 rel="noreferrer"
//               >
//                 Contact<span className="hidden sm:inline"> us</span>
//               </a>{' '}
//               <span className="italic px-1.5">or</span>{' '}
//               <a className="font-medium hover:underline text-emerald-400" href="https://www.instagram.com/gigadevden/" target="_blank" rel="noreferrer">
//                 Connect with us
//               </a>
//             </div>
//             <button className="text-slate-500 hover:text-slate-400 pl-2 ml-3 border-l border-gray-700" onClick={() => setBannerOpen(false)}>
//               <span className="sr-only">Close</span>
//               <svg className="w-4 h-4 shrink-0 fill-current" viewBox="0 0 16 16">
//                 <path d="M12.72 3.293a1 1 0 00-1.415 0L8.012 6.586 4.72 3.293a1 1 0 00-1.414 1.414L6.598 8l-3.293 3.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L9.426 8l3.293-3.293a1 1 0 000-1.414z" />
//               </svg>
//             </button> */}
//           </a>
//         </div>
//       )}
//     </>
//   );
// }

// export default Banner;

import React, { useEffect, useState } from 'react';

function ChatApp() {

  const websitePrefix = 'www.gigadevden.com';

  const [isChatboxOpen, setIsChatboxOpen] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);

  const toggleChatbox = () => {
    setIsChatboxOpen(!isChatboxOpen);
  };

  const handleUserInput = (event) => {
    setUserMessage(event.target.value);
  };

  const handleSendButton = () => {
    if (userMessage.trim() !== '') {
      addUserMessage(userMessage);
      respondToUser(userMessage);
      setUserMessage('');
    }
  };

  const handleUserInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendButton();
    }
  };

  const addUserMessage = (message) => {
    const newMessage = (
      <div key={chatMessages.length} className="mb-2 text-right">
        <p className="bg-blue-500 text-white rounded-lg py-2 px-4 inline-block">{message}</p>
      </div>
    );
    setChatMessages([...chatMessages, newMessage]);
  };

  const addBotMessage = (message) => {
    const newMessage = (
      <div key={chatMessages.length} className="mb-2">
        <p className="bg-gray-200 text-gray-700 rounded-lg py-2 px-4 inline-block">{message}</p>
      </div>
    );
    setChatMessages([...chatMessages, newMessage]);
  };

  const respondToUser = (userMessage) => {
    // Replace this with your chatbot logic
    setTimeout(() => {
      addBotMessage('Sorry! Whisper Support is busy.');
    }, 500);
  };

  const [isLoggedIn, setLoggedIn] = useState('');

  useEffect(() => {
    setLoggedIn(localStorage.getItem('${websitePrefix}-publiclogin'));
  }, []);



  return (
    <div>
      <div className="fixed bottom-0 right-0 mb-4 mr-4 z-50">
        <button
          id="open-chat"
          className="bg-blue-500 bg-opacity-90 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300 flex items-center"
          onClick={toggleChatbox}
        >
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg> */}
          ✉ Support
        </button>
      </div>
      {isChatboxOpen && (
        <div className="fixed bottom-16 right-4 w-96 z-50">
          <div className="bg-white bg-opacity-40 shadow-md rounded-lg max-w-lg w-full">
            <div className="p-4 border-b bg-slate-900 text-white rounded-t-lg flex justify-between items-center">
              <p className="text-lg font-semibold">Whisper Support</p>
              <button
                id="close-chat"
                className="text-gray-300 hover:text-gray-400 focus:outline-none focus:text-gray-400"
                onClick={toggleChatbox}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {isLoggedIn && (
              <div id="chatbox" className="p-4 h-80 overflow-y-auto">
                {chatMessages.map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </div>
            )}

            {!isLoggedIn && (
              <div className="p-4 h-80 overflow-y-auto">
                <p className="bg-gray-200 text-gray-700 rounded-lg py-2 px-4 inline-block">Login Required</p>
              </div>
            )}

            <div className="p-4 border-t flex">
              <input
                id="user-input"
                type="text"
                placeholder="Type a message"
                className="w-full px-3 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={userMessage}
                onChange={handleUserInput}
                onKeyPress={handleUserInputKeyPress}
              />
              <button
                id="send-button"
                className="bg-green-500 text-white px-4 py-2 rounded-r-md hover:bg-green-600 transition duration-300"
                onClick={handleSendButton}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatApp;
