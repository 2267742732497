import { Markup } from 'interweave';
import React, { useState, useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


import { modules, formats } from "../js/EditorToolbar";
import { EditorToolbar } from '../js/EditorToolbar';
import HeaderMain from '../partials/HeaderMain'
import { FaRegCircle } from 'react-icons/fa';
import Footer from '../partials/Footer';


import { useParams } from 'react-router-dom';
import { HiOutlineDownload } from 'react-icons/hi';
import { SiGoogledrive } from 'react-icons/si';
import { FaCircle, FaCloudDownloadAlt, FaPowerOff, FaRProject, FaRegEye, FaRegHeart, FaUser, FaWindowClose } from 'react-icons/fa';
import TestimonialImage01 from '../images/alirubass.jpeg';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { FaClipboard } from "react-icons/fa";
import { Interweave } from 'interweave';
import AutoAds from '../ads/autoAds';
import GoogleAd from '../ads/ads';
import moment from 'moment';
import { XIcon } from '@heroicons/react/solid';
import AdsterraAd from '../ads/AdsterraAdArticle';
import BannerAd from '../ads/AdsterraAdArticle';
import { AdvertisingProvider } from 'react-advertising';
import AdvertisingSlot from 'react-advertising/lib/components/AdvertisingSlot';

const Page = () => {

    const websitePrefix = 'www.gigadevden.com';

    const { id, title } = useParams();

    const [data, setData] = useState([]);
    const [cvc, setCVC] = useState('');  // description
    const [recent, setRecent] = useState([]);

    // const [isLgScreen, setIsLgScreen] = useState(false);

    useEffect(() => {
        fetchData();

        // const loadAds = () => {
        //   if (window.adsbygoogle) {
        //     window.adsbygoogle.push({});
        //   } else {
        //     setTimeout(loadAds, 100);
        //   }
        // };

        // const script = document.createElement('script');
        // script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        // script.crossOrigin = 'anonymous';
        // script.async = true;
        // script.onload = loadAds;

        // document.body.appendChild(script);

        // return () => {
        //   document.body.removeChild(script);
        // };

        // const handleResize = () => {
        //     setIsLgScreen(window.innerWidth >= 1024); // Assuming "lg" size is 1024px or larger
        // };

        // handleResize(); // Check on initial render
        // window.addEventListener('resize', handleResize);

        // return () => {
        //     window.removeEventListener('resize', handleResize);
        // };


    }, []);



    const fetchData = async () => {
        try {
            const response = await fetch(
                `https://gigadevden.com/webarticlepage.php?id=${encodeURIComponent(id)}`
            );
            const json = await response.json();
            if (Object.keys(json).length === 0) {

            }
            else {

                setData([json.selectedArticle]);
                setCVC(json.selectedArticle.description);
                setRecent(json.recentArticles);
                //console.log(json.recentArticles);

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getFileIcon = (driveLink) => {
        const fileExtension = driveLink.split('.').pop().toLowerCase();

        if (fileExtension === 'pdf') {
            return <SiGoogledrive className="w-6 h-6 text-red-500" />;
        } else if (fileExtension === 'doc' || fileExtension === 'docx') {
            return <SiGoogledrive className="w-6 h-6 text-blue-500" />;
        } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
            return <SiGoogledrive className="w-6 h-6 text-green-500" />;
        } else {
            return <SiGoogledrive className="w-6 h-6 text-gray-500" />;
        }
    };


    const [downloaded, setDownloaded] = useState(0);

    const increaseDownload = async (driveLink) => {
        if (sessionStorage.getItem('${websitePrefix}-projectdownload' + id) !== '1') {
            if (navigator.onLine) {
                try {
                    const response = await fetch(
                        `https://gigadevden.com/webincreasedownload.php?id=${encodeURIComponent(id)}`
                    );
                    const text = await response.text();
                    if (text === 'Successful') {
                        sessionStorage.setItem('${websitePrefix}-projectdownload' + id, '1');
                        window.open(driveLink, '_blank');
                    } else {
                        toast(
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FaWindowClose className="mr-2 text-red-600" />
                                <span>Server error</span>
                            </div>,
                            {
                                className: 'custom-toast-success', // Add a custom CSS class for styling
                            }
                        );
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            } else {
                toast(
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FaWindowClose className="mr-2 text-red-600" />
                        <span>Connection failed</span>
                    </div>,
                    {
                        className: 'custom-toast-success', // Add a custom CSS class for styling
                    }
                );
            }
        }
        else {
            window.open(driveLink, '_blank');
        }
    };


    const downloadFile = (driveLink) => {

        increaseDownload(driveLink);

    };

    const [rotate, setRotate] = useState(false);
    const [count, setCount] = useState(0);

    const addCount = () => {
        setCount((prev) => prev + 1);
    };

    const minusCount = () => {
        if (count > 0) {
            setCount((prev) => prev - 1);
        }
    };



    const handleShareClick = () => {

        const linkToCopy = window.location.href;

        navigator.clipboard
            .writeText(linkToCopy)
            .then(() => {
                console.log('Link copied to clipboard:', linkToCopy);
                toast(
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FaClipboard className="mr-2 text-green-600" />
                        <span>Link copied to clipboard</span>
                    </div>,
                    {
                        className: 'custom-toast-success', // Add a custom CSS class for styling
                    }
                );
            })
            .catch((error) => {
                console.error('Error copying link to clipboard:', error);
                toast.error('Error copying link to clipboard');
            });
    };

    const [open, setOpen] = useState(false);

    // const transform = (node, index) => {
    //     if (node.type === 'tag' && node.name === 'pre') {
    //         return (
    //             <div key={index} className="bg-black text-white rounded-md mb-4">
    //                 <div className="flex items-center relative text-gray-200 bg-gray-800 px-4 py-2 text-xs font-sans justify-between rounded-t-md">
    //                     <span>code</span>
    //                     {/* <button className="flex ml-auto gap-2">
    //               <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w32000/svg">
    //                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
    //                 <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
    //               </svg>
    //               Copy code
    //             </button> */}
    //                 </div>
    //                 <div className="p-4 overflow-y-auto">
    //                     <code className="!whitespace-pre hljs language-jsx">{node.children[0].data}</code>
    //                 </div>
    //             </div>
    //         );
    //     }
    // };


    const description = "<p>Tic Tac Toe is an integral part of our childhood memories. It is a fun game consisting of two players who battle each other using Xs and Os. But did you know that this game can be a good C++ project? If you program it correctly, you can have fun playing it on your PC with your friends again. This tutorial explains how to create simple game of tic tac toe using C++ programming language. This article contains a simple source code of the Tic Tac Toe game for two players with proper documentation.</p><p><br></p><pre class=\"ql-syntax\" spellcheck=\"false\">#include&nbsp;&lt;iostream&gt;\r\nusing namespace&nbsp;std;\r\n\r\n\r\nint&nbsp;main() {\r\ncout &lt;&lt;&nbsp;\"Hello World!\";\r\nreturn&nbsp;0;\r\n}\r\n</pre> <p>Tic Tac Toe is an integral part of our childhood memories. It is a fun game consisting of two players who battle each other using Xs and Os. But did you know that this game can be a good C++ project? If you program it correctly, you can have fun playing it on your PC with your friends again. This tutorial explains how to create simple game of tic tac toe using C++ programming language. This article contains a simple source code of the Tic Tac Toe game for two players with proper documentation.</p><p><br></p><pre class=\"ql-syntax\" spellcheck=\"false\">#include&nbsp;&lt;iostream&gt;\r\nusing namespace&nbsp;std;\r\n\r\n\r\nint&nbsp;main() {\r\ncout &lt;&lt;&nbsp;\"Hello World!\";\r\nreturn&nbsp;0;\r\n}\r\n</pre>";



    function setCurrentProjectId(id, title) {
        const formattedTitle = title
            .toLowerCase() // Convert title to lowercase
            .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
            .trim()
            .replace(/\s+/g, '-'); // Replace spaces with dashes
        window.location.href = '/infodexeus/articles/' + id + '/' + formattedTitle;
    }

    const getTimeDifference = (timestamp, time) => {
        const currentTime = moment.utc(timestamp);
        const oldTime = moment.utc(time, "YYYY-MM-DD HH:mm:ss");
        const duration = moment.duration(currentTime.diff(oldTime));

        if (duration.asMinutes() < 60) {
            return `${Math.round(duration.asMinutes())} min`;
        } else if (duration.asHours() < 24) {
            return `${Math.round(duration.asHours())}h`;
        } else if (duration.asDays() < 7) {
            return `${Math.round(duration.asDays())}d`;
        } else if (duration.asMonths() < 1) {
            return `${Math.round(duration.asWeeks())}w`;
        } else if (duration.asYears() < 1) {
            return `${Math.round(duration.asMonths())} mon`;
        } else {
            return `${Math.round(duration.asYears())}y`;
        }
    };

    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapsible = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="relative bg-black">

            <HeaderMain />

            <Helmet>
                <title>{data[0]?.title || ''}</title>
                <meta
                    name="description"
                    content={data[0]?.description.replace(/(<([^>]+)>)/gi, '').replace(/\\/g, '') || ''}
                />
                <link
                    rel="canonical"
                    href={`https://gigadevden.com/infodexeus/articles/${data[0]?.id || ''}/${data[0]?.title?.toLowerCase()?.replace(/[^a-zA-Z0-9\s]/g, ' ').trim().replace(/\s+/g, '-') || ''}`}
                />
                {data[0]?.image && (
                    <meta property="og:image" content={`https://gigadevden.com${data[0]?.image}`} />
                )}
                {data[0]?.topic && (
                    <meta property="og:image:alt" content={data[0]?.topic} />
                )}

                <meta property="og:title" content={data[0]?.title || ''} />

                <meta property="og:description" content={data[0]?.description.replace(/(<([^>]+)>)/gi, '').replace(/\\/g, '') || ''} />

                <meta property="og:url" content={`https://gigadevden.com/infodexeus/articles/${data[0]?.id || ''}/${data[0]?.title?.toLowerCase()?.replace(/[^a-zA-Z0-9\s]/g, ' ').trim().replace(/\s+/g, '-') || ''}`} />

                <meta name="robots" content="index,follow" />


                <script async src="https://www.googletagmanager.com/gtag/js?id=G-S7ER3Z65YR" />
                <script>
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-S7ER3Z65YR');
          `}
                </script>





            </Helmet>


            <div className="min-h-screen">





                <div className="relative -mt-24">


                    <div className="relative">
                        <img src={`https://www.gigadevden.com${data[0]?.image}`}
                            class="h-[420px] lg:h-[550px] w-full object-cover" alt="image" />
                        <div className="absolute inset-0 bg-gradient-to-t from-black via-black to-transparent opacity-80"></div>
                    </div>

                    <div className="absolute top-0 pt-36 lg:pt-52 xl:pt-64 px-16 lg:w-4/5">
                        <p className="text-gray-100 text-sm px-5 mb-4">published in<span className="text-red-600 text-lg font-bold leading-none rounded-full"> ☉ Infodexeus</span>
                        </p>


                        <div className="w-full font-urbanist font-black text-gray-100 text-4xl px-5 leading-10 hidden lg:flex">
                            {data[0]?.title ? (
                                data[0].title
                            ) : (
                                <div className="flex flex-col mb-2">
                                    <div className="animate-pulse bg-gray-300 h-10 w-[900px] rounded" />
                                </div>

                            )}
                        </div>


                        <div className="w-full text-gray-300 px-5 pb-5 pt-2 hidden lg:flex">


                            {data[0]?.one_liner ? (
                                data[0].one_liner
                            ) : (
                                <div className="flex flex-col mb-2">
                                    <div className="animate-pulse bg-gray-300 h-6 w-[600px] rounded" />
                                </div>

                            )}


                        </div>




                        <div className="w-full flex flex-row space-x-4 items-center lg:hidden mb-6 mt-6">

                            <div className="w-24 h-24 mb-3 rounded-full bg-white bg-opacity-50 flex items-center justify-center">
                                {data[0]?.image ? (
                                    <img onClick={() => {
                                        const formattedName =
                                            data[0].name.toLowerCase() // Convert title to lowercase
                                                .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
                                                .trim()
                                                .replace(/\s+/g, '-'); // Replace spaces with dashes
                                        window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
                                    }}
                                        className="cursor-pointer w-full h-full overflow-hidden object-cover rounded-full bg-white" src={`https://gigadevden.com${data[0].devimage}`} alt="avatar" />

                                ) : (

                                    <div className="animate-pulse bg-gray-300 h-full w-full rounded-full"></div>

                                )}
                            </div>

                            <div className="flex flex-col">
                                {data[0]?.name ? (
                                    <h2 onClick={() => {
                                        const formattedName =
                                            data[0].name.toLowerCase() // Convert title to lowercase
                                                .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
                                                .trim()
                                                .replace(/\s+/g, '-'); // Replace spaces with dashes
                                        window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
                                    }}
                                        className="text-gray-200 text-xl tracking-normal font-semibold mb-2 cursor-pointer">{data[0].name}</h2>
                                ) : (

                                    <div className="mt-3 animate-pulse bg-gray-300 h-6 rounded mb-5"></div>

                                )}
                                <p className="flex text-gray-300 text-sm tracking-normal font-normal mb-5 text-center cursor-pointer"
                                    onClick={() => {
                                        const formattedName =
                                            data[0].name.toLowerCase() // Convert title to lowercase
                                                .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
                                                .trim()
                                                .replace(/\s+/g, '-'); // Replace spaces with dashes
                                        window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
                                    }}
                                >
                                    <span className="cursor-pointer mr-1 text-gray-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-pin" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <circle cx={12} cy={11} r={3} />
                                            <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 0 1 -2.827 0l-4.244-4.243a8 8 0 1 1 11.314 0z" />
                                        </svg>
                                    </span>
                                    {data[0]?.regioncity ? (
                                        <div>{data[0].regioncity}, {data[0].country}</div>
                                    ) : (


                                        <div className="mt-1 animate-pulse bg-gray-300 h-4 w-32 rounded mb-5"></div>

                                    )}
                                </p>



                            </div>

                        </div>
                    </div>
                </div>

























                {/* <GoogleAd slot="4381743618" googleAdId="ca-pub-6636754181323634" /> */}




                <div className="md:mx-6 bg-white flex flex-col mb-32 space-x-4 relative -mt-20 md:rounded-lg py-6 lg:px-6">







                    <div className="group flex flex-col gap-2 rounded-lg bg-black z-10 shadow-md px-5 pt-3 pb-2 text-white mx-6 mb-4" tabIndex="1">
                        <div className="flex cursor-pointer items-end justify-between" onClick={toggleCollapsible}>
                            <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
                                <p className="text-sm leading-6 text-white">
                                    <strong className="font-semibold">Infodexeus</strong>
                                    <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                        <circle cx={1} cy={1} r={1} />
                                    </svg>
                                    Join our vibrant community, create compelling content, and inspire the world with your words.
                                </p>

                            </div>
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/9/96/Chevron-icon-drop-down-menu-WHITE.png"
                                className={`mb-2 h-2 w-3 transition-all duration-500 ${isCollapsed ? '' : 'rotate-180'}`}
                                alt="Chevron Icon"
                            />
                        </div>
                        <div
                            className={`space-x-4 font-urbanist collapsible-content ${isCollapsed ? 'invisible max-h-0 opacity-0' : 'visible max-h-screen opacity-100 duration-1000'}`}
                        >
                            <a
                                href="/console-home"
                                className="flex-none rounded bg-gray-600 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                            >
                                Open Console
                                {/* Console <span aria-hidden="true">&rarr;</span> */}
                            </a>
                            <span className="leading-7 text-sm">
                                Embark on a journey of knowledge with Infodexeus, where you'll find a treasure trove of informative articles, exciting projects, and groundbreaking research on Gigadevden. Unleash your true potential in the realm of development by exploring our platform. Join our dynamic developer console to level up your skills and connect with a thriving community of fellow developers, ready to collaborate and innovate together.
                            </span>
                        </div>
                    </div>


                    {/* profile bar */}
                    <div className="m-8 lg:flex lg:flex-row items-center hidden mb-6 justify-between">

                        <div className="flex flex-row space-x-4">
                            <div className="ml-4 w-16 h-16 mb-3 rounded-full bg-white bg-opacity-50 flex items-center justify-center">
                                {data[0]?.image ? (
                                    <img onClick={() => {
                                        const formattedName =
                                            data[0].name.toLowerCase() // Convert title to lowercase
                                                .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
                                                .trim()
                                                .replace(/\s+/g, '-'); // Replace spaces with dashes
                                        window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
                                    }}
                                        className="cursor-pointer w-full h-full overflow-hidden object-cover rounded-full bg-white" src={`https://gigadevden.com${data[0].devimage}`} alt="avatar" />

                                ) : (

                                    <div className="animate-pulse bg-gray-300 h-full w-full rounded-full"></div>

                                )}
                            </div>

                            <div className="flex flex-col">
                                {data[0]?.name ? (
                                    <h2 onClick={() => {
                                        const formattedName =
                                            data[0].name.toLowerCase() // Convert title to lowercase
                                                .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
                                                .trim()
                                                .replace(/\s+/g, '-'); // Replace spaces with dashes
                                        window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
                                    }}
                                        className="text-gray-500 text-lg tracking-normal font-semibold mb-2 cursor-pointer">{data[0].name}</h2>
                                ) : (

                                    <div className="mt-3 animate-pulse bg-gray-300 h-5 w-32 rounded mb-5"></div>

                                )}
                                <p className="flex text-gray-400 text-sm tracking-normal font-normal mb-5 text-center cursor-pointer"
                                    onClick={() => {
                                        const formattedName =
                                            data[0].name.toLowerCase() // Convert title to lowercase
                                                .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
                                                .trim()
                                                .replace(/\s+/g, '-'); // Replace spaces with dashes
                                        window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
                                    }}
                                >
                                    <span className="cursor-pointer mr-1 text-gray-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-pin" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <circle cx={12} cy={11} r={3} />
                                            <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 0 1 -2.827 0l-4.244-4.243a8 8 0 1 1 11.314 0z" />
                                        </svg>
                                    </span>
                                    {data[0]?.regioncity ? (
                                        <div>{data[0].regioncity}, {data[0].country}</div>
                                    ) : (

                                        <div className="mt-1 animate-pulse bg-gray-300 h-3 w-16 rounded mb-5"></div>

                                    )}
                                </p>

                            </div>



                            {/* 
{data[0]?.name ? (
    <button className="py-3 bg-white px-5 flex items-center justify-center text-sm focus:outline-none border hover:bg-green-600 hover:text-white rounded-lg text-green-600 border-green-600"
        onClick={() => {
            const formattedName =
                data[0].name.toLowerCase() // Convert title to lowercase
                    .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
                    .trim()
                    .replace(/\s+/g, '-'); // Replace spaces with dashes
            window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
        }}
    >
        <FaRegCircle className="h-5 w-5 text-gray-800 mr-2" />
        More by {data[0].name ? data[0].name.split(' ')[0] : ''}
    </button>



) : (

    <div className="mt-12 animate-pulse bg-gray-300 h-12 w-32 rounded"></div>

)} */}

                        </div>

                        <div className="flex flex-col mr-6">


                            <a href="" className="text-red-600 font-bold tracking-widest">

                                {data[0]?.topic ? (
                                    data[0].topic.toUpperCase()
                                ) : (

                                    <div className="animate-pulse bg-gray-300 h-8 w-48 rounded" />


                                )}

                            </a>

                            <span className="text-gray-400 text-xs">
                                {data[0]?.time ? new Date(data[0].time).toLocaleString('en-US', {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric'
                                }) + ' UTC' : ''}
                            </span>



                        </div>








                    </div>







                    <div className="flex flex-row">

                        <div className='w-full lg:w-4/5 mx-auto'>
                            <div className="mx-5 my-3 text-sm flex flex-col lg:hidden">






                                <a href="" className="text-red-600 font-bold tracking-widest">

                                    {data[0]?.topic ? (
                                        data[0].topic.toUpperCase()
                                    ) : (
                                        <div className="flex flex-col mb-2">
                                            <div className="animate-pulse bg-gray-300 h-5 w-32 rounded" />
                                        </div>

                                    )}

                                </a>

                                <span className="text-gray-400 text-xs">
                                    {data[0]?.time ? new Date(data[0].time).toLocaleString('en-US', {
                                        month: 'short',
                                        day: 'numeric',
                                        year: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    }) + ' UTC' : ''}
                                </span>
                            </div>


                            <div className="w-full font-urbanist font-black text-gray-800 text-3xl md:text-4xl px-5 md:leading-10 flex lg:hidden">
                                {data[0]?.title ? (
                                    data[0].title
                                ) : (
                                    <div className="flex flex-col mb-2">
                                        <div className="animate-pulse bg-gray-300 h-6 w-80 rounded mb-3" />

                                    </div>

                                )}
                            </div>


                            <div className="w-full text-gray-500 px-5 pb-5 pt-2 flex lg:hidden">


                                {data[0]?.one_liner ? (
                                    data[0].one_liner
                                ) : (
                                    <div className="flex flex-col mb-2">
                                        <div className="animate-pulse bg-gray-300 h-4 w-64 mb-3 rounded" />

                                        <div className="animate-pulse bg-gray-300 h-4 w-32 rounded" />
                                    </div>

                                )}


                            </div>


                            {/* <div className="p-2">
                             
                                    <div>
                                        <GoogleAd slot="3905471558" googleAdId="ca-pub-6636754181323634" />

                                    </div>
                          
                            </div> */}

 





                            <div className="mt-6">
                                <ReactQuill
                                    value={cvc.replace(/\\r\\n/g, '</br>').replace(/\\/g, '')}
                                    //value={ReactHtmlParser(cvc.replace(/\\/g, ''), { transform })}
                                    readOnly={true} // Set readOnly prop to true
                                    modules={{
                                        toolbar: false // Disable the toolbar
                                    }}
                                />
                            </div>





                            {/* 
                <ReactQuill
  value={cvc}
  onChange={(value) => setCVC(value)}
  formats={[
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'code-block'
  ]}
  modules={{
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // all header options
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }], // all types of lists
      [{ 'indent': '-1' }, { 'indent': '+1' }], // indent options
      [{ 'font': [] }], // all font options
      [{ 'size': ['small', false, 'large', 'huge'] }], // all size options
      ['link', 'image'],
      ['clean'], ['code-block'],
      [{ 'color': [] }], // color option for general text
      [{ 'blockquote': ['color'] }] // color option for blockquote
    ],
  }}
/> */}




                            {/* <ReactQuill
                    value={cvc}
                    onChange={(value) => setCVC(value)}
                    formats={[
                        'header', 'font', 'size',
                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                        'list', 'bullet', 'indent',
                        'link', 'image', 'color', 'code-block'  // include 'code-block' here
                    ]}
                    modules={{
                        toolbar: [
                            [{ 'header': [1, 2, false] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                            ['link', 'image'],
                            ['clean'], ['code-block']  // include 'code-block' here
                        ],
                    }}
                /> */}


                            {/* <h>Write</h>


                    <div className="text-editor">
                        <EditorToolbar />
                        <ReactQuill
                            theme="snow"
                            value={cvc.replace(/\\r\\n/g, '</br>').replace(/\\/g, '')}

                            onChange={(value) => setCVC(value)}
                            placeholder={"Write something awesome..."}
                            modules={modules}
                            formats={formats}
                        />
                    </div>


                    <p>Test</p>

                    {cvc} */}

                            {/* {cvc}
                <p>New research</p> */}


                            {/* <div className="bg-black text-white rounded-md mb-4 shadow-lg">
                        <div className="flex items-center relative text-gray-200 bg-gray-800 px-4 py-2 text-xs font-sans justify-between rounded-t-md">
                            <span>code</span>

                        </div>
                        <div className="p-4 overflow-y-auto">
                            <code>My code</code>
                        </div>
                    </div> */}





                            {/* <div className="w-full text-gray-800 text-4xl px-5 font-bold leading-none">
                            Kemp and Bottoms hurl insults at each other in Georgia mask feud
                        </div>

                        <div className="w-full text-gray-500 px-5 pb-5 pt-2">
                            The war of words comes after the governor sued the Atlanta mayor over her city’s mask mandate.
                        </div>

                        <div className="mx-5">
                            <img src="https://static.politico.com/dims4/default/fcd6d6a/2147483647/resize/1920x/quality/90/?url=https%3A%2F%2Fstatic.politico.com%2F22%2F87%2F2259ffd444678054896b9fa32b4d%2Fgettyimages-1221513169.jpg" />
                        </div>

                        <div className="w-full text-gray-600 text-normal mx-5">
                            <p className="border-b py-3">Georgia Gov. Brian Kemp speaks to the media during a press conference. | Kevin C. Cox/Getty Images</p>
                        </div>

                        <div className="w-full text-gray-600 font-thin italic px-5 pt-3">
                            By <strong className="text-gray-700">Quint Forgey</strong>
                            <br />
                            07/17/2020 09:57 AM EDT
                            <br />
                            Updated: 07/17/2020 10:33 AM EDT
                        </div>

                        <div className="px-5 w-full mx-auto">
                            <p className="my-5">Georgia Gov. Brian Kemp and Atlanta Mayor Keisha Lance Bottoms hurled insults at one another Friday, as their legal battle over whether to mandate masks in the state’s capital city entered its second day.</p>
                          
                        </div> */}
                        </div>




                        <div className="lg:flex lg:flex-col lg:w-1/5 hidden">



      

    

                         <div class="!z-5 relative flex flex-col rounded-[20px] max-w-[300px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 flex flex-col w-full !p-4 3xl:p-![18px] bg-white undefined">
                <div class="h-full w-full">
                    <div class="relative w-full">
                        <img src="https://horizon-tailwind-react-git-tailwind-components-horizon-ui.vercel.app/static/media/Nft3.3b3e6a4b3ada7618de6c.png" class="mb-3 h-full w-full rounded-xl 3xl:h-full 3xl:w-full" alt=""/>
                        <button class="absolute top-3 right-3 flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:cursor-pointer">
                            <div class="flex h-full w-full items-center justify-center rounded-full text-xl hover:bg-gray-50">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"></path></svg>
                            </div>
                        </button>
                    </div>
                    <div class="mb-3 flex items-center justify-between px-1 md:items-start">
                        <div class="mb-2">
                            <p class="text-lg font-bold text-navy-700"> Verified </p>
                            <p class="mt-1 text-sm font-medium text-gray-600 md:mt-2">By HR Dept. </p>
                        </div>
                        <div class="flex flex-row-reverse md:mt-2 lg:mt-0">
                            <span class="z-0 ml-px inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-[#E0E5F2] text-xs text-navy-700 ">+5</span><span class="z-10 -mr-3 h-8 w-8 rounded-full border-2 border-white">
                                <img class="h-full w-full rounded-full object-cover" src="https://horizon-tailwind-react-git-tailwind-components-horizon-ui.vercel.app/static/media/avatar1.eeef2af6dfcd3ff23cb8.png" alt=""/>
                            </span>
                            <span class="z-10 -mr-3 h-8 w-8 rounded-full border-2 border-white">
                                <img class="h-full w-full rounded-full object-cover" src="https://horizon-tailwind-react-git-tailwind-components-horizon-ui.vercel.app/static/media/avatar2.5692c39db4f8c0ea999e.png" alt=""/>
                            </span>
                            <span class="z-10 -mr-3 h-8 w-8 rounded-full border-2 border-white">
                                <img class="h-full w-full rounded-full object-cover" src="https://horizon-tailwind-react-git-tailwind-components-horizon-ui.vercel.app/static/media/avatar3.9f646ac5920fa40adf00.png" alt=""/>
                            </span>
                        </div>
                    </div>
                    <div class="flex items-center justify-between md:items-center lg:justify-between ">
                        {/* <div class="flex">
                            <p class="!mb-0 text-sm font-bold text-brand-500">Current Bid: 0.91 <span>ETH</span></p>
                        </div> */}
                        <button href="" class="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700">Place Bid</button>
                    </div>
                </div>
            </div>


                                  <div class="max-w-md py-4 px-8 bg-gray-200 shadow-lg rounded-lg ml-2">
  {/* <div class="flex justify-center md:justify-end -mt-16">
    <img class="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" src="https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"/>
  </div> */}
  <div>
    <h2 class="text-gray-800 text-xl font-semibold">Non-Exclusive Projects</h2>
    <p class="mt-2 text-gray-600">Get your base project for FYP's and commercial use</p>
  </div>
  <div class="flex justify-end mt-4">
    <a href="/projects" class="text-md font-medium text-indigo-500">Get it Now</a>
  </div>
</div>

                            {/* <div className="p-2">
                                {isLgScreen && (
                                    <div>
                                        <GoogleAd slot="7381697079" googleAdId="ca-pub-6636754181323634" />
                                    </div>
                                )}
                            </div> */}


                            {/* <div className="w-full px-2 lg:flex lg:flex-col items-center py-10 hidden">

                                <div className="w-24 h-24 mb-3 p-2 rounded-full bg-white bg-opacity-50 flex items-center justify-center">
                                    {data[0]?.image ? (
                                        <img className="w-full h-full overflow-hidden object-cover rounded-full bg-white" src={`https://gigadevden.com${data[0].devimage}`} alt="avatar" />

                                    ) : (

                                        <div className="animate-pulse bg-gray-300 h-full w-full rounded-full"></div>

                                    )}
                                </div>
                                {data[0]?.name ? (
                                    <h2 className="text-gray-500 text-xl tracking-normal font-semibold mb-2">{data[0].name}</h2>
                                ) : (

                                    <div className="mt-3 animate-pulse bg-gray-300 h-5 w-1/2 rounded mb-5"></div>

                                )}
                                <p className="flex text-gray-400 text-sm tracking-normal font-normal mb-5 text-center">
                                    <span className="cursor-pointer mr-1 text-gray-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-pin" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <circle cx={12} cy={11} r={3} />
                                            <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 0 1 -2.827 0l-4.244-4.243a8 8 0 1 1 11.314 0z" />
                                        </svg>
                                    </span>
                                    {data[0]?.regioncity ? (
                                        <div>{data[0].regioncity}, {data[0].country}</div>
                                    ) : (

                                        <div></div>

                                    )}
                                </p>
                                {data[0]?.bio ? (
                                    <p className="text-gray-400 text-xs tracking-normal font-normal mb-2 text-center w-10/12">
                                        {data[0].bio.split(/\s+/).map((word, index) => {
                                            if (word.startsWith("#")) {
                                                const hashtag = word.substring(1);
                                                return (
                                                    <span key={index} onClick={() => window.open(`https://www.google.com/search?q=${hashtag}`)} className="text-blue-500 cursor-pointer">
                                                        {word}{" "}
                                                    </span>
                                                );
                                            } else {
                                                return <span key={index}>{word} </span>;
                                            }
                                        })}
                                    </p>

                                ) : (

                                    <div className="mt-3 animate-pulse bg-gray-300 h-32 w-10/12 rounded mb-5"></div>

                                )}




                                {data[0]?.name ? (
                                    <button className="py-3 bg-white px-5 flex items-center justify-center text-sm focus:outline-none border hover:bg-green-600 hover:text-white rounded-lg text-green-600 border-green-600 mt-12"
                                        onClick={() => {
                                            const formattedName =
                                                data[0].name.toLowerCase() // Convert title to lowercase
                                                    .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
                                                    .trim()
                                                    .replace(/\s+/g, '-'); // Replace spaces with dashes
                                            window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
                                        }}
                                    >
                                        <FaRegCircle className="h-5 w-5 text-gray-800 mr-2" />
                                        More by {data[0].name ? data[0].name.split(' ')[0] : ''}
                                    </button>



                                ) : (

                                    <div className="mt-12 animate-pulse bg-gray-300 h-12 w-32 rounded"></div>

                                )}





                            </div> */}

                            <div>
                            </div>
                        </div>

                    </div>






                </div>

                {/* <div className="absolute w-full text-right text-sm bottom-0 p-3 mx-auto text-gray-400">
                Made with <i className="text-red-300 fa fa-heart"></i> By <a href="https://timelydevs.com">Timely Systems</a>
            </div> */}

                <div className="w-full px-4 flex-col items-center py-10 bg-white hidden">

                    <div className="w-24 h-24 mb-3 p-2 rounded-full bg-white bg-opacity-50 flex items-center justify-center">
                        {data[0]?.image ? (
                            <img className="w-full h-full overflow-hidden object-cover rounded-full" src={`https://gigadevden.com${data[0].devimage}`} alt="avatar" />

                        ) : (

                            <div className="animate-pulse bg-gray-300 h-full w-full rounded-full"></div>

                        )}
                    </div>
                    {data[0]?.name ? (
                        <h2 className="text-gray-500 text-xl tracking-normal font-semibold mb-2">{data[0].name}</h2>
                    ) : (

                        <div className="mt-3 animate-pulse bg-gray-300 h-5 w-1/2 rounded mb-5"></div>

                    )}
                    <p className="flex text-gray-400 text-sm tracking-normal font-normal mb-5 text-center">
                        <span className="cursor-pointer mr-1 text-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-pin" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <circle cx={12} cy={11} r={3} />
                                <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 0 1 -2.827 0l-4.244-4.243a8 8 0 1 1 11.314 0z" />
                            </svg>
                        </span>
                        {data[0]?.regioncity ? (
                            <div>{data[0].regioncity}, {data[0].country}</div>
                        ) : (

                            <div></div>

                        )}
                    </p>
                    {data[0]?.bio ? (
                        <p className="text-gray-400 text-sm tracking-normal font-normal text-center w-10/12">
                            {data[0].bio.split(/\s+/).map((word, index) => {
                                if (word.startsWith("#")) {
                                    const hashtag = word.substring(1);
                                    return (
                                        <span key={index} onClick={() => window.open(`https://www.google.com/search?q=${hashtag}`)} className="text-blue-500 cursor-pointer">
                                            {word}{" "}
                                        </span>
                                    );
                                } else {
                                    return <span key={index}>{word} </span>;
                                }
                            })}
                        </p>

                    ) : (

                        <div className="mt-3 animate-pulse bg-gray-300 h-32 w-full rounded mb-5"></div>

                    )}




                    {data[0]?.name ? (
                        <button className="py-3 bg-white px-5 flex items-center justify-center text-s focus:outline-none border hover:bg-green-600 hover:text-white rounded-lg text-green-600 border-green-600 mt-12"
                            onClick={() => {
                                const formattedName =
                                    data[0].name.toLowerCase() // Convert title to lowercase
                                        .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
                                        .trim()
                                        .replace(/\s+/g, '-'); // Replace spaces with dashes
                                window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
                            }}
                        >
                            <FaRegCircle className="h-5 w-5 text-gray-800 mr-2" />
                            More by {data[0].name}
                        </button>



                    ) : (

                        <div className="mt-12 animate-pulse bg-gray-300 h-12 w-48 rounded"></div>

                    )}





                </div>


                <div className="my-16 hidden">
                    <div className="mx-8 my-3 text-sm">
                        <a href="" className="text-red-600 font-bold tracking-widest flex items-center">
                            <span className="flex justify-center items-center"
                                style={{
                                    position: 'relative',
                                    display: 'flex',
                                    animation: 'glowing 1s infinite',
                                    backgroundColor: 'transparent',
                                    borderRadius: '100%',
                                    width: '1.5em',
                                    height: '1.5em',

                                }}>⚫</span>

                            <h6 className="ml-2">New</h6>
                        </a>
                    </div>

                    {recent.map((article, index) => (
                        <div key={index}>
                            <div className="mx-8 bg-gray-100 rounded-lg p-4 mb-4 flex flex-row justify-center items-center">
                                <div className="text-xs font-bold text-red-600 w-1/6 flex justify-center items-center">{getTimeDifference(article.timestamp, article.time)} ago</div>
                                <button
                                    onClick={() => setCurrentProjectId(article.id, article.title)}
                                    className="text-sm w-5/6 text-gray-600 hover:text-blue-500 font-bold text-left transition-colors duration-200 ease-in-out"
                                >
                                    {article.title}
                                </button>
                            </div>


                        </div>
                    ))}



                </div>


            </div>

            <Footer />

        </div>






    );
};

export default Page;
