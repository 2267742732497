import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive'
import { SearchIcon } from '@heroicons/react/solid';
import { useState, useEffect } from 'react';
import gameden from '../images/features-03-image-01.png'
import Banner from '../partials/Banner.jsx';
import Letter from '../partials/Newsletter.jsx';
import { motion } from "framer-motion";
import HeaderMain from '../partials/HeaderMain.jsx'
import Footer from '../partials/Footer.jsx';
import '../css/circles.css';
import HomePageNewsUnit from '../partials/HomePageNewsUnit';
import HomePageContentUnits from '../partials/HomePageContentUnits';

import { Helmet } from 'react-helmet';
import HomeTop from '../partials/HomeTop';
import SourceCode from '../partials/SourceCode';
import { FaArrowRight, FaSearch } from 'react-icons/fa';
import Ai from '../partials/Ai';

import background from '../images/NUHostel.png'
import background2 from '../images/women.png'
import contentPicture from '../images/background_design_id_153.jpg'
import backgroundGrid from '../images/grid-hero-black.png'
import HomeTeam from '../partials/HomeTeam';

import backgroundVideo from '../videos/video.mp4'

import { CloudUploadIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/solid'
import HeaderHome from '../partials/HeaderHome';

import eventPoster1 from '../images/pet_care_poster.webp'
import eventPoster2 from '../images/church_sunday_prayer.webp'
import eventPoster3 from '../images/background_design_id_49.webp'
import downPoster1 from '../images/background_design_id_78.webp'
import downPoster2 from '../images/background_design_id_96.webp'
import mainPoster from '../images/background_design_id_178.jpg'


import TestimonialImage01 from '../images/ali rubass.jpg';
import TestimonialImage02 from '../images/rehan1.jpeg';
import TestimonialImage03 from '../images/inara-sheikh - Copy.jpeg';

import SecondaryBackground from '../images/background_design_id_161.jpg'

import blueAndYellow from '../images/blue_and_yellow.jpg'



function Home() {

  const [postUrl, setPostUrl] = useState('');
  const [countdown, setCountdown] = useState({ hours: 0, minutes: 0, seconds: 0 });

  const [time, setTime] = useState('2022-06-01T00:00:00');

  const [data, setData] = useState('');

  const fetchData = async () => {
    try {
      const response = await fetch(`https://gigadevden.com/saletimer.php`);
      console.log(response); // Log the response object to inspect its content
      const json = await response.json();
      if (Array.isArray(json) && json.length === 1) {
        setTime(json[0].time);
        console.log(time);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {
    // const sessionStorageValue = localStorage.getItem('${websitePrefix}-publiclogin');
    // if (sessionStorageValue !== '1') {
    //     //setShowOverlay(true);
    // }
    // else {

    // }
    // fetchData();
  }, []);


  useEffect(() => {


    const endTime = new Date(time);


    const timer = setInterval(() => {
      const now = new Date();
      //console.log(endTime);
      //console.log(now);
      const timeDiff = endTime - now;

      if (timeDiff <= 0) {
        clearInterval(timer);
      } else {
        setCountdown(prevCountdown => {
          const remainingSeconds = Math.floor(timeDiff / 1000);
          const remainingHours = Math.floor(remainingSeconds / 3600);
          const remainingMinutes = Math.floor((remainingSeconds % 3600) / 60);
          const remainingSecondsFormatted = remainingSeconds % 60;

          console.log(countdown);

          return { hours: remainingHours, minutes: remainingMinutes, seconds: remainingSecondsFormatted };
        });
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };

  }, [countdown, time]);

  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const [isOpen, setIsOpen] = useState(true);
  const [isOpen2, setIsOpen2] = useState(false);




  const handleDrawerButtonClick = () => {
    setIsOpen(!isOpen);

  };

  useEffect(() => {
    console.log(isOpen);
  }, [isOpen]);


  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // handle search submit here
    window.location.href = "/error";
  };



  //gameden
  const [player, setPlayer] = useState("X");
  const [winner, setWinner] = useState(null);
  const [board, setBoard] = useState(Array(9).fill(null));

  const handleClick = (index) => {
    if (board[index] || winner) return;

    const newBoard = [...board];
    newBoard[index] = player;
    setBoard(newBoard);

    const newPlayer = player === "X" ? "O" : "X";
    setPlayer(newPlayer);

    const newWinner = checkWinner(newBoard);
    setWinner(newWinner);
  };

  const checkWinner = (board) => {
    const lines = [[0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
    ];

    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (board[a] && board[a] === board[b] && board[a] === board[c]) {
        return board[a];
      }
    }

    return null;
  };

  const resetGame = () => {
    setPlayer("X");
    setWinner(null);
    setBoard(Array(9).fill(null));
  };

  const [menu, setMenu] = useState(false);

  const stats = [
    { id: 1, name: 'Transactions every 24 hours', value: '44 million' },
    { id: 2, name: 'Assets under holding', value: '$119 trillion' },
    { id: 3, name: 'New users annually', value: '46,000' },
  ]

  const [isActive, setIsActive] = useState(false);

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };



  const images = [
    eventPoster1,
    eventPoster2,
    eventPoster3,
    // Add more image URLs as needed
  ];



  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change the interval time as desired (in milliseconds)

    return () => clearInterval(interval);
  }, []);

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };


  const imageText = [
    'International Dog Day',
    'Church Prayer',
    'World Animal Day',
    // Add more text values as needed
  ];

  const imageDate = [
    '26',
    '15',
    '4',
    // Add more text values as needed
  ];

  const imageDescription = [
    'Celebrate the Internation Dog Day on 26 August',
    'Get together for the prayer this friday',
    'World Animal day is coming on 4 October',
    // Add more text values as needed
  ];

  return (
    <>


      <Helmet>
        <title>Gigadevden.com: Design You rOwn Solution - Get a Free Website</title>
        <meta name="description" content="Order a Website, Designs and Tech Solutions to rebuild the reputation of your brand - Try for free" />
        <link rel="canonical" href="https://gigadevden.com/" />
        <meta name="keywords" content="articles, projects, research, development, developer console, collaboration, Gigadevden, programming languages, technology frameworks, Programming, Software Development, Art, Creative Design, Business, Entrepreneurship, Engineering, Technology, Science, Research, Education, Health, Medicine, Social Sciences, Environment, Sustainability, Gaming, Entertainment, JavaScript, Python, React, Angular, Node.js, React Native, Django, Laravel" />
        <meta property="og:title" content="Informative Articles on Infodexeus, Exciting Projects, and Cutting-edge Research — Gigadevden" />
        <meta property="og:description" content="Discover a wealth of informative articles, exciting projects, and cutting-edge research at Gigadevden. Explore our platform to unlock your potential in the world of development. Join our developer console to enhance your skills and collaborate with fellow developers in Programming, Software Development, Art, Creative Design, Business, Entrepreneurship, Engineering, Technology, Science, Research, Education, Health, Medicine, Social Sciences, Environment, Sustainability, Gaming, and Entertainment." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://gigadevden.com/" />
        <meta property="og:image" content="https://gigadevden.com/dotdenblack.png" />
        <meta property="og:image:alt" content="Gigadevden - Empowering Developers" />
        <meta name="robots" content="index,follow" />

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-S7ER3Z65YR" />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-S7ER3Z65YR');
          `}
        </script>

      </Helmet>



      <div className='flex flex-col min-h-screen'>








        {/* <div className="flex flex-row w-full md:w-[100%] h-auto min-h-0 flex-shrink-0">



      </div> */}
        <div className="flex flex-col md:flex-row h-auto min-h-0">



          <div className="lg:w-16">
            <aside class="fixed z-50 hidden lg:flex shadow-gray-500 shadow-md">
              <div class="flex flex-col items-center w-16 h-screen pb-32 pt-48 space-y-8 bg-white">
                {/* <a href="#">
                  <img class="w-auto h-6" src="https://merakiui.com/images/logo.svg" alt="" />
                </a> */}

                <a href="#" class="p-1.5 focus:outline-nones transition-colors duration-200 rounded-lg  text-black-400 bg-yellow-500">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                  </svg>
                </a>

                <a href="#" class="p-1.5  transition-colors duration-200  rounded-lg text-gray-600 hover:bg-gray-800">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                  </svg>
                </a>

                <a href="#" class="p-1.5 focus:outline-nones transition-colors duration-200 rounded-lg text-gray-600 hover:bg-gray-800 ">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                  </svg>
                </a>

                <a href="#" class="p-1.5 focus:outline-nones transition-colors duration-200 rounded-lg text-gray-600 hover:bg-gray-800">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                  </svg>
                </a>

                <a href="/profile" class="p-1.5 focus:outline-nones transition-colors duration-200 rounded-lg text-gray-600 hover:bg-gray-800">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </a>
              </div>

              {/* <div class="h-screen py-8 overflow-y-auto bg-white border-l border-r sm:w-64 w-60 dark:bg-gray-900 dark:border-gray-700">
        <h2 class="px-5 text-lg font-medium text-gray-800 dark:text-white">Accounts</h2>

        <div class="mt-8 space-y-4">
            <button class="flex items-center w-full px-5 py-2 transition-colors duration-200 dark:hover:bg-gray-800 gap-x-2 hover:bg-gray-100 focus:outline-none">
                <img class="object-cover w-8 h-8 rounded-full" src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=faceare&facepad=3&w=688&h=688&q=100" alt=""/>
            
                <div class="text-left rtl:text-right">
                    <h1 class="text-sm font-medium text-gray-700 capitalize dark:text-white">Mia John</h1>
    
                    <p class="text-xs text-gray-500 dark:text-gray-400">11.2 Followers</p>
                </div>
            </button>

            <button class="flex items-center w-full px-5 py-2 transition-colors duration-200 dark:hover:bg-gray-800 gap-x-2 hover:bg-gray-100 focus:outline-none">
                <img class="object-cover w-8 h-8 rounded-full" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&h=880&q=80" alt=""/>
            
                <div class="text-left rtl:text-right">
                    <h1 class="text-sm font-medium text-gray-700 capitalize dark:text-white">arthur melo</h1>
    
                    <p class="text-xs text-gray-500 dark:text-gray-400">1.2 Followers</p>
                </div>
            </button>

            <button class="flex items-center w-full px-5 py-2 transition-colors duration-200 bg-gray-100 dark:bg-gray-800 gap-x-2 focus:outline-none">
                <div class="relative">
                    <img class="object-cover w-8 h-8 rounded-full" src="https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&h=764&q=100" alt=""/>
                    <span class="h-2 w-2 rounded-full bg-emerald-500 absolute right-0.5 ring-1 ring-white bottom-0"></span>
                </div>

                <div class="text-left rtl:text-right">
                    <h1 class="text-sm font-medium text-gray-700 capitalize dark:text-white">Jane Doe</h1>
    
                    <p class="text-xs text-gray-500 dark:text-gray-400">15.6 Followers</p>
                </div>
            </button>

            <button class="flex items-center w-full px-5 py-2 transition-colors duration-200 dark:hover:bg-gray-800 gap-x-2 hover:bg-gray-100 focus:outline-none">
                <img class="object-cover w-8 h-8 rounded-full" src="https://images.unsplash.com/photo-1531590878845-12627191e687?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&h=764&q=80" alt=""/>
            
                <div class="text-left rtl:text-right">
                    <h1 class="text-sm font-medium text-gray-700 capitalize dark:text-white">Amelia. Anderson</h1>
    
                    <p class="text-xs text-gray-500 dark:text-gray-400">32.9 Followers</p>
                </div>
            </button>

            <button class="flex items-center w-full px-5 py-2 transition-colors duration-200 dark:hover:bg-gray-800 gap-x-2 hover:bg-gray-100 focus:outline-none">
                <img class="object-cover w-8 h-8 rounded-full" src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&h=687&q=80" alt=""/>
            
                <div class="text-left rtl:text-right">
                    <h1 class="text-sm font-medium text-gray-700 capitalize dark:text-white">Joseph Gonzalez</h1>
    
                    <p class="text-xs text-gray-500 dark:text-gray-400">100.2 Followers</p>
                </div>
            </button>

            <button class="flex items-center w-full px-5 py-2 transition-colors duration-200 hover:bg-gray-100 dark:hover:bg-gray-800 gap-x-2 focus:outline-none">
                <div class="relative">
                    <img class="object-cover w-8 h-8 rounded-full" src="https://images.unsplash.com/photo-1488508872907-592763824245?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&h=1470&q=80" alt=""/>
                    <span class="h-2 w-2 rounded-full bg-emerald-500 absolute right-0.5 ring-1 ring-white bottom-0"></span>
                </div>

                <div class="text-left rtl:text-right">
                    <h1 class="text-sm font-medium text-gray-700 capitalize dark:text-white">Olivia Wathan</h1>
    
                    <p class="text-xs text-gray-500 dark:text-gray-400">8.6 Followers</p>
                </div>
            </button>

            <button class="flex items-center w-full px-5 py-2 transition-colors duration-200 hover:bg-gray-100 dark:hover:bg-gray-800 gap-x-2 focus:outline-none">
                <div class="relative">
                    <img class="object-cover w-8 h-8 rounded-full" src="https://images.unsplash.com/photo-1608174386344-80898cec6beb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&h=687&q=80" alt=""/>
                    <span class="h-2 w-2 rounded-full bg-emerald-500 absolute right-0.5 ring-1 ring-white bottom-0"></span>
                </div>

                <div class="text-left rtl:text-right">
                    <h1 class="text-sm font-medium text-gray-700 capitalize dark:text-white">Junior REIS</h1>
    
                    <p class="text-xs text-gray-500 dark:text-gray-400">56.6 Followers</p>
                </div>
            </button>
        </div>
    </div> */}
            </aside>

          </div>

          {/* Main content */}

          <div className="bg-white w-full md:w-[100%] min-h-screen flex-grow font-urbanist"
          >
            {/* Header */}
            {/* <HeaderMain /> */}
            <HeaderHome />






          





            <div className="block">








              {/* <div className="absolute  w-full opacity-20 rounded pointer-events-none">
                <div className="relative">
                    <video className="w-full" controls autoPlay muted loop>
                        <source src={backgroundVideo} type="video/mp4" />
                    </video>
                    <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-transparent to-transparent opacity-200"></div>

                    <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent opacity-200"></div>

                </div>
            </div> */}
              {/* <img src={background} className='absolute left-0 w-[300px] md:w-[1000px] opacity-50 rotate-45'/> */}
              {/* <img src={background2} className='absolute right-0 w-[1000px] opacity-20'
            style={{ filter: 'grayscale(100%)' }}/> */}
              {/* <img src={backgroundGrid} className='absolute w-full md:px-16 lg:px-0 mr-12 mt-16 lg:right-0 lg:w-[700px] opacity-30' /> */}


              <div className="mx-4 md:mx-16 mt-6 flex flex-row space-x-4 h-[450px] md:h-[750px] md:mb-48">





                <div className="w-full xl:w-2/3 h-full">
                  {/* <img src={background} className='w-full p-16 rounded-lg opacity-30'/> */}

                  <div className="relative h-full md:h-2/3 w-full z-30">
                    {/* <div className='absolute w-full h-full bg-black rounded-2xl opacity-60' >
                    </div> */}
                    <div className='absolute px-4 md:px-16 lg:mr-12 md:mt-16' >


                      <div class="row py-8">
                        <div class="col-xl-4 col-lg-5 col-sm-7 mx-lg-0 mx-auto">
                          <div class="border-radius-xl mb-3 position-relative">
                            <span class="mask bg-dark border-radius-xl z-index-1 opacity-5"></span>
                            <div class="d-flex align-items-center z-index-2 position-relative">
                              <div class="text-white icon-move-right text-sm ms-2 w-100 d-flex align-items-center">
                                <a href='/services/portfolio' className='hover:cursor-pointer'>
                                  <div className="flex flex-row">
                                    <span class="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">new</span>
                                    <span className="ml-2">Free Portfolio Creator</span>
                                  </div>
                                </a>
                                {/* <i class="fas fa-chevron-right text-xs ms-auto me-2" aria-hidden="true"></i> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h1 className="sm:mx-auto sm:w-10/12 font-black text-2xl text-center sm:text-5xl md:text-4xl lg:w-auto lg:text-left lg:text-5xl  text-white mt-40 sm:mt-0">Custom Websites, Designs,<br className="lg:block hidden" /> <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-cyan-300">Tech Solutions For You</span>.</h1>
                      <div className="relative mt-4 space-y-8 lg:w-11/12">
                        {/* <p className="sm:text-md text-gray-200 ">
                          Embark on a rewarding development journey and turn your ideas into something extraordinary. Share your creations with a vibrant community, forge valuable connections, and unlock earning potential. Start developing with passion, purpose, and the opportunity to thrive in a supportive environment.
                        </p> */}
                        {/* <span className="block font-semibold text-gray-400">The best companion platform for your articles.</span> */}


                        <div className="grid grid-cols-2 space-x-4 md:space-x-6 md:flex md:justify-center lg:justify-start mt-8">
                          <a aria-label="Become a dev" href="/console-home" className="p-4 border bg-gray-800 bg-opacity-50 border-gray-700 rounded-full duration-300 hover:border-cyan-400 hover:shadow-lg hover:shadow-cyan-600/20 hover:border-cyan-300/30">
                            <div className="flex justify-center md:space-x-4">
                              {/* <b className="text-sky-500">&lt;/&gt;</b> */}
                              {/* <img className="w-6 h-6 hidden md:block" src="https://cdn-icons-png.flaticon.com/512/458/458910.png" alt="slack logo" loading="lazy" width="128" height="128" /> */}
                              <span className="font-medium block text-white">Order Website</span>
                            </div>
                          </a>
                          <a aria-label="add to chat" href="/console-signup" className="p-4 border-2 bg-gray-800 bg-opacity-50 border-gray-700 rounded-full duration-300 hover:border-green-400 hover:shadow-lg hover:shadow-lime-600/20 hover:border-green-300/30">
                            <div className="flex justify-center md:space-x-2">
                              <p className="hidden md:block">⭕</p>
                              {/* <img className="w-6 h-6" src="https://cdn0.iconfinder.com/data/icons/leto-blue-project-management/64/_-19-256.png" alt="chat logo" loading="lazy" width="128" height="128" /> */}
                              <span className="font-medium block text-white">Buy Template</span>
                            </div>
                          </a>
                          {/* <a aria-label="add to zoom" href="#" className="p-4 border border-gray-200 dark:bg-gray-800  dark:border-gray-700 rounded-full duration-300 hover:border-blue-400 hover:shadow-lg hover:shadow-blue-600/20 dark:hover:border-blue-300/30">
                  <div className="flex justify-center space-x-4">
                    <img className="w-6 h-6" src="https://tailus.io/sources/blocks/tech-startup/preview/images/zoom.png" alt="chat logo" loading="lazy" width="128" height="128" />
                    <span className="hidden font-medium md:block dark:text-white">Zoom</span>
                  </div>
                </a> */}
                        </div>

                        {/* <div className="dark:text-gray-300">
                🔥🌟
                <span>Other integrations :</span>
                <a href="#" className="font-semibold text-gray-700 dark:text-gray-200">Discord,</a>
                <a href="#" className="font-semibold text-gray-700 dark:text-gray-200">Telegram</a>
              </div> */}

                        {/* <div className="pt-12 flex gap-6 lg:gap-12 justify-between grayscale lg:w-2/3">
                <img src="https://tailus.io/sources/blocks/tech-startup/preview/images/clients/airbnb.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" />
                <img src="https://tailus.io/sources/blocks/tech-startup/preview/images/clients/ge.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" />
                <img src="https://tailus.io/sources/blocks/tech-startup/preview/images/clients/coty.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" />
                <img src="https://tailus.io/sources/blocks/tech-startup/preview/images/clients/transferwise.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" />
              </div> */}
                      </div>

                      <div>

                      </div>
                    </div>

                    {/* 
                    {!imageLoaded ? (
                      // <div className="absolute inset-0 flex items-center justify-center bg-gray-200 animate-pulse">
<div></div>
                      // </div>
                    ) : (

                    <img
                      className={`w-full h-full rounded-2xl object-cover`}
                      src={mainPoster}
                      alt="Main Poster"
                      onLoad={handleImageLoad}
                    />

                      )} */}




                    <img class="bg-gray-200 w-full h-full rounded-2xl object-cover" src={mainPoster} loading="lazy" />




                  </div>



                  <div className="flex md:flex-row md:h-1/3">


                    {/* <img class="bg-gray-200 mt-2 w-1/2 pr-2 rounded-2xl object-cover" src={downPoster1} loading="lazy" /> */}

                    {/* <div class="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10"> */}
                    <div class=" flex max-h-full w-full sm:rounded-2xl bg-white">



                      <div className="flex flex-row">












                        <div class="flex-col justify-center items-center h-[100vh] hidden md:block font-poppins">




                          <div class="relative flex flex-col items-center w-[576px] mx-auto p-4 bg-white">


                            <div class="flex h-full w-full items-start justify-between rounded-md border-[1px] border-[transparent] bg-white px-3 py-[20px] transition-all duration-150 hover:border-gray-200">
                              <div class="flex items-center gap-3">
                                <div class="flex h-16 w-16 items-center justify-center">
                                  <img
                                    class="h-full w-full rounded-xl"
                                    src="https://horizon-tailwind-react-corporate-7s21b54hb-horizon-ui.vercel.app/static/media/Nft6.9ff5403226e81a6fd390.png"
                                    alt=""
                                  />
                                </div>
                                <div class="flex flex-col">
                                  <h5 class="text-sm font-semibold text-gray-800">
                                    Human Rights
                                  </h5>
                                  <p class="mt-1 text-xs font-normal text-gray-600">
                                    Dr. Hiba Imran
                                  </p>
                                </div>
                              </div>
                              <div class="mt-1 flex items-center justify-center text-navy-700 ">
                                <button onClick={() => window.location.href = "/infodexeus/articles/10/minorities-and-extremism-in-pakistan-a-critical-and-historical-review"} class="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-sm font-medium text-brand-500 transition duration-200 bg-blue-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                  Read
                                </button>
                              </div>
                            </div>
                            <div class="flex h-full w-full items-start justify-between rounded-md border-[1px] border-[transparent] bg-white px-3 py-[20px] transition-all duration-150 hover:border-gray-200 ">
                              <div class="flex items-center gap-3">
                                <div class="flex h-16 w-16 items-center justify-center">
                                  <img
                                    class="h-full w-full rounded-xl"
                                    src="https://horizon-tailwind-react-corporate-7s21b54hb-horizon-ui.vercel.app/static/media/Nft4.5fc37877b25c9fb9a52d.png"
                                    alt=""
                                  />
                                </div>
                                <div class="flex flex-col">
                                  <h5 class="text-base font-bold text-navy-700 ">
                                    ARTIFICIAL INTELLIGENCE
                                  </h5>
                                  <p class="mt-1 text-sm font-normal text-gray-600">
                                    Ali Rubass
                                  </p>
                                </div>
                              </div>
                              <div class="mt-1 flex items-center justify-center text-navy-700 ">
                                <button onClick={() => window.location.href = "/infodexeus/articles/3/the-power-of-ai-in-engineering-reshaping-the-future-of-innovation"} class="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-sm font-medium text-brand-500 transition duration-200 bg-blue-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                  Read
                                </button>
                              </div>
                            </div>
                            <div class="flex h-full w-full items-start justify-between rounded-md border-[1px] border-[transparent] bg-white px-3 py-[20px] transition-all duration-150 hover:border-gray-200 dark:!bg-navy-800 dark:hover:!bg-navy-700">
                              <div class="flex items-center gap-3">
                                <div class="flex h-16 w-16 items-center justify-center">
                                  <img
                                    class="h-full w-full rounded-xl"
                                    src="https://horizon-tailwind-react-corporate-7s21b54hb-horizon-ui.vercel.app/static/media/Nft3.3b3e6a4b3ada7618de6c.png"
                                    alt=""
                                  />
                                </div>
                                <div class="flex flex-col">
                                  <h5 class="text-base font-bold text-navy-700 dark:text-white">
                                    NUTRIENT FOODS
                                  </h5>
                                  <p class="mt-1 text-sm font-normal text-gray-600">
                                    Emily Watson
                                  </p>
                                </div>
                              </div>
                              <div class="mt-1 flex items-center justify-center text-navy-700 dark:text-white">
                                <button onClick={() => window.location.href = "/infodexeus/articles/8/optimizing-nutrition-for-peak-health-exploring-the-latest-trends-and-vital-foods"} class="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-sm font-medium text-brand-500 transition duration-200 bg-blue-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                  Read
                                </button>
                              </div>
                            </div>

                            {/* <div class="flex items-center justify-between rounded-t-3xl p-3 w-full">
                              <div class="text-lg font-bold text-navy-700">

                              </div>
                              <button onClick={() => window.location.href = "/infodexeus/articles"} class="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200">
                                See all
                              </button>
                            </div> */}

                          </div>
                        </div>



                        {/* <div class="w-1/2">
                          <div class="m-8 my-20 max-w-[400px] mx-auto">
                            <div class="mb-8">
                              <h1 class="mb-4 text-3xl font-extrabold text-gray-900 font-montserrat">Significant Articles</h1>
                              <p class="text-gray-600">Get the most out of GIGaDevDen by staying up to date with what's happening.</p>
                            </div>
                            <div class="space-y-4">
                              <button onClick={() => window.location.href="/infodexeus/articles"} class="p-3 bg-black rounded-full text-white w-full font-semibold">Read Articles</button>
                              <button onClick={() => window.location.href="console-home"} class="p-3 bg-white border rounded-full w-full font-semibold">Publish</button>
                            </div>
                          </div>
                        </div> */}



                        {/* <div class='w-1/2 flex items-center justify-center px-2'>
                          <div class='w-full max-w-md  mx-auto bg-white rounded-3xl shadow-xl overflow-hidden'>
                            <div class='max-w-md mx-auto'>
                              <div class='h-[236px]' style={{
                                backgroundImage: 'url(https://img.freepik.com/free-photo/pasta-spaghetti-with-shrimps-sauce_1220-5072.jpg?w=2000&t=st=1678041911~exp=1678042511~hmac=e4aa55e70f8c231d4d23832a611004f86eeb3b6ca067b3fa0c374ac78fe7aba6)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                              }}
                              >
                              </div>
                              <div class='p-4 sm:p-6'>
                                <p class='font-bold text-gray-700 text-[22px] leading-7 mb-1'>GameDen Android Studio Base App</p>
                                <div class='flex flex-row'>
                                  <p class='text-[#3C3C4399] text-[17px] mr-2 line-through'>$ 154</p>
                                  <p class='text-[17px] font-bold text-[#0FB478]'>$ 5</p>
                                </div>
                                <p class='text-[#7C7C80] font-[15px] mt-6'>Android stucio project for sale (Java): MySQL Database</p>


                                <a target='_blank' href='' class='block mt-10 w-full px-4 py-3 font-medium tracking-wide text-center capitalize transition-colors duration-300 transform bg-[#FFC933] rounded-[14px] hover:bg-[#FFC933DD] focus:outline-none focus:ring focus:ring-teal-300 focus:ring-opacity-80'>
                                  View Project
                                </a>
                                <a target='_blank' href="/" class='block mt-1.5 w-full px-4 py-3 font-medium tracking-wide text-center capitalize transition-colors duration-300 transform rounded-[14px] hover:bg-[#F2ECE7] hover:text-[#000000dd] focus:outline-none focus:ring focus:ring-teal-300 focus:ring-opacity-80'>
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div> */}




                      </div>

                    </div>
                    {/* </div> */}

                    {/* <div className="relative mt-2 w-1/2 pl-2 z-30">

                      <div className='absolute px-4' >
                      <div class="row bg-black bg-opacity-50 rounded-xl">
                        <div class="col-xl-4 col-lg-5 col-sm-7 mx-lg-0 mx-auto">
                          <div class="border-radius-xl p-1 mb-3 position-relative">
                            <span class="mask bg-dark border-radius-xl z-index-1 opacity-5"></span>
                            <div class="d-flex align-items-center z-index-2 position-relative">
                              <a href="#soft-ui-dashboard-tailwind-builder" class="text-white icon-move-right text-sm ms-2 w-100 d-flex align-items-center">
                                <div className="flex flex-row">
                                  <span class="ml-2 rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">new</span>
                                  <span className="ml-2 mr-2 font-urbanist font-bold">GAMEDEN</span>
                                </div>
                                <i class="fas fa-chevron-right text-xs ms-auto me-2" aria-hidden="true"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
             

                      <img class="bg-gray-200 rounded-2xl object-cover" src="https://get.wallhere.com/photo/1920x1080-px-board-games-digital-art-logo-TV-657115.jpg" loading="lazy" />

                    </div> */}


                  </div>

                </div>

                <div className="hidden xl:w-1/3 xl:flex xl:flex-col h-full">
                  {/* <img src={background} className='w-full p-16 rounded-lg opacity-30'/> */}

                  <div class="rounded-2xl">
                    <div class="group relative">
                      {/* <img class="w-full object-cover block rounded-2xl" src={eventPoster} alt="" /> */}





                      <div className="relative rounded-2xl overflow-hidden">
                        <div
                          className="flex transition-transform duration-1000 ease-in-out"
                          style={{
                            transform: `translateX(-${currentIndex * 100}%)`,
                          }}
                        >
                          {images.map((image, index) => (
                            <div
                              key={index}
                              className={`w-full h-auto transition-transform duration-1000 ${index === currentIndex ? 'scale-100' : 'scale-50'
                                }`}
                              style={{
                                flex: '0 0 100%',
                              }}
                            >
                              <img
                                src={image}
                                loading="lazy"
                                alt={`Carousel Slide ${index}`}
                                className="bg-gray-200 w-full h-auto object-cover shadow-lg rounded-2xl"

                              //style={{ borderRadius: '1rem' }}

                              />
                              <div class="p-5">
                                <div className="flex flex-row bg-gray-950 w-fit bg-opacity-25 px-1 py-1 rounded-full">
                                  <span class="hidden rounded-full bg-sky-500 px-1 py-1 h-6 w-6 text-xs text-white sm:flex justify-center items-center">{imageDate[index]}</span>
                                  <span className="mx-2">{imageText[index]}</span>
                                </div>
                                <p className="pt-2 text-sm">{imageDescription[index]}</p>
                                {/* <h3 class="text-gray-950 text-xl font-urbanist font-bold">International Dog day <span className="text-gray-400 font-normal text-sm">26 Aug, 2023</span> </h3>
                        <h3 class="text-white text-lg">Epoch <span className="text-gray-400">Tycho</span> </h3>
                        <h3 class="text-white text-lg">Epoch <span className="text-gray-400">Tycho</span> </h3> */}

                                {/* <p class="text-gray-400">Tycho</p> */}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>


                      {/* <div class="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 transition justify-evenly rounded-t-2xl">
                        <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                            <path d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                          </svg>
                        </button>

                        <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                          </svg>
                        </button>

                        <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                          </svg>
                        </button>
                      </div> */}
                    </div>





                  </div>


                  {/* <img class="h-1/3 rounded-2xl object-cover" src="https://wallpaperaccess.com/full/3790191.jpg" />
                  <img class="mt-2 h-1/3 rounded-2xl object-cover" src="https://wallpaperaccess.com/full/4422239.jpg" /> */}
                  {/* <img class="mt-2 h-1/3 rounded-2xl object-cover" src="https://wallpaperaccess.com/full/4422576.jpg" /> */}

                </div>

              </div>








              <style>{`
    @import url('//fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');
    @import url(//pro.fontawesome.com/releases/v5.10.0/css/all.css);
    @import url(//cdn.jsdelivr.net/npm/cryptocoins-icons@2.9.0/webfont/cryptocoins.css);
    body {
        font-family: Poppins, sans-serif;
    }
    .rounded-4xl {
        border-radius: 3.5rem;
    }
    .phone-top {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        height: 3rem;
        z-index: 1;
    }
    .phone-top-inner {
        position: relative;
        height: 2rem;
        width: 100%;
        background-color: #fff;
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }
    .phone-top-inner:before {
        content: "";
        position: absolute;
        top: 0;
        left: -1rem;
        height: 1rem;
        width: 1rem;
        border-top-right-radius: 50%;
        box-shadow: 0.5rem -0.5rem 0 0 #fff;
    }
    .phone-top-inner:after {
        content: "";
        position: absolute;
        top: 0;
        right: -1rem;
        height: 1rem;
        width: 1rem;
        border-top-left-radius: 50%;
        box-shadow: -0.5rem -0.5rem 0 0 #fff;
    }
    `}
              </style>

              {/* <div class="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center px-3 py-5">
                <div class="w-full bg-white text-gray-800 overflow-hidden border-4 border-white rounded-4xl shadow-lg relative" style={{ maxWidth: '414px' }}>
                  <div class="phone-top"><div class="phone-top-inner"></div></div>
                  <div class="w-full bg-gradient-to-br from-yellow-500 to-pink-600 pt-12 pb-12 px-4 text-white">
                    <div class="mb-5">
                      <input type="text" class="w-full text-white bg-white bg-opacity-20 rounded-full border-2 border-transparent focus:border-white focus:border-opacity-50 focus:outline-none px-3 py-1 leading-none text-sm transition-colors placeholder-white placeholder-opacity-50" placeholder="Search..." />
                    </div>
                    <h1 class="text-3xl font-light mb-3">Market Overview</h1>
                  </div>
                  <div class="bg-gray-50 px-2">
                    <ul class="relative -top-10">
                      <li class="mb-2 bg-white p-3 shadow-lg rounded cursor-pointer transition-colors border-b-2 border-transparent hover:border-pink-500">
                        <div class="flex items-center">
                          <div class="w-16 text-3xl leading-none">
                            <i class="cc BTC text-yellow-500"></i>
                          </div>
                          <div class="w-full">Bitcoin <span class="ml-3 text-gray-400">BTC</span></div>
                          <div class="text-green-500">+0.65%</div>
                        </div>
                      </li>
                      <li class="mb-2 bg-white p-3 shadow-lg rounded cursor-pointer transition-colors border-b-2 border-transparent hover:border-pink-500">
                        <div class="flex items-center">
                          <div class="w-16 text-3xl leading-none">
                            <i class="cc ETH text-gray-700"></i>
                          </div>
                          <div class="w-full">Ethereum <span class="ml-3 text-gray-400">ETH</span></div>
                          <div class="text-green-500">+0.98%</div>
                        </div>
                      </li>
                      <li class="mb-2 bg-white p-3 shadow-lg rounded cursor-pointer transition-colors border-b-2 border-transparent hover:border-pink-500">
                        <div class="flex items-center">
                          <div class="w-16 text-3xl leading-none">
                            <i class="cc XLM text-blue-300"></i>
                          </div>
                          <div class="w-full">Stellar <span class="ml-3 text-gray-400">XLM</span></div>
                          <div class="text-red-500">-1.24%</div>
                        </div>
                      </li>
                      <li class="mb-2 bg-white p-3 shadow-lg rounded cursor-pointer transition-colors border-b-2 border-transparent hover:border-pink-500">
                        <div class="flex items-center">
                          <div class="w-16 text-3xl leading-none">
                            <i class="cc XRP text-blue-700"></i>
                          </div>
                          <div class="w-full">Ripple <span class="ml-3 text-gray-400">XRP</span></div>
                          <div class="text-green-500">+0.30%</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="px-5 bg-white pb-2">
                    <div class="flex">
                      <div class="flex-1 group">
                        <a href="#" class="flex items-end justify-center text-center mx-auto px-4 w-full text-gray-400 group-hover:text-pink-500 border-b-2 border-transparent group-hover:border-pink-500">
                          <span class="block px-1">
                            <i class="far fa-home text-xl pt-1 mb-1 block"></i>
                            <span class="block text-xs pb-1">Home</span>
                          </span>
                        </a>
                      </div>
                      <div class="flex-1 group">
                        <a href="#" class="flex items-end justify-center text-center mx-auto px-4 w-full text-gray-400 group-hover:text-pink-500 border-b-2 border-transparent group-hover:border-pink-500">
                          <span class="block px-1">
                            <i class="far fa-compass text-xl pt-1 mb-1 block"></i>
                            <span class="block text-xs pb-1">Explore</span>
                          </span>
                        </a>
                      </div>
                      <div class="flex-1 group">
                        <a href="#" class="flex items-end justify-center text-center mx-auto px-4 w-full text-gray-400 group-hover:text-pink-500 border-b-2 border-transparent group-hover:border-pink-500">
                          <span class="block px-1">
                            <i class="far fa-search text-xl pt-1 mb-1 block"></i>
                            <span class="block text-xs pb-1">Search</span>
                          </span>
                        </a>
                      </div>
                      <div class="flex-1 group">
                        <a href="#" class="flex items-end justify-center text-center mx-auto px-4 w-full text-gray-400 group-hover:text-pink-500 border-b-2 border-transparent group-hover:border-pink-500">
                          <span class="block px-1">
                            <i class="far fa-cog text-xl pt-1 mb-1 block"></i>
                            <span class="block text-xs pb-1">Settings</span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}




              <div class="flex h-full items-start justify-between rounded-xl bg-gradient-to-r from-white via-cyan-500 to-pink-500 px-4 mx-4 mt-3 pt-[20px] pb-[15px] transition-all duration-150 md:hidden"
                style={{ backgroundImage: `url('${blueAndYellow}')`, backgroundSize: 'auto 400%', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>

                <div class="flex items-center gap-3">
                  <div class="flex h-12 w-12 items-center justify-center">
                    <img
                      class="h-full w-full rounded-xl"
                      src="https://play-lh.googleusercontent.com/GpGKPYeEajO00zEYFTw8rbDvq-0JbSXuu8fnMXouAGQuOATxavgXwXI_tn5zWrcsyQ=w240-h480-rw"
                      alt=""
                    />
                  </div>
                  <div class="flex flex-col">
                    <h5 class="text-sm font-bold text-navy-700 text-white">
                      GameDen
                    </h5>
                    <p class="mt-1 text-xs font-normal text-gray-200">
                      Available on Play Store
                    </p>
                  </div>
                </div>
                <div class="mt-1 flex items-center justify-center text-navy-700 dark:text-white">

                  <button onClick={() => window.location.href = "https://play.google.com/store/apps/details?id=com.giga.gameden"} class="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-sm font-medium text-brand-500 transition duration-200 bg-blue-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                    Get
                  </button>
                </div>
              </div>



              <div class="flex flex-col justify-center items-center md:hidden font-poppins px-4 pt-4">
                <div class="max-w-full relative flex flex-col items-center w-[576px] mx-auto p-2 bg-white border border-gray-200 rounded-lg" style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>

                  <div class=" w-full p-2 rounded-lg">
                    <p class="mt-2 text-base text-gray-600">
                      Top
                    </p>
                    <h4 class="text-xl font-bold text-navy-700">
                      Articles
                    </h4>

                  </div>
                  <div class="flex h-full w-full items-start justify-between rounded-md border-[1px] border-[transparent] bg-white px-3 py-[20px] transition-all duration-150 hover:border-gray-200">

                    <div class="flex items-center gap-3">
                      <div class="flex h-12 w-12 items-center justify-center">
                        <img
                          class="h-full w-full rounded-xl"
                          src="https://horizon-tailwind-react-corporate-7s21b54hb-horizon-ui.vercel.app/static/media/Nft6.9ff5403226e81a6fd390.png"
                          alt=""
                        />
                      </div>
                      <div class="flex flex-col">
                        <h5 class="text-sm font-bold text-navy-700 ">
                          HUMAN RIGHTS
                        </h5>
                        <p class="mt-1 text-xs font-normal text-gray-600">
                          Dr. Hiba Imran
                        </p>
                      </div>
                    </div>
                    <div class="mt-1 flex items-center justify-center text-navy-700 ">
                      <button onClick={() => window.location.href = "/infodexeus/articles/10/minorities-and-extremism-in-pakistan-a-critical-and-historical-review"} class="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-sm font-medium text-brand-500 transition duration-200 bg-blue-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                        Read
                      </button>
                    </div>
                  </div>
                  <div class="flex h-full w-full items-start justify-between rounded-md border-[1px] border-[transparent] bg-white px-3 py-[20px] transition-all duration-150 hover:border-gray-200 ">
                    <div class="flex items-center gap-3">
                      <div class="flex h-12 w-12 items-center justify-center">
                        <img
                          class="h-full w-full rounded-xl"
                          src="https://horizon-tailwind-react-corporate-7s21b54hb-horizon-ui.vercel.app/static/media/Nft4.5fc37877b25c9fb9a52d.png"
                          alt=""
                        />
                      </div>
                      <div class="flex flex-col">
                        <h5 class="text-sm font-bold text-navy-700 ">
                          ARTIFICIAL INTELLIGENCE
                        </h5>
                        <p class="mt-1 text-xs font-normal text-gray-600">
                          Ali Rubass
                        </p>
                      </div>
                    </div>
                    <div class="mt-1 flex items-center justify-center text-navy-700 ">
                      <button onClick={() => window.location.href = "/infodexeus/articles/3/the-power-of-ai-in-engineering-reshaping-the-future-of-innovation"} class="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-sm font-medium text-brand-500 transition duration-200 bg-blue-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                        Read
                      </button>
                    </div>
                  </div>
                  <div class="flex h-full w-full items-start justify-between rounded-md border-[1px] border-[transparent] bg-white px-3 py-[20px] transition-all duration-150 hover:border-gray-200 dark:!bg-navy-800 dark:hover:!bg-navy-700">
                    <div class="flex items-center gap-3">
                      <div class="flex h-12 w-12 items-center justify-center">
                        <img
                          class="h-full w-full rounded-xl"
                          src="https://horizon-tailwind-react-corporate-7s21b54hb-horizon-ui.vercel.app/static/media/Nft3.3b3e6a4b3ada7618de6c.png"
                          alt=""
                        />
                      </div>
                      <div class="flex flex-col">
                        <h5 class="text-sm font-bold text-navy-700 dark:text-white">
                          NUTRIENT FOODS
                        </h5>
                        <p class="mt-1 text-xs font-normal text-gray-600">
                          Emily Watson
                        </p>
                      </div>
                    </div>
                    <div class="mt-1 flex items-center justify-center text-navy-700 dark:text-white">

                      <button onClick={() => window.location.href = "/infodexeus/articles/8/optimizing-nutrition-for-peak-health-exploring-the-latest-trends-and-vital-foods"} class="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-sm font-medium text-brand-500 transition duration-200 bg-blue-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                        Read
                      </button>
                    </div>
                  </div>


                  {/* <div class="flex items-center justify-between rounded-t-3xl p-3 w-full">
                    <div class="text-lg font-bold text-navy-700 dark:text-white">

                    </div>
                    <button onClick={() => window.location.href = "/infodexeus/articles"} class="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                      Read
                    </button>
                  </div> */}

                </div>
              </div>






              <div class="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
                <img src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center" />
                <div class="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl" aria-hidden="true">
                  <div class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }}></div>
                </div>
                <div class="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu" aria-hidden="true">
                  <div class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }}></div>


                </div>
                <div class="mx-auto max-w-7xl px-6 lg:px-8">
                  <div class="mx-auto max-w-2xl lg:mx-0">
                    <h2 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">Work with us</h2>
                    <p class="mt-6 text-lg leading-8 text-gray-300">Just work with us no matter what there is no spam call 911 on us if we spam you.</p>
                  </div>
                  <div class="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                    <div class="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                      <a href="#">Open roles <span aria-hidden="true">&rarr;</span></a>
                      <a href="#">Internship program <span aria-hidden="true">&rarr;</span></a>
                      <a href="#">Our values <span aria-hidden="true">&rarr;</span></a>
                      <a href="#">Meet our leadership <span aria-hidden="true">&rarr;</span></a>
                    </div>
                    <dl class="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                      <div class="flex flex-col-reverse">
                        <dt class="text-base leading-7 text-gray-300">Offices worldwide</dt>
                        <dd class="text-2xl font-bold leading-9 tracking-tight text-white">12</dd>
                      </div>
                      <div class="flex flex-col-reverse">
                        <dt class="text-base leading-7 text-gray-300">Full-time colleagues</dt>
                        <dd class="text-2xl font-bold leading-9 tracking-tight text-white">300+</dd>
                      </div>
                      <div class="flex flex-col-reverse">
                        <dt class="text-base leading-7 text-gray-300">Hours per week</dt>
                        <dd class="text-2xl font-bold leading-9 tracking-tight text-white">40</dd>
                      </div>
                      <div class="flex flex-col-reverse">
                        <dt class="text-base leading-7 text-gray-300">Paid time off</dt>
                        <dd class="text-2xl font-bold leading-9 tracking-tight text-white">Unlimited</dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>





              <Ai />











            </div>





            <div className="bg-white">













              {/* 
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
            Brand new
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="ea469ae8-e6ec-4aca-8875-fc402da4d16e"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#ea469ae8-e6ec-4aca-8875-fc402da4d16e)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative">The</span>
          </span>{' '}
          quick, brown fox jumps over a lazy dog
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque rem aperiam, eaque ipsa quae.
        </p>
      </div>
      <div className="grid gap-8 row-gap-10 lg:grid-cols-2">
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
            <svg
              className="w-12 h-12 text-deep-purple-accent-400 sm:w-16 sm:h-16"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5">The deep ocean</h6>
          <p className="mb-3 text-sm text-gray-900">
            A flower in my garden, a mystery in my panties. Heart attack never
            stopped old Big Bear. I didn't even know we were calling him Big
            Bear. We never had the chance to.
          </p>
          <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Learn more
          </a>
        </div>
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
            <svg
              className="w-12 h-12 text-deep-purple-accent-400 sm:w-16 sm:h-16"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5">When has justice</h6>
          <p className="mb-3 text-sm text-gray-900">
            Rough pomfret lemon shark plownose chimaera southern sandfish
            kokanee northern sea robin Antarctic cod. Yellow-and-black triplefin
            gulper South American Lungfish mahi-mahi, butterflyfish glass
            catfish soapfish ling gray mullet!
          </p>
          <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Learn more
          </a>
        </div>
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
            <svg
              className="w-12 h-12 text-deep-purple-accent-400 sm:w-16 sm:h-16"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5">Organically grow</h6>
          <p className="mb-3 text-sm text-gray-900">
            A slice of heaven. O for awesome, this chocka full cuzzie is as
            rip-off as a cracker. Meanwhile, in behind the bicycle shed,
            Hercules Morse, as big as a horse and Mrs Falani were up to no good
            with a bunch of crook pikelets.
          </p>
          <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Learn more
          </a>
        </div>
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
            <svg
              className="w-12 h-12 text-deep-purple-accent-400 sm:w-16 sm:h-16"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5">
            A slice of heaven
          </h6>
          <p className="mb-3 text-sm text-gray-900">
            Disrupt inspire and think tank, social entrepreneur but preliminary
            thinking think tank compelling. Inspiring, invest synergy capacity
            building, white paper; silo, unprecedented challenge B-corp
            problem-solvers.
          </p>
          <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Learn more
          </a>
        </div>
      </div>
    </div>
             */}


              <div className="relative isolate overflow-hidden bg-white px-6 py-16 sm:py-20 lg:overflow-visible lg:px-0">
                <div className="absolute inset-0 -z-10 overflow-hidden">
                  <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                        width={200}
                        height={200}
                        x="50%"
                        y={-1}
                        patternUnits="userSpaceOnUse"
                      >
                        <path d="M100 200V.5M.5 .5H200" fill="none" />
                      </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                      <path
                        d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                        strokeWidth={0}
                      />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                  </svg>
                </div>
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                  <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                      <div className="lg:max-w-lg">
                        <p className="text-base font-semibold leading-7 text-gray-600">Comunity Hub</p>
                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better workflow</h1>
                        <p className="mt-6 text-xl leading-8 text-gray-700">
                          Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam
                          eget aliquam. Quisque id at vitae feugiat egestas.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                    <img
                      className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                      src={contentPicture}
                      alt=""
                    />

                  </div>
                  <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                      <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                        <p>
                          Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet
                          vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque
                          erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris
                          semper sed amet vitae sed turpis id.
                        </p>
                        <ul role="list" className="mt-8 space-y-8 text-gray-600">
                          <li className="flex gap-x-3">
                            <CloudUploadIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                              <strong className="font-semibold text-gray-900">Push to deploy.</strong> Lorem ipsum, dolor sit amet
                              consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate
                              blanditiis ratione.
                            </span>
                          </li>
                          <li className="flex gap-x-3">
                            <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                              <strong className="font-semibold text-gray-900">SSL certificates.</strong> Anim aute id magna aliqua
                              ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.
                            </span>
                          </li>
                          <li className="flex gap-x-3">
                            <ServerIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                              <strong className="font-semibold text-gray-900">Database backups.</strong> Ac tincidunt sapien
                              vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.
                            </span>
                          </li>
                        </ul>
                        <p className="mt-8">
                          Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor
                          fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac
                          adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.
                        </p>
                        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">No server? No problem.</h2>
                        <p className="mt-6">
                          Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis arcu ipsum urna nibh.
                          Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed
                          tellus mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed nullam sed diam
                          turpis ipsum eu a sed convallis diam.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>






              {/* <HomeTeam /> */}






              {/* <div className="bg-white py-24 sm:py-12">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                {stats.map((stat) => (
                  <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
                    <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div> */}




              {/* 
<>
          <div>

            <div


              className="flex flex-col items-center justify-center mt-10 mb-3 py-10 ml-16 mr-16 rounded rounded-lg"
              style={{
                //backgroundImage: "url('https://img.freepik.com/free-vector/flat-design-metaverse-technology-twitch-banner_23-2149424571.jpg?w=1060&t=st=1685981138~exp=1685981738~hmac=d008c045063a195f6c068edef37910139b33d1a10a4bb14d23a9401bc139f8f8')",
                backgroundImage: "url('https://th.bing.com/th/id/R.060ed86ed6118578e68d3b3094dafb8d?rik=K91UIeLNOSI%2btQ&pid=ImgRaw&r=0')",
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                //backgroundRepeat: 'no-repeat',

              }}
            >

              <div className="flex">
                <div className="flex flex-col items-center mx-2">
                  <div className="bg-gray-200 rounded-lg p-4">
                    <span className="text-4xl font-bold">{countdown.hours.toString().padStart(2, '0')}</span>
                  </div>
                </div>
                <div className="flex flex-col items-center mx-2">
                  <div className="p-4">
                    <span className="text-3xl font-bold">:</span>
                  </div>
                </div>
                <div className="flex flex-col items-center mx-2">
                  <div className="bg-gray-200 rounded-lg p-4">
                    <span className="text-4xl font-bold">{countdown.minutes.toString().padStart(2, '0')}</span>
                  </div>
                </div>
                <div className="flex flex-col items-center mx-2">
                  <div className="p-4">
                    <span className="text-3xl font-bold">:</span>
                  </div>
                </div>
                <div className="flex flex-col items-center mx-2">
                  <div className="bg-gray-200 rounded-lg p-4">
                    <span className="text-4xl font-bold">{countdown.seconds.toString().padStart(2, '0')}</span>
                  </div>
                </div>
              </div>
            </div>
            

          </div>

          </> */}

              {/* <div className=" flex justify-center items-center">
            <h1 className="text-red-600 text-3xl md:text-5xl font-bold leading-none rounded-full">☉ Infodexeus</h1>
          </div> */}







              <HomePageContentUnits />










              <div className="flex flex-grow flex-col md:flex-row md:p-[40px] lg:mb-32">
                <div className="md:w-1/2 lg:w-1/3 font-poppins">

                  <div className="bg-white p-[20px] overflow-hidden">
                    <div className="relative">
                      <img src={gameden} alt="Product 1" className="bg-slate-900 w-full h-auto mb-[20px] rounded" />
                      <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">New Arrival</div>
                    </div>


                    <div className="px-4 py-5 sm:p-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">GAME DEN - Hare meets Tortoise</h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">Step into the world of Den and experience a unique blend of arcade gaming and a marketplace where you can trade items with players from all over the world!</p>
                    </div>
                    <div className="bg-gray-50 px-4 py-4 sm:px-6">
                      <div className="text-sm font-medium text-indigo-600 hover:text-indigo-500">Learn more →</div>
                    </div>
                  </div>

                </div>








                <div className="md:w-1/2 lg:w-1/3">

                  <div className="relative">
                    <div className="context">
                      {/* <p className="text-center text-white justify-center items-center">Pure CSS Animated Background</p> */}

                      <div className="pt-16 pb-16 md:pt-32 md:pb-[255px] bg-gradient-to-br from-purple-600 to-blue-600 flex flex-col justify-center items-center">
                        {/* <h1 className="text-5xl md:text-7xl text-white font-bold mb-8 tracking-widest">
        Tic-Tac-Toe
      </h1> */}
                        <motion.div
                          initial={{ scale: 0.8, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          transition={{ duration: 0.4 }}
                          className="bg-white rounded-lg shadow-md p-4"
                        >
                          <div className="grid grid-cols-3 gap-4">
                            {board.map((cell, index) => (
                              <button
                                key={index}
                                className={`w-16 h-16 border-2 rounded-lg ${winner && winner === cell
                                  ? "bg-green-300"
                                  : "hover:bg-gray-200"
                                  }`}
                                onClick={() => handleClick(index)}
                              >
                                <span className="text-4xl font-bold">{cell}</span>
                              </button>
                            ))}
                          </div>
                        </motion.div>
                        {winner && (
                          <div className="mt-4 text-2xl font-bold text-green-500">
                            {winner} wins!
                          </div>
                        )}
                        {!winner && !board.includes(null) && (
                          <div className="mt-4 text-2xl font-bold text-red-500">
                            It's a tie!
                          </div>
                        )}
                        {winner && (
                          <motion.button
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.4 }}
                            className="mt-8 py-2 px-4 bg-green-500 text-white rounded-lg hover:bg-green-600"
                            onClick={resetGame}
                          >
                            Reset Game
                          </motion.button>
                        )}
                        {!winner && !board.includes(null) && (
                          <motion.button
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.4 }}
                            className="mt-8 py-2 px-4 bg-green-500 text-white rounded-lg hover:bg-green-600"
                            onClick={resetGame}
                          >
                            Reset Game
                          </motion.button>
                        )}
                      </div>

                    </div>
                    {/* <div className="area bg-gradient-to-r from-indigo-400 to-purple-900">
                  <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div> */}
                  </div>


                </div>

                <section className="flex flex-col md:flex-col items-center justify-center md:justify-between bg-gray-100 px-4 md:px-16 md:hidden lg:w-1/3 lg:block">
                  {/* <div className="md:w-1/2">
                <img src="https://via.placeholder.com/600x600" alt="App screenshot" className="mx-auto md:mx-0" />
              </div> */}
                  <div className="text-center md:text-left mt-10 font-poppins">
                    <h2 className="text-2xl font-bold mb-4">Get the App Now!</h2>
                    <p className="text-gray-600 mb-4">Have access to multiple exciting arcade games, where you can collect valuable items to make your gaming experience even more interesting. Our marketplace allows you to trade items from different games with other players, giving you the chance to grow your collection and add to the fun.</p>
                    <a href="https://play.google.com/store/apps/details?id=com.giga.gameden&pli=1" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300">Play Store</a>
                  </div>
                </section>

                {/* <svg
      className="w-32 h-32"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.525 19.2C43.525 18.43 43.22 17.706 42.675 17.16C42.13 16.615 41.405 16.31 40.635 16.31C39.865 16.31 39.14 16.615 38.595 17.16L24 31.755L9.405 17.16C8.86 16.615 8.135 16.31 7.365 16.31C6.595 16.31 5.87 16.615 5.325 17.16C4.78 17.706 4.475 18.43 4.475 19.2V29.6C4.475 30.37 4.78 31.094 5.325 31.64C5.87 32.185 6.595 32.49 7.365 32.49H40.635C41.405 32.49 42.13 32.185 42.675 31.64C43.22 31.094 43.525 30.37 43.525 29.6V19.2Z"
        fill="#A0AEC0"
      />
      <path
        d="M31.335 20.16C31.335 19.39 31.03 18.666 30.485 18.12C29.94 17.575 29.215 17.27 28.445 17.27C27.675 17.27 26.95 17.575 26.405 18.12L24 20.525L21.595 18.12C21.05 17.575 20.325 17.27 19.555 17.27C18.785 17.27 18.06 17.575 17.515 18.12C16.97 18.666 16.665 19.39 16.665 20.16V25.44C16.665 26.21 16.97 26.934 17.515 27.48C18.06 28.025 18.785 28.33 19.555 28.33H28.445C29.215 28.33 29.94 28.025 30.485 27.48C31.03 26.934 31.335 26.21 31.335 25.44V20.16Z"
        fill="#718096"
      />
      <path
        d="M40.635 22.04H7.365C6.595 22.04 5.87 22.345 5.325 22.89C4.78 23.435 4.475 24.16 4.475 24.93V29.6C4.475 30.37 4.78 31.094 5.32531.64C5.87 32.185 6.595 32.49 7.365 32.49H40.635C41.405 32.49 42.13 32.185 42.675 31.64C43.22 31.094 43.525 30.37 43.525 29.6V24.93C43.525 24.16 43.22 23.435 42.675 22.89C42.13 22.345 41.405 22.04 40.635 22.04Z"
        fill="#2D3748"
        />
        <path
             d="M30.485 27.48C29.94 27.925 29.215 28.33 28.445 28.33H19.555C18.785 28.33 18.06 27.925 17.515 27.48C16.97 26.934 16.665 26.21 16.665 25.44V22.04C16.665 21.27 16.97 20.546 17.515 20C18.06 19.455 18.785 19.15 19.555 19.15C20.325 19.15 21.05 19.455 21.595 20C22.14 20.546 22.445 21.27 22.445 22.04V24.45L24 22.895L25.555 24.45V22.04C25.555 21.27 25.86 20.546 26.405 20C26.95 19.455 27.675 19.15 28.445 19.15C29.215 19.15 29.94 19.455 30.485 20C31.03 20.546 31.335 21.27 31.335 22.04V25.44C31.335 26.21 31.03 26.934 30.485 27.48Z"
             fill="#2D3748"
           />
        <path
             d="M17.875 21.295C17.875 20.835 17.465 20.475 16.975 20.475C16.485 20.475 16.075 20.835 16.075 21.295V22.925C16.075 23.385 16.485 23.745 16.975 23.745C17.465 23.745 17.875 23.385 17.875 22.925V21.295Z"
             fill="#FFF"
           />
        <path
             d="M31.025 21.295C31.025 20.835 30.615 20.475 30.125 20.475C29.635 20.475 29.225 20.835 29.225 21.295V22.925C29.225 23.385 29.635 23.745 30.125 23.745C30.615 23.745 31.025 23.385 31.025 22.925V21.295Z"
             fill="#FFF"
           />
        <circle cx="23.25" cy="26.125" r="3.625" fill="#F56565"/>
<path
     d="M23.25 32.49C21.64 32.49 20.335 31.185 20.335 29.575C20.335 27.965 21.64 26.66 23.25 26.66C24.86 26.66 26.165 27.965 26.165 29.575C26.165 31.185 24.86 32.49 23.25 32.49ZM23.25 28.94C22.535 28.94 21.955 29.52 21.955 30.235C21.955 30.95 22.535 31.53 23.25 31.53C23.965 31.53 24.545 30.95 24.545 30.235C24.545 29.52 23.965 28.94 23.25 28.94Z"
     fill="#2D3748"
   />
<path
     d="M33.53 12.17L33.29 11.975C29.575 8.535 24.975 6.665 20.335 6.665C15.695 6.665 11.095 8.535 7.38 11.975L7.14 12.17C6.99 12.315 6.99 12.525 7.14 12.67C7.38 12.865 7.74 12.865 7.98 12.67L8.345 12.315C12.395 8.85 17.585 6.66 23.25 6.66C28.915 6.66 34.105 8.85 38.155 12.315L38.52 12.67C38.67 12.865 39.03 12.865 39.27 12.67C39.57 12.525 39.57 12.315 39.42 12.17L33.53 12.17Z"
     fill="#2D3748"
   />
</svg> */}

              </div>
              <section className="flex flex-col md:flex-col items-center justify-center md:justify-between bg-gray-100 px-10 md:px-16 pb-10 mb-5 lg:hidden mt-32">
                {/* <div className="md:w-1/2">
                <img src="https://via.placeholder.com/600x600" alt="App screenshot" className="mx-auto md:mx-0" />
              </div> */}
                <div className="text-center md:text-left mt-10">
                  <h2 className="text-2xl font-bold mb-4">Get the App Now!</h2>
                  <p className="text-gray-600 mb-4">Have access to multiple exciting arcade games, where you can collect valuable items to make your gaming experience even more interesting. Our marketplace allows you to trade items from different games with other players, giving you the chance to grow your collection and add to the fun.</p>
                  <a href="https://play.google.com/store/apps/details?id=com.giga.gameden&pli=1" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300">Download on the Play Store</a>
                </div>
              </section>





            </div>
          </div>

        </div>

        {/* <div className="w-full h-[500px]">
      <iframe src={postUrl} className="w-full h-full"></iframe>
    </div> */}




        <div class="grid place-items-center p-5 md:rounded bg-white">
          <div>

            <div class="section-title">
              <h2 className="font-montserrat text-3xl">Team <span className="text-yellow-400 text-4xl"> ──────</span></h2>
              <p className="font-montserrat text-3xl font-bold">CHECK OUR TEAM</p>
            </div>

            <br></br>
            <br></br>

            {/* <h1 class="text-4xl sm:text-5xl md:text-7xl font-bold text-gray-200 mb-5">Made for you</h1> */}
            <section class="grid grid-cols-1 sm:grid-cols-3 gap-4">





              <div class="bg-gray-900 shadow-lg rounded p-3">
                <div class="group relative">
                  <img class="w-full md:w-72 block rounded" src={TestimonialImage01} loading="lazy" alt="" />
                  <div class="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 transition justify-evenly">
                    <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                        <path d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                      </svg>
                    </button>

                    <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                      </svg>
                    </button>

                    <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="p-5">
                  <h3 class="text-white text-lg">Ali Rubass</h3>
                  <p class="text-gray-400">Founder & CEO</p>
                </div>
              </div>



              <div class="bg-gray-900 shadow-lg rounded p-3">
                <div class="group relative">
                  <img class="w-full md:w-72 block rounded" src={TestimonialImage03} loading="lazy" alt="" />
                  <div class="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 transition justify-evenly">
                    <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                        <path d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                      </svg>
                    </button>

                    <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                      </svg>
                    </button>

                    <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="p-5">
                  <h3 class="text-white text-lg">Inara Sheikh</h3>
                  <p class="text-gray-400">HR Manager</p>
                </div>
              </div>



              <div class="bg-gray-900 shadow-lg rounded p-3">
                <div class="group relative">
                  <img class="w-full md:w-72 block rounded" src={TestimonialImage02} loading="lazy" alt="" />
                  <div class="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 transition justify-evenly">
                    <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                        <path d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                      </svg>
                    </button>

                    <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                      </svg>
                    </button>

                    <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="p-5">
                  <h3 class="text-white text-lg">Rehan Tariq</h3>
                  <p class="text-gray-400">Chief Design Officer</p>
                </div>
              </div>

            </section>
          </div>
        </div>



        <div class="bg-white py-24 sm:py-32">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 class="text-center text-lg font-semibold leading-8 text-gray-900">Trusted by the world’s most innovative teams</h2>
            <div class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
              <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg" alt="Transistor" width="158" height="48" />
              <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg" alt="Reform" width="158" height="48" />
              <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg" alt="Tuple" width="158" height="48" />
              <img class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg" alt="SavvyCal" width="158" height="48" />
              <img class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg" alt="Statamic" width="158" height="48" />
            </div>
          </div>
        </div>





        <Footer />
        <Banner />
      </div>

    </>
  );
}

export default Home;