import React, { useState, useEffect } from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import { FaMedal, FaRegSun, FaTimes, FaUserAlt } from 'react-icons/fa';
import { FaSistrix } from 'react-icons/fa';
import { FaWpforms } from 'react-icons/fa';
import { FaFilePowerpoint } from 'react-icons/fa';
import { FaRegChartBar } from 'react-icons/fa';
import { FaHdd } from 'react-icons/fa';
import { FaMicrochip } from 'react-icons/fa';
import Header from '../partials/Header';
import DevHome from './DevHome';
import DevArticles from './DevArticles'
import DevProjects from './DevProjects'
import Settings from './DeveloperProfile'
import Medals from './Medals'
import DevResearch from './DevResearch';
import '../custom_css/all_styles.css'
import DevAnalytics from './DevAnalytics';

const App = () => {

    const websitePrefix = 'www.gigadevden.com';

    const name = localStorage.getItem('${websitePrefix}-name');
    //const developerName = name ? name.split(" ")[0] : 'Developer';
    const developerName = name ? name.split(" ")[0]+" "+ (name.split(" ")[1]? name.split(" ")[1] : "") : 'Developer';
    const image = localStorage.getItem('${websitePrefix}-image');

    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const getOptionIcon = (optionValue) => {
        switch (optionValue) {
            case 'home':
                return <FaUserAlt className="h-4 w-4 mr-4 text-gray-500" />;
            case 'projects':
                return <FaFilePowerpoint className="h-4 w-4 mr-4 text-gray-500" />;
            case 'articles':
                return <FaWpforms className="h-4 w-4 mr-4 text-gray-500" />;
            case 'research':
                return <FaMicrochip className="h-4 w-4 mr-4 text-gray-500" />;
            case 'analytics':
                return <FaRegChartBar className="h-4 w-4 mr-4 text-gray-500" />;
            case 'badges':
                return <FaMedal className="h-4 w-4 mr-4 text-gray-500" />;
            case 'controlPanel':
                return <FaHdd className="h-4 w-4 mr-4 text-gray-500" />;

            // Add more cases for other options
            default:
                return null;
        }
    };

    const handleOptionClick = (optionValue) => {
        switch (optionValue) {
            case 'home':
                handleComponentChange('Home');
                return;
            case 'projects':
                handleComponentChange('Projects');
                return;
            case 'articles':
                handleComponentChange('Articles');
                return;
            case 'research':
                handleComponentChange('Research');
                return;
            case 'analytics':
                handleComponentChange('Analytics');
                return;
            case 'badges':
                handleComponentChange('Medals');
                return;
            case 'controlPanel':
                handleComponentChange('controlPanel');
                return;

            // Add more cases for other options
            default:
                return null;
        }
    };


    const showControlPanel = localStorage.getItem('${websitePrefix}-controlpanel') === '1';

    const options = [
        { show: 'Home', label: 'home', value: 'home' },
        { show: 'Projects', label: 'projects', value: 'projects' },
        { show: 'Articles', label: 'articles', value: 'articles' },
        { show: 'Research', label: 'research', value: 'research' },
        { show: 'Analytics', label: 'Analytics', value: 'analytics' },
        { show: 'Badges', label: 'badges', value: 'badges' },
        ...(showControlPanel ? [{ show: 'Control Panel', label: 'control panel', value: 'controlPanel' }] : []),
        // Add more options as needed
    ];


    const [matchingOptions, setMatchingOptions] = useState([]);


    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);

        const matchingOptions = options.filter((option) =>
            option.label.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setMatchingOptions(matchingOptions);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        // handle search submit here
        window.location.href = "/error";
    };

    const [showOverlay, setShowOverlay] = useState(false);

    useEffect(() => {

        const sessionStorageValue = localStorage.getItem('${websitePrefix}-developerlogin');
        if (sessionStorageValue !== '1') {
            setShowOverlay(true);
        }
        else {

        }
    }, []);

    const [activeComponent, setActiveComponent] = useState("Home");

    const [activeButton, setActiveButton] = useState('Home');

    const handleComponentChange = (componentName) => {
        if (isOpen === true) {
            toggleSidebar();
        }
        setSearchTerm('');
        setActiveComponent(componentName);
        setActiveButton(componentName);
    };

    const medalButton = () => {
        // Do something when the button in DevHome is clicked
        handleComponentChange('Medals')
        // You can perform any other actions or state updates here
    };

    const projectButton = () => {
        // Do something when the button in DevHome is clicked
        handleComponentChange('Projects')
        // You can perform any other actions or state updates here
    };

    const articleButton = () => {
        // Do something when the button in DevHome is clicked
        handleComponentChange('Articles')
        // You can perform any other actions or state updates here
    };

    const researchButton = () => {
        // Do something when the button in DevHome is clicked
        handleComponentChange('Research')
        // You can perform any other actions or state updates here
    };


    const renderActiveComponent = () => {
        switch (activeComponent) {
            case "Home":
                return <DevHome medalButton={medalButton} projectButton={projectButton} articleButton={articleButton} researchButton={researchButton} />;
            case "Projects":
                return <DevProjects />;
            case "Articles":
                return <DevArticles />;
            case "Research":
                return <DevResearch />;
            case "Analytics":
                return <DevAnalytics />;
            case "Settings":
                return <Settings />;
            case "Medals":
                return <Medals />;
            default:
                return null;
        }
    };



    return (
        <div>


            <div className="flex h-screen bg-gray-100">
                
                <div id="messages" className={`max-h-screen bg-slate-950 z-50 md:z-0 w-80 space-y-6 pt-7 px-2 absolute inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 transition duration-200 ease-in-out overflow-y-auto`}>


                    
                    {/* <h2 className="text-2xl font-semibold text-gray-800 tracking-wider">Brand</h2> */}


                    {/* <button
                        className="md:hidden flex items-center text-gray-500 text-sm py-2.5 mx-5 px-3 rounded transition duration-200 cursor-pointer hover:bg-white hover:text-black"
                        onClick={() => setIsOpen(false)}
                    >
                        <FaTimes className="h-4 w-4 mr-4 text-gray-500" />
                        Close
                    </button> */}




                    {/* <img src="https://www.gigadevden.com/dotden.png" alt="Logo" onClick={() => window.location.href = '/'} className="w-auto h-[80px] hover:cursor-pointer" /> */}

                    <nav className="flex mt-7 justify-center">
                        {/* other navbar content */}
                        <form onSubmit={handleSearchSubmit} className="flex items-center">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    className="pl-10 pr-10 py-1 rounded bg-gray-500 opacity-30 shadow placeholder-white"

                                />
                                <FaSistrix className="h-5 w-5 text-gray-500 absolute left-2 top-1/2 transform -translate-y-1/2" />

                                {searchTerm !== '' && matchingOptions.length > 0 && (
                                    <div className="mt-2 absolute left-0 right-0 bg-white rounded-bl rounded-br">
                                        {matchingOptions.map((option) => (
                                            <a
                                                key={option.value}
                                                href="#"
                                                className="flex items-center text-gray-500 mb-3 mt-3 text-sm py-2.5 mx-5 px-3 rounded transition duration-200 hover:bg-white hover:text-black"
                                                onClick={() => handleOptionClick(option.value)}
                                            >
                                                {getOptionIcon(option.value)}
                                                {option.show}
                                            </a>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </form>
                    </nav>






                    <nav className="border-b border-gray-300">

                        <>
                            <a
                                className={`flex items-center text-gray-500 mb-3 text-sm py-2.5 mx-5 px-3 rounded transition duration-200 cursor-pointer ${activeButton === 'Home' ? 'bg-blue-500 text-white hover:bg-white hover:text-slate-950' : 'hover:bg-white hover:text-black'
                                    }`}
                                onClick={() => handleComponentChange('Home')}
                            >
                                <FaUserAlt className={`h-4 w-4 mr-4 ${activeButton === 'Home' ? 'text-slate-950' : 'text-gray-500'}`} />
                                Home
                            </a>

                            <a
                                className={`flex items-center text-gray-500 mb-3 text-sm py-2.5 mx-5 px-3 rounded transition duration-200 cursor-pointer ${activeButton === 'Projects' ? 'bg-blue-500 text-white hover:bg-white hover:text-slate-950' : 'hover:bg-white hover:text-black'
                                    }`}
                                onClick={() => handleComponentChange('Projects')}
                            >
                                <FaFilePowerpoint className={`h-4 w-4 mr-4 ${activeButton === 'Projects' ? 'text-slate-950' : 'text-gray-500'}`} />
                                Projects
                            </a>

                            <a
                                className={`flex items-center text-gray-500 mb-3 text-sm py-2.5 mx-5 px-3 rounded transition duration-200 cursor-pointer ${activeButton === 'Articles' ? 'bg-blue-500 text-white hover:bg-white hover:text-slate-950' : 'hover:bg-white hover:text-black'
                                    }`}
                                onClick={() => handleComponentChange('Articles')}
                            >
                                <FaWpforms className={`h-4 w-4 mr-4 ${activeButton === 'Articles' ? 'text-slate-950' : 'text-gray-500'}`} />
                                Articles
                            </a>

                            <a
                                className={`flex items-center text-gray-500 mb-3 text-sm py-2.5 mx-5 px-3 rounded transition duration-200 cursor-pointer ${activeButton === 'Research' ? 'bg-blue-500 text-white hover:bg-white hover:text-slate-950' : 'hover:bg-white hover:text-black'
                                    }`}
                                onClick={() => handleComponentChange('Research')}
                            >
                                <FaMicrochip className={`h-4 w-4 mr-4 ${activeButton === 'Research' ? 'text-slate-950' : 'text-gray-500'}`} />
                                Research
                            </a>
                        </>

                    </nav>

                    <nav className="h-64 overflow-y-auto" style={{ scrollbarWidth: 'thin' }}>
                        <style>
                            {`
      /* Hide the default scrollbar */
      nav::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      nav::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      nav::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 2.5px;
      }

      /* Handle on hover */
      nav::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `}
                        </style>

                        {/* Navigation links */}
                     
                        <a
                                className={`flex items-center text-gray-500 mb-3 text-sm py-2.5 mx-5 px-3 rounded transition duration-200 cursor-pointer ${activeButton === 'Analytics' ? 'bg-blue-500 text-white hover:bg-white hover:text-slate-950' : 'hover:bg-white hover:text-black'
                                    }`}
                                onClick={() => handleComponentChange('Analytics')}
                            >
                                <FaRegChartBar className={`h-4 w-4 mr-4 ${activeButton === 'Analytics' ? 'text-slate-950' : 'text-gray-500'}`} />
                                Analytics
                            </a>

                        <a
                            className={`flex items-center text-gray-500 mb-3 text-sm py-2.5 mx-5 px-3 rounded transition duration-200 cursor-pointer ${activeButton === 'Medals' ? 'bg-blue-500 text-white hover:bg-white hover:text-slate-950' : 'hover:bg-white hover:text-black'
                                }`}
                            onClick={() => handleComponentChange('Medals')}
                        >
                            <FaMedal className={`h-4 w-4 mr-4 ${activeButton === 'Medals' ? 'text-slate-950' : 'text-gray-500'}`} />
                            Badges
                        </a>
                        {localStorage.getItem('${websitePrefix}-controlpanel') === '1' && (
                            <a href="#" className="flex items-center text-gray-500 mb-3 text-sm py-2.5 mx-5 px-3 rounded transition duration-200 hover:bg-white hover:text-black">
                                <FaHdd className="h-4 w-4 mr-4 text-gray-500" />
                                Control Panel
                            </a>
                        )}

                    </nav>


                    <div className="sidebar sticky absolute bottom-0 left-6 bg-slate-950"
                    //style={{ position: 'relative', flex: '1 1 100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                    >
                        {/* ... other sidebar content ... */}
                        <div className="pl-6 pr-6 pt-6 pb-4 rounded-lg shadow">
                            <div className="flex items-center justify-between">
                                <div className="flex flex-row items-center">
                                {image ? (
                                    <img src={`https://www.gigadevden.com${image}`} alt="User Avatar" className="h-12 w-12 rounded-full" />
                                ) : (
                                    <div className="w-12 h-12 bg-gray-300 animate-pulse rounded-full" />
                                )}
                                <div className="ml-4">
                                    <div className="text-gray-600">{developerName}</div>
                                </div>
                                </div>
                                <a
                                    className={`flex items-center text-gray-500 mb-3 text-sm py-2.5 rounded transition duration-200 cursor-pointer`}
                                    onClick={() => handleComponentChange('Settings')}
                                >
                                    <FaRegSun className={`mt-3 h-4 w-4`} />
                                </a>
                            </div>
                        </div>
                    </div>



                </div>
                <div className="flex-1 flex flex-col overflow-hidden">
                    {/* <header className="bg-slate-950 shadow-md md:hidden">
                        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"> */}

                    <button
                        onClick={toggleSidebar}
                        className="text-gray-500 focus:outline-none md:hidden fixed left-4 top-4 z-50 bg-gray-100 rounded"
                    >
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </button>


                    {/* </div>
                    </header> */}


                    {renderActiveComponent()}


                </div>
                <div
                    className={`z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity ${showOverlay ? 'opacity-100' : 'opacity-0'
                        } ${showOverlay ? '' : 'pointer-events-none'}`}
                >
                    <div className="bg-white rounded-lg p-8 shadow-lg w-full max-w-md">
                        <h2 className="text-2xl font-bold mb-4">Welcome to developers den</h2>
                        <p className="text-gray-700 mb-6">
                            Empowering tech whizzes to showcase their projects, share articles, and present research papers, while leveraging the wizardry fizz of the Devden Squad.
                        </p>
                        <div className="flex flex-col md:flex-row">
                            <button
                                className="bg-green-500 text-white py-2 px-4 rounded hover:bg-black transition-colors"
                                onClick={() => window.location.href = '/console-login'}
                            >
                                Login
                            </button>
                            <p className="my-2 mx-auto md:ml-5 md:mr-5 md:my-auto">Or</p>
                            <button
                                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-black transition-colors"
                                onClick={() => window.location.href = '/console-signup'}
                            >
                                Sign up
                            </button>
                            <img
                                src="https://www.gigadevden.com/dotdenblack.png"
                                alt="Logo"
                                onClick={() => window.location.href = '/'}
                                className="mt-2 sm:mt-0 ml-0 sm:ml-24 w-auto h-[30px] hover:cursor-pointer hidden md:block"
                            />
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default App;
