import { useState, useRef } from 'react';

function Content() {
    const [isOpen, setIsOpen] = useState(false);

    const timeoutRef = useRef(null);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleContentMouseEnter = () => {
        setIsOpen(true);
        clearTimeout(timeoutRef.current);
    };

    const handleContentMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setIsOpen(false);
        }, 200); // Adjust the delay (in milliseconds) as needed
    };

    const handleMenuMouseEnter = () => {
        clearTimeout(timeoutRef.current);
    };

    const handleMenuMouseLeave = () => {
        setIsOpen(false);
    };


    const [isOpen2, setIsOpen2] = useState(false);

    return (
        <div className="relative"
        >

            <button className="flex items-center px-4 py-2 hidden lg:block text-sm text-gray-700 hover:text-blue-500"
                onMouseEnter={handleContentMouseEnter}
                onMouseLeave={handleContentMouseLeave}
                onClick={handleToggle}>
                {/* <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-6z" />
                </svg> */}
                Content
            </button>
            {isOpen && (
                <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                    onMouseEnter={handleMenuMouseEnter}
                    onMouseLeave={handleMenuMouseLeave}>
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href={'/projects'} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                            Projects
                        </a>
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                            Articles
                        </a>
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                            Research
                        </a>
                        <a
                            href="#"
                            className="mr-10 rounded-tr-md rounded-br-md bg-red-500 block px-4 py-2 text-sm text-white hover:text-gray-700 md:hidden"
                            role="menuitem"
                        >

                        </a>
                        {/* <form method="POST" action="#">
                            <button type="submit" className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                Sign in
                            </button>
                        </form> */}
                        {/* <div className="xl:hidden">
            <button
              type="button"
              className="m-5 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-red-500"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded="true"
              onClick={() => setIsOpen2(!isOpen2)}
            >
              Go to console
            
              <svg
                className="-mr-1 ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                />
                <path
                  fillRule="evenodd"
                  d="M3 10a2 2 0 114 0 2 2 0 01-4 0zM10 10a2 2 0 114 0 2 2 0 01-4 0zM17 10a2 2 0 114 0 2 2 0 01-4 0z"
                />
              </svg>
            </button>
          </div> */}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Content;