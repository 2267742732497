// import React from 'react';

// const CardTemplate = () => {
//   return (
//     <div className="bg-gray-100 flex items-center justify-center px-8 py-5">
//       <div className="bg-gray-800 text-gray-500 rounded shadow-xl py-5 px-5 w-full">
//         <div className="flex w-full">
//           <h3 className="text-lg font-semibold leading-tight flex-1">TOTAL SESSIONS</h3>
//           <div className="relative h-5 leading-none">
//             <button className="text-xl text-gray-500 hover:text-gray-300 h-6 focus:outline-none">
//               <i className="mdi"></i>
//             </button>
//           </div>
//         </div>
//         <div className="relative overflow-hidden transition-all duration-500">
//           <div>
//             <div className="pb-4 lg:pb-6">
//               <h4 className="text-2xl lg:text-3xl text-white font-semibold leading-tight inline-block">0</h4>
//             </div>
//             <div className="pb-4 lg:pb-6">
//               <div className="overflow-hidden rounded-full h-3 bg-gray-800 flex transition-all duration-500"></div>
//             </div>
//             <div className="flex -mx-4">
//               <div className="w-1/3 px-4">
//                 <div className="text-sm">
//                   <span className="inline-block w-2 h-2 rounded-full mr-1 align-middle bg-indigo-600">&nbsp;</span>
//                   <span className="align-middle">Phone&nbsp;</span>
//                 </div>
//                 <div className="font-medium text-lg text-white">
//                   <span>0</span>%
//                 </div>
//               </div>
//               <div className="w-1/3 px-4 border-l border-gray-700">
//                 <div className="text-sm">
//                   <span className="inline-block w-2 h-2 rounded-full mr-1 align-middle bg-indigo-400">&nbsp;</span>
//                   <span className="align-middle">Tablet&nbsp;</span>
//                 </div>
//                 <div className="font-medium text-lg text-white">
//                   <span>0</span>%
//                 </div>
//               </div>
//               <div className="w-1/3 px-4 border-l border-gray-700">
//                 <div className="text-sm">
//                   <span className="inline-block w-2 h-2 rounded-full mr-1 align-middle bg-indigo-200">&nbsp;</span>
//                   <span className="align-middle">Desktop&nbsp;</span>
//                 </div>
//                 <div className="font-medium text-lg text-white">
//                   <span>0</span>%
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="absolute bottom-0 pb-2 text-gray-700 text-xs w-full text-center">
//         See more:{' '}
//         <a href="https://codepen.io/ScottWindon" className="underline hover:text-gray-500" target="_blank">
//           https://codepen.io/ScottWindon
//         </a>
//       </div>
//       {/* <div className="flex items-end justify-end fixed bottom-0 right-0 mb-4 mr-4 z-10">
//         <div>
//           <a
//             title="Buy me a beer"
//             href="https://www.buymeacoffee.com/scottwindon"
//             target="_blank"
//             className="block w-16 h-16 rounded-full transition-all shadow hover:shadow-lg transform hover:scale-110 hover:rotate-12"
//           >
//             <img
//               className="object-cover object-center w-full h-full rounded-full"
//               src="https://i.pinimg.com/originals/60/fd/e8/60fde811b6be57094e0abc69d9c2622a.jpg"
//               alt="Buy Me a Beer"
//             />
//           </a>
//         </div>
//       </div> */}
//     </div>
//   );
// };

// export default CardTemplate;


// import React, { useEffect, useState } from 'react';

// const CardTemplate = () => {
//   const [desktopPercentage, setDesktopPercentage] = useState(0);
//   const [tabletPercentage, setTabletPercentage] = useState(0);
//   const [mobilePercentage, setMobilePercentage] = useState(0);

//   useEffect(() => {
//     // Function to check the device type and set percentages accordingly
//     const checkDeviceType = () => {
//       const screenWidth = window.innerWidth;

//       if (screenWidth < 640) {
//         setDesktopPercentage(0);
//         setTabletPercentage(0);
//         setMobilePercentage(100);
//       } else if (screenWidth >= 640 && screenWidth < 1024) {
//         setDesktopPercentage(0);
//         setTabletPercentage(100);
//         setMobilePercentage(0);
//       } else {
//         setDesktopPercentage(100);
//         setTabletPercentage(0);
//         setMobilePercentage(0);
//       }
//     };

//     // Check the initial device type on component mount
//     checkDeviceType();

//     // Attach a listener to check the device type on window resize
//     window.addEventListener('resize', checkDeviceType);

//     // Remove the event listener on component unmount
//     return () => {
//       window.removeEventListener('resize', checkDeviceType);
//     };
//   }, []);

//   return (
//     <div className="bg-gray-100 flex items-center justify-center px-8 py-5">
//       <div className="bg-gray-800 text-gray-500 rounded shadow-xl py-5 px-5 w-full">
//         {/* ... (rest of your JSX) ... */}
//         <div className="flex w-full">
//           <h3 className="text-lg font-semibold leading-tight flex-1">TOTAL SESSIONS</h3>
//           <div className="relative h-5 leading-none">
//             <button className="text-xl text-gray-500 hover:text-gray-300 h-6 focus:outline-none">
//               <i className="mdi"></i>
//             </button>
//           </div>
//         </div>
        
//         <div className="relative overflow-hidden transition-all duration-500">
//           <div>
//             {/* ... (rest of your JSX) ... */}
//             <div className="pb-4 lg:pb-6">
//               <h4 className="text-2xl lg:text-3xl text-white font-semibold leading-tight inline-block">1</h4>
//             </div>
//             <div className="pb-4 lg:pb-6">
//               <div className="overflow-hidden rounded-full h-3 bg-gray-800 flex transition-all duration-500"></div>
//             </div>
//             <div className="flex -mx-4">
//               <div className="w-1/3 px-4">
//                 <div className="text-sm">
//                   <span className="inline-block w-2 h-2 rounded-full mr-1 align-middle bg-indigo-600">&nbsp;</span>
//                   <span className="align-middle">Phone&nbsp;</span>
//                 </div>
//                 <div className="font-medium text-lg text-white">
//                   <span>{mobilePercentage}%</span>
//                 </div>
//               </div>
//               <div className="w-1/3 px-4 border-l border-gray-700">
//                 <div className="text-sm">
//                   <span className="inline-block w-2 h-2 rounded-full mr-1 align-middle bg-indigo-400">&nbsp;</span>
//                   <span className="align-middle">Tablet&nbsp;</span>
//                 </div>
//                 <div className="font-medium text-lg text-white">
//                   <span>{tabletPercentage}%</span>
//                 </div>
//               </div>
//               <div className="w-1/3 px-4 border-l border-gray-700">
//                 <div className="text-sm">
//                   <span className="inline-block w-2 h-2 rounded-full mr-1 align-middle bg-indigo-200">&nbsp;</span>
//                   <span className="align-middle">Desktop&nbsp;</span>
//                 </div>
//                 <div className="font-medium text-lg text-white">
//                   <span>{desktopPercentage}%</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* ... (rest of your JSX) ... */}
//     </div>
//   );
// };

// export default CardTemplate;


import React, { useEffect, useState } from 'react';

const CardTemplate = () => {
  const [deviceType, setDeviceType] = useState('desktop');

  useEffect(() => {
    // Function to check the device type and set it accordingly
    const checkDeviceType = () => {
      const userAgent = navigator.userAgent.toLowerCase();

      if (/mobile|android|iphone|ipad|phone|blackberry|opera mini|iemobile/i.test(userAgent)) {
        setDeviceType('mobile');
      } else if (/tablet|ipad|playbook|silk/i.test(userAgent)) {
        setDeviceType('tablet');
      } else {
        setDeviceType('desktop');
      }
    };

    // Check the initial device type on component mount
    checkDeviceType();

    // Attach a listener to check the device type on window resize
    window.addEventListener('resize', checkDeviceType);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, []);

  return (
    <div className="bg-gray-100 flex items-center justify-center px-8 py-5">
      <div className="bg-gray-800 text-gray-500 rounded shadow-xl py-5 px-5 w-full">
        {/* ... (rest of your JSX) ... */}
        <div className="flex w-full">
          <h3 className="text-lg font-semibold leading-tight flex-1">TOTAL SESSIONS</h3>
          <div className="relative h-5 leading-none">
            <button className="text-xl text-gray-500 hover:text-gray-300 h-6 focus:outline-none">
              <i className="mdi"></i>
            </button>
          </div>
        </div>
        <div className="relative overflow-hidden transition-all duration-500">
          <div>
            {/* ... (rest of your JSX) ... */}
            <div className="pb-4 lg:pb-6">
              <h4 className="text-2xl lg:text-3xl text-white font-semibold leading-tight inline-block">1</h4>
            </div>
            <div className="pb-4 lg:pb-6">
              <div className="overflow-hidden rounded-full h-3 bg-gray-800 flex transition-all duration-500"></div>
            </div>
            <div className="flex -mx-4">
              <div className="w-1/3 px-4">
                <div className="text-sm">
                  <span className="inline-block w-2 h-2 rounded-full mr-1 align-middle bg-indigo-600">&nbsp;</span>
                  <span className="align-middle">Phone&nbsp;</span>
                </div>
                <div className="font-medium text-lg text-white">
                  <span>{deviceType === 'mobile' ? '100' : '0'}%</span>
                </div>
              </div>
              <div className="w-1/3 px-4 border-l border-gray-700">
                <div className="text-sm">
                  <span className="inline-block w-2 h-2 rounded-full mr-1 align-middle bg-indigo-400">&nbsp;</span>
                  <span className="align-middle">Tablet&nbsp;</span>
                </div>
                <div className="font-medium text-lg text-white">
                  <span>{deviceType === 'tablet' ? '100' : '0'}%</span>
                </div>
              </div>
              <div className="w-1/3 px-4 border-l border-gray-700">
                <div className="text-sm">
                  <span className="inline-block w-2 h-2 rounded-full mr-1 align-middle bg-indigo-200">&nbsp;</span>
                  <span className="align-middle">Desktop&nbsp;</span>
                </div>
                <div className="font-medium text-lg text-white">
                  <span>{deviceType === 'desktop' ? '100' : '0'}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ... (rest of your JSX) ... */}
    </div>
  );
};

export default CardTemplate;
