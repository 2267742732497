import { useState } from "react";

function ProductBottomBar({ currentPage, totalPages, onPageChange }) {
  const [selectedPage, setSelectedPage] = useState(currentPage);

  const handlePageChange = (event) => {
    const newPage = parseInt(event.target.value);
    setSelectedPage(newPage);
    onPageChange(newPage);
  };

  return (
    <div className="flex justify-between items-center bg-white p-4">
      <div className="text-gray-600 text-sm hidden md:block">
        Showing page {currentPage} of {totalPages}
      </div>
      <div className="text-gray-600 text-sm md:hidden">
        Page {currentPage} of {totalPages}
      </div>
      <div className="flex justify-end">
        <button
          className={`border border-gray-500 rounded-md py-1 px-2 mr-2 ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          className={`border border-gray-500 rounded-md py-1 px-2 ${
            currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
        <select
          className="border border-gray-500 bg-black text-white rounded-md py-1 px-2 ml-2"
          value={selectedPage}
          onChange={handlePageChange}
        >
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <option key={page} value={page}>
              Page {page}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

function Bottombar() {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 5; // replace with the actual total number of pages
  
    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };
  
    return (
      <div>
  
        {/* display bottom bar for pagination */}
        <ProductBottomBar
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    );
  }

  export default Bottombar;