import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import HeaderBlack from '../partials/HeaderBlack.jsx';
import PageIllustration from '../partials/PageIllustration.jsx';
import Banner from '../partials/Banner.jsx';
import Footer from '../partials/Footer.jsx'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { FaWindowClose } from 'react-icons/fa';

function SignIn() {

  const websitePrefix = 'www.gigadevden.com';

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setEmail(localStorage.getItem('${websitePrefix}-temppublicemail'));
    setPassword(localStorage.getItem('${websitePrefix}-temppublicpassword'));
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://gigadevden.com/webpubliclogin.php?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`
      );

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        // Response is JSON

        const data = await response.json();
        // Access data using keys and put them in variables
    
        localStorage.setItem('${websitePrefix}-publiclogin', '1');
        localStorage.setItem('${websitePrefix}-publicid', data.id);
        localStorage.setItem('${websitePrefix}-publicname', data.name);
        localStorage.setItem('${websitePrefix}-publicemail', data.email);
        localStorage.setItem('${websitePrefix}-publicpassword', data.password);
        localStorage.setItem('${websitePrefix}-publicimage',data.image);
        window.location.href = "/";

      } else {
        // Response is text
        const text = await response.text();
        
        if (text === '"Incorrect password"') {
          toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaWindowClose className="mr-2 text-red-600" />
              <span>Incorrect password</span>
            </div>,
            {
              className: 'custom-toast-success', // Add a custom CSS class for styling
            }
          );
        } else if (text === '"Email does not exist"') {
          toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaWindowClose className="mr-2 text-red-600" />
              <span>User doesn't exist</span>
            </div>,
            {
              className: 'custom-toast-success', // Add a custom CSS class for styling
            }
          );
        } else if (text === '"All fields are required"') {
          toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaWindowClose className="mr-2 text-red-600" />
              <span>All fields are required</span>
            </div>,
            {
              className: 'custom-toast-success', // Add a custom CSS class for styling
            }
          );
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };


  return (
    <div className="bg-black font-inter antialiased text-gray-200 tracking-tight font-urbanist"
    >
      <div className="flex flex-col min-h-screen overflow-hidden">

        {/*  Site header */}
        <HeaderBlack />

        {/*  Page content */}
        <main className="grow">

          {/*  Page illustration */}
          <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
            <PageIllustration />
          </div>

          <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="pt-12 pb-12 md:pt-16 md:pb-20">

               

                {/* Page header */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                  <h1 className="text-4xl font-extrabold leading-tight tracking-tighter">Welcome back. We exist to make entrepreneurship easier.</h1>
                </div>

                {/* Form */}
                <div className="max-w-sm mx-auto">
                  {/* <form>
                    <div className="flex flex-wrap -mx-3">
                      <div className="w-full px-3">
                        <button className="btn px-0 text-white bg-red-600 hover:bg-red-700 w-full relative flex items-center">
                          <svg className="w-4 h-4 fill-current text-white opacity-75 shrink-0 mx-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z" />
                          </svg>
                          <span className="h-6 flex items-center border-r border-white border-opacity-25 mr-4" aria-hidden="true"></span>
                          <span className="flex-auto pl-16 pr-8 -ml-16">Sign in with Google</span>
                        </button>
                      </div>
                    </div>
                  </form> */}
                  {/* <div className="flex items-center my-6">
                    <div className="border-t border-gray-700 border-dotted grow mr-3" aria-hidden="true"></div>
                    <div className="text-gray-400">Or, sign in with your email</div>
                    <div className="border-t border-gray-700 border-dotted grow ml-3" aria-hidden="true"></div>
                  </div> */}
                  <form onSubmit={handleFormSubmit}>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">Email</label>
                        <input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)}  className="form-input w-full text-gray-300" placeholder="" required />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="password">Password</label>
                        <input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-input w-full text-gray-300" placeholder="" required />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <div className="flex justify-between">
                          {/* <label className="flex items-center">
                            <input type="checkbox" className="form-checkbox" />
                            <span className="text-gray-400 ml-2">Keep me signed in</span>
                          </label> */}
                          <Link to="/reset-password" className="text-blue-600 hover:text-gray-200 transition duration-150 ease-in-out">Forgot Password?</Link>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mt-6">
                      <div className="w-full px-3">
                        <button className="btn text-white bg-red-600 hover:bg-red-700 w-full">Sign in</button>
                      </div>
                    </div>
                  </form>
                  <div className="text-gray-400 text-center mt-6">
                    Don’t you have an account? <Link to="/signup" className="text-blue-600 hover:text-gray-200 transition duration-150 ease-in-out">Sign up</Link>
                  </div>
                </div>

              </div>
            </div>
          </section>

        </main>


        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />


        <Banner />

        <Footer />

      </div>
    </div>
  );
}

export default SignIn;