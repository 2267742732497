import React, { useState, useEffect } from 'react';

const EmployeeData = () => {
  const [data, setData] = useState([]);
  const [empno, setEmpno] = useState('');
  const [ename, setEname] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`https://gigadevden.com/ali.php?empno=${empno}&ename=${ename}`);
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEmpnoChange = (event) => {
    setEmpno(event.target.value);
  };

  const handleEnameChange = (event) => {
    setEname(event.target.value);
  };

  return (
    <div>
      <h1>Employee Data</h1>
      <div>
        <label htmlFor="empno">Employee No:</label>
        <input type="text" id="empno" value={empno} onChange={handleEmpnoChange} />
      </div>
      <div>
        <label htmlFor="ename">Employee Name:</label>
        <input type="text" id="ename" value={ename} onChange={handleEnameChange} />
      </div>
      <button onClick={fetchData}>Fetch Data</button>
      <table>
        <thead>
          <tr>
            <th>Employee No.</th>
            <th>Name</th>
            <th>Job</th>
            <th>Manager</th>
            <th>Hire Date</th>
            <th>Salary</th>
            <th>Commission</th>
            <th>Department No.</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.empno}>
              <td>{item.empno}</td>
              <td>{item.ename}</td>
              <td>{item.job}</td>
              <td>{item.mgr}</td>
              <td>{item.hiredate}</td>
              <td>{item.sal}</td>
              <td>{item.comm}</td>
              <td>{item.deptno}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeData;
