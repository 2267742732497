import React, { useState } from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

export default function HomePageContentUnits() {

    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);

    const [isBackgroundLoaded1, setIsBackgroundLoaded1] = useState(false);
    const [isBackgroundLoaded2, setIsBackgroundLoaded2] = useState(false);
    const [isBackgroundLoaded3, setIsBackgroundLoaded3] = useState(false);

    return (
        <div className="pt-8">

            
            {/* <Head>
                <title>Component</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Head> */}
            <CarouselProvider isIntrinsicHeight={true} totalSlides={3}>

                
                <div className="lg:hidden px-6 md:px-16 xl:px-0 overflow-y-hidden mb-16">
                    <div className="container mx-auto">
                        {/* <div className="flex flex-col lg:items-center justify-center w-full  py-1">
                            <h1 className="font-semibold text-gray-800 text-3xl md:text-4xl">Updated line</h1>
                        </div> */}

                        <div class="section-title px-5">
          <h2 className="font-montserrat text-3xl">Our <span className="text-yellow-400 text-4xl"> ──────</span></h2>
          <p className="font-montserrat text-3xl font-bold">SERVICES</p>
        </div>


                        <div className="w-full flex flex-col items-end justify-center">
                            <p className="text-lg text-gray-100">
                                <span id="current"></span>
                                <span className="text-gray-800"></span>
                            </p>
                            <div className="my-4 flex items-center">
                                <ButtonBack>
                                    <button aria-controls="slide" aria-label="slide back" className="rounded-full hover:bg-gray-200 focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none cursor-pointer mr-4 p-2 flex items-center justify-center ">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-left" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <line x1="5" y1="12" x2="19" y2="12" />
                                            <line x1="5" y1="12" x2="9" y2="16" />
                                            <line x1="5" y1="12" x2="9" y2="8" />
                                        </svg>
                                    </button>
                                </ButtonBack>
                                <ButtonNext>
                                    <div aria-controls="slide" aria-label="slide forward" className="rounded-full hover:bg-gray-200 focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none cursor-pointer flex items-center justify-center  ">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4338CA" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <line x1="5" y1="12" x2="19" y2="12" />
                                            <line x1="15" y1="16" x2="19" y2="12" />
                                            <line x1="15" y1="8" x2="19" y2="12" />
                                        </svg>
                                    </div>
                                </ButtonNext>
                            </div>
                        </div>
                        
                        <div className="flex justify-between">

                            
                            <Slider>
                                <Slide index={0}>
                                    <div className="cursor-pointer hover:cursor-pointer" onClick={() => window.location.href = '/projects'}>
                                        <div className="w-full px-5">
                                            <div className="w-full flex justify-center items-center flex-col rounded-md">
                                                {isBackgroundLoaded1 ? (
                                                    <div
                                                        onMouseEnter={() => setIsHovered1(true)}
                                                        onMouseLeave={() => setIsHovered1(false)}
                                                        style={{
                                                            backgroundImage: "url('https://gigadevden.com/project-women.jpeg')",
                                                            backgroundSize: isHovered1 ? "110%" : "100%",
                                                            transition: "background-size 0.3s ease-in-out",
                                                        }}
                                                        className="relative bg-cover w-full flex justify-center flex-col rounded-md"
                                                    >
                                                        <div className="h-64 relative bg-cover w-full flex justify-center flex-col rounded-md">
                                                            <div className="absolute inset-0 bg-gray-800 opacity-70 rounded-md"></div>
                                                            <h2 className="text-white font-bold text-5xl rounded z-50 m-auto">Projects</h2>
                                                        </div>
                                                        <button className="focus:outline-none absolute right-10 bottom-7 bg-white rounded-full p-2 hover:opacity-75"
                                                            onClick={() => window.location.href = '/projects'}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="w-80 h-64 bg-gray-500 animate-pulse rounded-md">
                                                        <img
                                                            src="https://gigadevden.com/project-women.jpeg"
                                                            alt="Loading"
                                                            onLoad={() => setIsBackgroundLoaded1(true)}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Slide>

                                <Slide index={1}>
                                    <div className="cursor-pointer hover:cursor-pointer" onClick={() => window.location.href = '/infodexeus/articles'}>
                                        <div className="w-full px-5">
                                            <div className="w-full flex justify-center items-center flex-col rounded-md">
                                                {isBackgroundLoaded2 ? (
                                                    <div
                                                        onMouseEnter={() => setIsHovered2(true)}
                                                        onMouseLeave={() => setIsHovered2(false)}
                                                        style={{
                                                            backgroundImage: "url('https://gigadevden.com/article.jpg')",
                                                            backgroundSize: isHovered2 ? "110%" : "100%",
                                                            transition: "background-size 0.3s ease-in-out",
                                                        }}
                                                        className="relative bg-cover w-full flex justify-center flex-col rounded-md"
                                                    >
                                                        {/* <h1 className="w-40 text-white font-semibold text-left text-lg absolute left-0 mt-7 ml-7 rounded h-64 relative">
                                                            Articles: Technical, Political, Social & more.
                                                        </h1> */}
                                                        <div className="h-64 relative bg-cover w-full flex justify-center flex-col rounded-md">
                                                            <div className="absolute inset-0 bg-gray-800 opacity-70 rounded-md"></div>
                                                            <h2 className="text-white font-bold text-5xl rounded z-50 m-auto">Articles</h2>
                                                        </div>
                                                        <button className="focus:outline-none absolute right-10 bottom-7 bg-white rounded-full p-2 hover:opacity-75"
                                                            onClick={() => window.location.href = '/infodexeus/articles'}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="w-80 h-64 bg-gray-500 animate-pulse rounded-md">
                                                        <img
                                                            src="https://gigadevden.com/article.jpg"
                                                            alt="Loading"
                                                            onLoad={() => setIsBackgroundLoaded2(true)}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                                <Slide index={2}>
                                    <div className="cursor-pointer hover:cursor-pointer" onClick={() => window.location.href = '/research'}>
                                        <div className="w-full px-5">
                                            <div className="w-full flex justify-center items-center flex-col rounded-md">
                                                {isBackgroundLoaded3 ? (
                                                    <div
                                                        onMouseEnter={() => setIsHovered3(true)}
                                                        onMouseLeave={() => setIsHovered3(false)}
                                                        style={{
                                                            backgroundImage: "url('https://gigadevden.com/AI.jpeg')",
                                                            backgroundSize: isHovered3 ? "110%" : "100%",
                                                            transition: "background-size 0.3s ease-in-out",
                                                        }}
                                                        className="relative bg-cover w-full flex justify-center flex-col rounded-md"
                                                    >
                                                        <div className="h-64 relative bg-cover w-full flex justify-center flex-col rounded-md">
                                                            <div className="absolute inset-0 bg-gray-800 opacity-70 rounded-md"></div>
                                                            <h2 className="text-white font-bold text-5xl rounded z-50 m-auto">Research</h2>
                                                        </div>
                                                        <button className="focus:outline-none absolute right-10 bottom-7 bg-white rounded-full p-2 hover:opacity-75"
                                                            onClick={() => window.location.href = '/research'}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="w-80 h-64 bg-gray-500 animate-pulse rounded-md">
                                                        <img
                                                            src="https://gigadevden.com/AI.jpeg"
                                                            alt="Loading"
                                                            onLoad={() => setIsBackgroundLoaded3(true)}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                            </Slider>
                        </div>
                    </div>
                </div>
            </CarouselProvider>
            <CarouselProvider naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={3}>

                
                <div className="hidden lg:block px-6 mb-16">

                    
                    <div className="container mx-auto">

                    <div class="section-title">
          <h2 className="font-montserrat text-3xl">Our <span className="text-yellow-400 text-4xl"> ──────</span></h2>
          <p className="font-montserrat text-3xl font-bold">SERVICES</p>
        </div>


                        <div className="flex flex-row">
                            <div role="article" className="flex flex-col items-left justify-center w-full py-6">
                                {/* <h1 className="font-bold text-gray-800 text-3xl md:text-5xl">Featured</h1> */}
                            </div>
                            <div className="w-full flex flex-col items-end justify-end pr-24 my-3">
                                <p className="text-lg text-gray-100">
                                    <span id="current3"></span>
                                    <span className="text-gray-800"></span>
                                </p>
                            </div>
                            {/* <div className="my-4 flex items-center">
                                <ButtonBack>
                                    <button aria-controls="slide" aria-label="slide back" className="rounded-full hover:bg-gray-200 focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none cursor-pointer mr-4 p-2 flex items-center justify-center ">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-left" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <line x1="5" y1="12" x2="19" y2="12" />
                                            <line x1="5" y1="12" x2="9" y2="16" />
                                            <line x1="5" y1="12" x2="9" y2="8" />
                                        </svg>
                                    </button>
                                </ButtonBack>
                                <ButtonNext>
                                    <div aria-controls="slide" aria-label="slide forward" className="rounded-full hover:bg-gray-200 focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none cursor-pointer flex items-center justify-center  ">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4338CA" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <line x1="5" y1="12" x2="19" y2="12" />
                                            <line x1="15" y1="16" x2="19" y2="12" />
                                            <line x1="15" y1="8" x2="19" y2="12" />
                                        </svg>
                                    </div>
                                </ButtonNext>
                            </div> */}
                        </div>
                        <div id="slide" aria-roledescription="carousel" aria-label="about healt care" className="slider3">
                            <div className="slide-ana3">
                                <Slider>
                                    <Slide index={0}>
                                        <div className="grid grid-cols-3  gap-6">
                                            <button onClick={() => window.location.href = '/projects'}>
                                                {isBackgroundLoaded1 ? (
                                                    <div
                                                        aria-live="off"
                                                        aria-roledescription="slide1"
                                                        className="rounded-md"
                                                        onMouseEnter={() => setIsHovered1(true)}
                                                        onMouseLeave={() => setIsHovered1(false)}
                                                        style={{
                                                            backgroundImage: "url('https://gigadevden.com/project-women.jpeg')",
                                                            backgroundSize: isHovered1 ? "110%" : "100%",
                                                            transition: "background-size 0.3s ease-in-out",
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <div className="h-64 relative bg-cover w-full flex justify-center flex-col rounded-md">
                                                            <div className="absolute inset-0 bg-gray-800 opacity-70 rounded-md"></div>
                                                            <h2 className="text-white font-bold text-6xl rounded z-50">Projects</h2>
                                                        </div>
                                                        <button
                                                            className="focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none absolute right-10 bottom-7 bg-white rounded-full p-2 hover:opacity-75"
                                                            onClick={() => (window.location.href = "/projects")}
                                                            style={{
                                                                zIndex: 10, // Adjust the value as needed
                                                            }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-gray-600"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                            >
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>

                                                ) : (
                                                    <div className="w-full h-64 bg-gray-400 animate-pulse rounded-md">
                                                        <img
                                                            src="https://gigadevden.com/project-women.jpeg"
                                                            alt="Loading"
                                                            onLoad={() => setIsBackgroundLoaded1(true)}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                )}
                                            </button>
                                            <button onClick={() => window.location.href = '/infodexeus/articles'}>
                                                {isBackgroundLoaded2 ? (
                                                    <div
                                                        aria-live="off"
                                                        aria-roledescription="slide2"
                                                        className="rounded-md"
                                                        onMouseEnter={() => setIsHovered2(true)}
                                                        onMouseLeave={() => setIsHovered2(false)}
                                                        style={{
                                                            backgroundImage: "url('https://gigadevden.com/article.jpg')",
                                                            backgroundSize: isHovered2 ? "110%" : "100%",
                                                            transition: "background-size 0.3s ease-in-out",
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <div className="h-64 relative bg-cover w-full flex justify-center flex-col rounded-md">
                                                            <div className="absolute inset-0 bg-gray-800 opacity-70 rounded-md"></div>
                                                            <h2 className="text-white font-bold text-6xl rounded z-50">Articles</h2>
                                                        </div>
                                                        <button
                                                            className="focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none absolute right-10 bottom-7 bg-white rounded-full p-2 hover:opacity-75"
                                                            onClick={() => (window.location.href = "/infodexeus/articles")}
                                                            style={{
                                                                zIndex: 10, // Adjust the value as needed
                                                            }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-gray-600"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                            >
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>


                                                ) : (
                                                    <div className="w-full h-64 bg-gray-400 animate-pulse rounded-md">
                                                        <img
                                                            src="https://gigadevden.com/article.jpg"
                                                            alt="Loading"
                                                            onLoad={() => setIsBackgroundLoaded2(true)}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                )}
                                            </button>
                                            <button onClick={() => window.location.href = '/research'}>
                                                {isBackgroundLoaded3 ? (
                                                    <div
                                                        aria-live="off"
                                                        aria-roledescription="slide3"
                                                        className="rounded-md"
                                                        onMouseEnter={() => setIsHovered3(true)}
                                                        onMouseLeave={() => setIsHovered3(false)}
                                                        style={{
                                                            backgroundImage: "url('https://gigadevden.com/AI.jpeg')",
                                                            backgroundSize: isHovered3 ? "110%" : "100%",
                                                            transition: "background-size 0.3s ease-in-out",
                                                        }}
                                                    >
                                                        <div className="h-64 relative bg-cover w-full flex justify-center flex-col rounded-md">
                                                            <div className="absolute inset-0 bg-gray-800 opacity-70 rounded-md"></div>
                                                            <h2 className="text-white font-bold text-6xl rounded z-50">Research</h2>
                                                        </div>
                                                        <button
                                                            className="focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none absolute right-10 bottom-10 bg-white rounded-full p-2 hover:opacity-75"
                                                            onClick={() => (window.location.href = "/research")}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-gray-600"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                            >
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>


                                                ) : (
                                                    <div className="w-full h-64 bg-gray-400 animate-pulse rounded-md">
                                                        <img
                                                            src="https://gigadevden.com/AI.jpeg"
                                                            alt="Loading"
                                                            onLoad={() => setIsBackgroundLoaded3(true)}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                )}
                                            </button>
                                        </div>
                                    </Slide>
                                    <Slide index={1}>
                                        <div className="grid grid-cols-3 gap-6">
                                            <div className=" ">
                                                <div aria-live="off" aria-roledescription="slide4" className="bg-white w-full flex justify-center items-center flex-col  rounded-md ">
                                                    <div style={{ "background-image": "url('https://i.ibb.co/93dVNbx/Rectangle-27.png');" }} className="relative  bg-cover w-full flex justify-center  flex-col  rounded-md ">
                                                        <h1 className="w-40 text-white font-semibold text-lg absolute left-0 mt-7 ml-7 rounded  h-64 relative">Who doesn’t love a good swim</h1>

                                                        <button className="focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none absolute right-10 bottom-7 bg-white rounded-full p-2 hover:opacity-75">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" ">
                                                <div aria-live="off" aria-roledescription="slide5" className="bg-white w-full flex justify-center items-center flex-col  rounded-md">
                                                    <div style={{ "background-image": "url('https://i.ibb.co/M6jV7ty/Rectangle-28.png');" }} className="relative  bg-cover w-full flex justify-center  flex-col  rounded-md ">
                                                        <h1 className="w-40 text-white font-semibold text-lg absolute left-0 mt-7 ml-7 rounded  h-64 relative">A truly immersive experience like never before</h1>

                                                        <button className="focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none absolute right-10 bottom-7 bg-white rounded-full p-2 hover:opacity-75">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" ">
                                                <div aria-live="off" aria-roledescription="slide6" className="bg-white w-full flex justify-center items-center flex-col  rounded-md">
                                                    <div style={{ "background-image": "url('https://i.ibb.co/Lgjy80Y/Content-image-1.png');" }} className="relative  bg-cover w-full flex justify-center  flex-col  rounded-md ">
                                                        <h1 className="w-40 text-white font-semibold text-lg absolute left-0 mt-7 ml-7 rounded  h-64 relative">Maybe a walk on the beach could help</h1>

                                                        <button className="focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none absolute right-10 bottom-7 bg-white rounded-full p-2 hover:opacity-75">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={2}>
                                        <div className="grid grid-cols-3 gap-6">
                                            <div className=" ">
                                                <div aria-live="off" aria-roledescription="slide7" className="bg-white w-full flex justify-center items-center flex-col  rounded-md">
                                                    <div style={{ "background-image": "url('https://i.ibb.co/93dVNbx/Rectangle-27.png');" }} className="relative  bg-cover w-full flex justify-center  flex-col  rounded-md ">
                                                        <h1 className="w-40 text-white font-semibold text-lg absolute left-0 mt-7 ml-7 rounded  h-64 relative">Who doesn’t love a good swim</h1>

                                                        <button className="focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none absolute right-10 bottom-7 bg-white rounded-full p-2 hover:opacity-75">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" ">
                                                <div aria-live="off" aria-roledescription="slide8" className="bg-white w-full flex justify-center items-center flex-col  rounded-md">
                                                    <div style={{ "background-image": "url('https://i.ibb.co/M6jV7ty/Rectangle-28.png');" }} className="relative  bg-cover w-full flex justify-center  flex-col  rounded-md ">
                                                        <h1 className="w-40 text-white font-semibold text-lg absolute left-0 mt-7 ml-7 rounded  h-64 relative">A truly immersive experience like never before</h1>

                                                        <button className="focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none absolute right-10 bottom-7 bg-white rounded-full p-2 hover:opacity-75">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" ">
                                                <div aria-live="off" aria-roledescription="slide9" className="bg-white w-full flex justify-center items-center flex-col  rounded-md">
                                                    <div style={{ "background-image": "url('https://i.ibb.co/Lgjy80Y/Content-image-1.png');" }} className="relative  bg-cover w-full flex justify-center  flex-col  rounded-md ">
                                                        <h1 className="w-40 text-white font-semibold text-lg absolute left-0 mt-7 ml-7 rounded  h-64 relative">Maybe a walk on the beach could help</h1>

                                                        <button className="focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none absolute right-10 bottom-7 bg-white rounded-full p-2 hover:opacity-75">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </CarouselProvider>
        </div>
    );
}
