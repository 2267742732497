import { useEffect, useRef, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import blueAndYellow from '../images/blue_and_yellow.jpg'

const Ai = () => {

    const inputRef = useRef(null);

    const [reportsOpen, setReportsOpen] = useState(true);

    const toggleReports = () => {
        setReportsOpen(!reportsOpen);
    };

    useEffect(() => {
        setReportsOpen(!reportsOpen);
      }, []);

    return (
        <div className="flex flex-col z-10 mx-4 md:mx-16 xl:mx-24 mb-8 mt-8 md:mt-20">
            <div className={`w-full px-4 pb-4 pt-6 bg-gray-950 rounded-lg ${reportsOpen ? '-translate-y-48 opacity-0 pointer-events-none' : 'opacity-100'
                }`}
                
                style={{backgroundImage: `url('${blueAndYellow}')`, backgroundSize: 'auto 600%', boxShadow: '0 0 10px rgba(0, 0, 0, 0.4)'}}
                >
                <div className="flex flex-col md:flex-row items-center px-4">


                <div class="space-y-4 mb-8 w-full">
                    <h2 class="text-2xl font-semibold text-white md:text-4xl">
                        Search Precisely{' '}
                        <span class="text-gray-200 font-thin">with AI Assistance</span>
                    </h2>
                    <p className="block antialiased font-sans mb-6 text-md font-light text-gray-100 lg:pr-12">
                        Looking to find what you need? Ask
                        <b className="text-white text-lg font-poppins">
                            <b className="text-red-600"> .</b>
                            den</b>
                        , our AI-powered chatbot, for assistance!
                    </p>


                    <div class="gap-6 flex">
                        <a href="/chat.den.ai" title="customize" aria-label="customize" class="w-max relative flex gap-2 h-9 items-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-gray-900 before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95">
                            
                            <span class="w-max relative text-sm font-semibold text-white">
                                AI ChatBot
                            </span>
                        </a>
                        <button class="block-switcher group relative flex h-9 w-9 rounded-full before:absolute before:inset-0 before:rounded-full before:border before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 before:border-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" class="sun relative transistion m-auto h-5 w-5 duration-300 group-hover:rotate-180 fill-gray-300 group-hover:fill-yellow-400 hidden" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clip-rule="evenodd"></path>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" class="moon relative transistion m-auto h-5 w-5 duration-300 group-hover:-rotate-90 fill-gray-300 group-hover:fill-white block" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                            </svg>
                        </button>
                    </div>
                </div>


                    {/* <div className="w-full lg:mt-4 font-urbanist">
                        <div className="bg-gradient-to-r from-cyan-500 to-pink-500 w-fit pb-1 mb-4">
                        <p className="block antialiased font-poppins w-fit text-3xl leading-tight mb-2 font-bold tracking-normal text-gray-900 bg-white">AI ChatBot</p>

                        </div>

                        <p className="block antialiased font-sans mb-6 text-md font-light text-gray-900 lg:pr-12">
                            Looking to find what you need? Ask
                            <b className="text-gray-900 text-lg font-poppins">
                                <b className="text-red-600"> .</b>
                                den</b>
                            , our AI-powered chatbot, for assistance!
                        </p>
                    </div> */}



                    <div className="pt-4 mb-2 sm:mb-0">
                        <div className="relative flex">
                            <input
                                type="text"
                                placeholder="Say something..."
                                autoComplete="off"
                                //autoFocus={true}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        window.location.href = `/chat.den.ai?search=${encodeURIComponent(inputRef.current.value)}`
                                    }
                                }}
                                className="text-md w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-5 pr-32 bg-gray-100 border-2 border-gray-300 focus:border-blue-500 rounded-full py-2"
                                ref={inputRef}
                            />
                            <div className="absolute right-2 items-center inset-y-0 flex">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-full h-8 w-8 transition duration-200 ease-in-out text-white bg-blue-500 hover:bg-blue-600 focus:outline-none"

                                    onClick={() => window.location.href = `/chat.den.ai?search=${encodeURIComponent(inputRef.current.value)}`}
                                >
                                    {/* <i className="mdi mdi-arrow-right text-xl leading-none"></i> */}
                                    <FaArrowRight />
                                </button>
                            </div>
                        </div>
                    </div>



                    {/* <div
                        className="flex p-5 md:p-0 w-full transition duration-300 ease-in border-b pb-10"
                        style={{ display: reportsOpen ? 'block' : 'none' }}
                    >
                        This is a very simple dropdown/accordion/collapse (whatever you call it) using Tailwind, Alpine.js, and the Alpine.js plugin "Collapse" to enable smoother open/collapse transitions than what comes out of the box with Alpine.js
                    </div> */}
                </div>
            </div>

            {/* <div
                onClick={toggleReports}
                className={`opacity-40 flex h-16 items-center justify-center text-gray-600 w-full overflow-hidden mb-5 mx-auto transition duration-300 ease-in ${reportsOpen ? '-translate-y-48 sm:mt-4 md:mt-16' : 'mt-4'
                    }`}
            >
                <div
                    className={`cursor-pointer w-10 h-10 bg-slate-800 border-2 border-gray-900 animate-bounce px-2 shadow-lg rounded-full flex items-center justify-center ring-slate-900/5 transform transition duration-300 ease-in-out ${reportsOpen ? 'rotate-90' : '-translate-y-0.0'
                        }`}
                >

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                            color='skyblue'
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    


                </div>
            </div> */}


            
      

        </div>
    );
};

export default Ai;
