// // import React, { useState, useEffect } from 'react';
// // import { HiOutlineDownload } from 'react-icons/hi';
// // import { SiGoogledrive } from 'react-icons/si';
// // import { FileIcon, defaultStyles } from 'react-file-icon';


// // const EmployeeData = () => {
// //   const [data, setData] = useState([]);

// //   useEffect(() => {
// //     fetchData();
// //   }, []);

// //   const fetchData = async () => {
// //     try {
// //       const response = await fetch('https://gigadevden.com/ali.php');
// //       const json = await response.json();
// //       setData(json);
// //     } catch (error) {
// //       console.error('Error fetching data:', error);
// //     }
// //   };

// //   const getFileIcon = (driveLink) => {
// //     const fileExtension = driveLink.split('.').pop().toLowerCase();

// //     if (fileExtension === 'pdf') {
// //       return <SiGoogledrive className="w-6 h-6 text-red-500" />;
// //     } else if (fileExtension === 'doc' || fileExtension === 'docx') {
// //       return <SiGoogledrive className="w-6 h-6 text-blue-500" />;
// //     } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
// //       return <SiGoogledrive className="w-6 h-6 text-green-500" />;
// //     } else {
// //       return <SiGoogledrive className="w-6 h-6 text-gray-500" />;
// //     }
// //   };

// //   const downloadFile = (driveLink) => {
// //     window.open(driveLink, '_blank');
// //   };

// //   return (
// //     <div className="container mx-auto">
// //       <h1 className="text-3xl font-bold text-center mt-8">Employee Data</h1>
// //       <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mt-8">
// //         {data.map((item) => (
// //           <div key={item.id} className="border p-4 rounded-lg shadow">
// //             <div className="relative overflow-hidden">
// //               <div className="h-32 mb-4">
// //                 <img
// //                   className="object-cover w-full h-full"
// //                   src="https://th.bing.com/th/id/OIP.9a3jigzUYVXBZRSIDtq0KwHaGG?pid=ImgDet&rs=1"
// //                   alt="Sample Image"
// //                 />
// //               </div>
// //             </div>
// //             <div> {/* Divider two */}
// //               <h2 className="text-xl font-bold mb-2">{item.title}</h2>
// //               <p className="mb-4">{item.description}</p>
// //               {item.driveLink && (
// //                 <div className="flex items-center">
// //                   <div className="mr-2">{getFileIcon(item.driveLink)}</div>
// //                   <button
// //                     className="flex items-center text-blue-500"
// //                     onClick={() => downloadFile(item.driveLink)}
// //                   >
// //                     <HiOutlineDownload className="w-4 h-4 mr-1" />
// //                     Download
// //                   </button>
// //                 </div>
// //               )}
// //             </div>
// //           </div>
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };

// // export default EmployeeData;


// // import React, { useState, useEffect } from 'react';
// // import { HiOutlineDownload } from 'react-icons/hi';
// // import { SiGoogledrive } from 'react-icons/si';
// // import Header from '../partials/Header';
// // import Footer from '../partials/Footer';
// // import { SearchIcon } from '@heroicons/react/solid';

// // const EmployeeData = () => {

// //     const [searchTerm, setSearchTerm] = useState('');

// //     const handleSearchChange = (event) => {
// //       setSearchTerm(event.target.value);
// //     };

// //     const handleSearchSubmit = (event) => {
// //       event.preventDefault();
// //       // handle search submit here

// //     };

// //     const [data, setData] = useState([]);

// //     useEffect(() => {
// //         fetchData();
// //     }, [searchTerm]);

// //     useEffect(() => {
// //         fetchData();
// //     }, []);

// //     const fetchData = async () => {
// //         try {
// //             const response = await fetch(`https://gigadevden.com/ali.php?search=${encodeURIComponent(searchTerm)}`);
// //             const json = await response.json();
// //             setData(json);
// //         } catch (error) {
// //             console.error('Error fetching data:', error);
// //         }
// //     };

// //     const getFileIcon = (driveLink) => {
// //         const fileExtension = driveLink.split('.').pop().toLowerCase();

// //         if (fileExtension === 'pdf') {
// //             return <SiGoogledrive className="w-6 h-6 text-red-500" />;
// //         } else if (fileExtension === 'doc' || fileExtension === 'docx') {
// //             return <SiGoogledrive className="w-6 h-6 text-blue-500" />;
// //         } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
// //             return <SiGoogledrive className="w-6 h-6 text-green-500" />;
// //         } else {
// //             return <SiGoogledrive className="w-6 h-6 text-gray-500" />;
// //         }
// //     };

// //     const downloadFile = (driveLink) => {
// //         window.open(driveLink, '_blank');
// //     };

// //     return (
// //         <div>
// //             <Header />
// //             <div className="container mx-auto min-h-screen p-16">

// //             <nav className="flex justify-center">
// //             {/* other navbar content */}
// //             <form onSubmit={handleSearchSubmit} className="flex items-center">
// //               <SearchIcon className="h-4 w-4 text-gray-500" />
// //               <input
// //                 type="text"
// //                 placeholder="C++ Tower of Hanoai?"
// //                 value={searchTerm}
// //                 onChange={handleSearchChange}
// //                 className="ml-2 pl-3 pr-14 py-1 rounded border border-gray-300"
// //               />
// //             </form>
// //           </nav>

// //                 <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mt-32">
// //                     {data.map((item) => (
// //                         <div key={item.id} className="border p-4 rounded-lg shadow relative">
// //                             <div className="flex flex-col"> {/* Flex container */}
// //                                 <div className="h-20p relative -mt-28"> {/* Divider one - 15% height */}
// //                                     <div className="aspect-w-1 aspect-h-1">
// //                                         <img
// //                                             src={item.image}
// //                                             alt="Sample Image"
// //                                             className="object-contain h-auto w-1/2"
// //                                         />
// //                                     </div>
// //                                 </div>

// //                                 <div className="h-80p"> {/* Divider two - 85% height */}
// //                                     <h2 className="text-l font-bold mb-2">{item.title}</h2>
// //                                     <p className="mb-4 text-sm">{item.description}</p>
// //                                     {item.driveLink && (
// //                                         <div className="flex items-center">
// //                                             <div className="mr-2">{getFileIcon(item.driveLink)}</div>
// //                                             <button
// //                                                 className="flex items-center text-blue-500"
// //                                                 onClick={() => downloadFile(item.driveLink)}
// //                                             >
// //                                                 <HiOutlineDownload className="w-4 h-4 mr-1" />
// //                                                 Download
// //                                             </button>
// //                                         </div>
// //                                     )}
// //                                 </div>
// //                             </div>


// //                         </div>
// //                     ))}
// //                 </div>
// //             </div>
// //             <Footer />
// //         </div>
// //     );
// // };

// // export default EmployeeData;


// import React, { useState, useEffect } from 'react';
// import { HiOutlineDownload } from 'react-icons/hi';
// import { SiGoogledrive } from 'react-icons/si';
// import Header from '../partials/Header';
// import Footer from '../partials/Footer';
// import { SearchIcon } from '@heroicons/react/solid';
// import { css } from '@emotion/react';
// import { RingLoader } from 'react-spinners';

// const EmployeeData = () => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [loading, setLoading] = useState(true);
//   const [data, setData] = useState([]);

//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//   };

//   const handleSearchSubmit = (event) => {
//     event.preventDefault();
//     fetchData();
//   };

//   useEffect(() => {
//     fetchData();
//   }, [searchTerm]);

//   const fetchData = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(
//         `https://gigadevden.com/ali.php?search=${encodeURIComponent(searchTerm)}`
//       );
//       const json = await response.json();
//       setData(json);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       setLoading(false);
//     }
//   };

//   const getFileIcon = (driveLink) => {
//     const fileExtension = driveLink.split('.').pop().toLowerCase();

//     if (fileExtension === 'pdf') {
//       return <SiGoogledrive className="w-6 h-6 text-red-500" />;
//     } else if (fileExtension === 'doc' || fileExtension === 'docx') {
//       return <SiGoogledrive className="w-6 h-6 text-blue-500" />;
//     } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
//       return <SiGoogledrive className="w-6 h-6 text-green-500" />;
//     } else {
//       return <SiGoogledrive className="w-6 h-6 text-gray-500" />;
//     }
//   };

//   const downloadFile = (driveLink) => {
//     window.open(driveLink, '_blank');
//   };

//   const override = css`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100vh;
//   `;

//   return (
//     <div>
//       <Header />
//       <div className="container mx-auto min-h-screen p-16">
//         <nav className="flex justify-center">
//           {/* other navbar content */}
//           <form onSubmit={handleSearchSubmit} className="flex items-center">
//             <SearchIcon className="h-4 w-4 text-gray-500" />
//             <input
//               type="text"
//               placeholder="C++ Tower of Hanoai?"
//               value={searchTerm}
//               onChange={handleSearchChange}
//               className="ml-2 pl-3 pr-14 py-1 rounded border border-gray-300"
//             />
//           </form>
//         </nav>
//         {loading ? (
//           <RingLoader color="#4a4a4a" loading={loading} css={override} size={150} />
//         ) : (
//           <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mt-32">
//             {data.map((item) => (
//               <div key={item.id} className="border p-4 rounded-lg shadow relative">
//                 <div className="flex flex-col">
//                   {/* Flex container */}
//                   <div className="h-20p relative -mt-28">
//                     {/* Divider one - 15% height */}
//                     <div className="aspect-w-1 aspect-h-1">
//                       <img
//                         src={item.image}
//                         alt="Sample Image"
//                         className="object-contain h-auto w-1/2"
//                       />
//                     </div>
//                   </div>

//                   <div className="h-80p">
//                     {/* Divider two - 85% height */}
//                     <h2 className="text-l font-bold mb-2">{item.title}</h2>
//                     <p className="mb-4 text-sm">{item.description}</p>
//                     {item.driveLink && (
//                       <div className="flex items-center">
//                         <div className="mr-2">{getFileIcon(item.driveLink)}</div>
//                         <button
//                           className="flex items-center text-blue-500"
//                           onClick={() => downloadFile(item.driveLink)}
//                         >
//                           <HiOutlineDownload className="w-4 h-4 mr-1" />
//                           Download
//                         </button>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default EmployeeData;

import React, { useState, useEffect } from 'react';
import { HiOutlineDownload } from 'react-icons/hi';
import { SiGoogledrive } from 'react-icons/si';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import Pagination from '../partials/Pagination';
import { SearchIcon } from '@heroicons/react/solid';
import { id } from 'date-fns/locale';
import { FaArrowRight, FaChessKing, FaCloudDownloadAlt, FaDownload, FaRegEye } from 'react-icons/fa';
import moment from 'moment';
import { Markup } from 'interweave';
import ReactQuill from 'react-quill';
import AliRubass from '../images/alirubass.jpeg';
import dotden from '../images/dotden.png'
import AiBackground from '../images/ai_background.jpg'

import background from '../gif/video.gif'

const EmployeeData = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    fetchData();
  };



  // Total number of pages
  const [totalPages, setTotalPages] = useState(1);
  const [offset, setOffset] = useState(0);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://gigadevden.com/webprojects.php?search=${encodeURIComponent(searchTerm)}&offset=${encodeURIComponent(offset)}&category=${encodeURIComponent(category)}&sortby=${encodeURIComponent(sortby)}&price=${encodeURIComponent(price)}`
      );
      const json = await response.json();

      if (Object.keys(json).length === 0) {
        setTotalPages(0);
      }
      else {

        if (json[0].totalrows % 12 === 0) {
          setTotalPages((json[0].totalrows) / 12);
        }
        else {
          setTotalPages(Math.floor(json[0].totalrows / 12) + 1);
        }

        setData(json);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const getFileIcon = (driveLink) => {
    const fileExtension = driveLink.split('.').pop().toLowerCase();

    if (fileExtension === 'pdf') {
      return <SiGoogledrive className="w-6 h-6 text-red-500" />;
    } else if (fileExtension === 'doc' || fileExtension === 'docx') {
      return <SiGoogledrive className="w-6 h-6 text-blue-500" />;
    } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
      return <SiGoogledrive className="w-6 h-6 text-green-500" />;
    } else {
      return <SiGoogledrive className="w-6 h-6 text-gray-500" />;
    }
  };

  const downloadFile = (driveLink) => {
    window.open(driveLink, '_blank');
  };

  const [currentPage, setCurrentPage] = useState(1);



  const handlePageChange = (page) => {

    if (page >= 1 && page <= totalPages) {
      setOffset((page - 1) * 12);
      setCurrentPage(page);
    }

    // Perform any other actions or fetch data based on the new page
  };

  const websitePrefix = 'www.gigadevden.com';

  function setCurrentProjectId(id, title) {
    const formattedTitle = title
      .toLowerCase() // Convert title to lowercase
      .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
      .trim()
      .replace(/\s+/g, '-'); // Replace spaces with dashes
    window.location.href = '/projects/' + id + '/' + formattedTitle;
  }



  // Filters
  const [showFilters, setShowfilters] = useState(false);

  const [check, setCheck] = useState({
    "All": true,
    "Programming/Software Development": false,
    "Art/Creative Design": false,
    "Business/Entrepreneurship": false,
    "Engineering/Technology": false,
    "Science/Research": false,
    "Education": false,
    "Health/Medicine": false,
    "Social Sciences": false,
    "Environment/Sustainability": false,
    "Gaming": false,
    "Entertainment": false,
    "Other": false,
  });

  const { "All": All, "Programming/Software Development": Programming, "Art/Creative Design": Art, "Business/Entrepreneurship": Business, "Engineering/Technology": Engineering, "Science/Research": Science, "Education": Education, "Health/Medicine": Health, "Social Sciences": Social, "Environment/Sustainability": Environment, "Gaming": Gaming, "Entertainment": Entertainment, "Other": Other } = check;



  const [category, setCategory] = useState('All');





  const [selectedButton, setSelectedButton] = useState("All");


  const changeHandler = (e) => {
    const { name } = e.target;
    setSelectedButton(e.target.name);
    setCategory(name);

  };




  const applyFilters = (e) => {
    setCheck({
      ...check,
      "All": All,
      "Programming/Software Development": false,
      "Art/Creative Design": false,
      "Business/Entrepreneurship": false,
      "Engineering/Technology": false,
      "Science/Research": false,
      "Education": false,
      "Health/Medicine": false,
      "Social Sciences": false,
      "Environment/Sustainability": false,
      "Gaming": false,
      "Entertainment": false,
      "Other": false,
    });
  };



  const [check2, setCheck2] = useState({
    "": true,
    "Upload date: New to Old": false,
    "Upload date: Old to New": false,
  });

  const { "": Relevance, "Upload date: New to Old": New, "Upload date: Old to New": Old } = check2;

  const [sortby, setSortby] = useState('');

  const changeHandler2 = (e) => {
    const { name, checked } = e.target;

    // If the checkbox is being checked, set its value to true and set all other checkboxes to false
    if (checked) {
      setCheck2((prevState) => ({
        ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])),

        [name]: true,
      }));

      setSortby(name);
    }
    // else {
    //   // If the checkbox is being unchecked, set its value to false
    //   setCheck3((prevState) => ({
    //     ...prevState,
    //     [name]: false,
    //   }));

    //   setPrice('');
    // }
  };


  const applyFilters2 = (e) => {
    setCheck({
      ...check2,
      "": true,
      "Upload date: New to Old": false,
      "Upload date: Old to New": false,
    });
  };



  const [check3, setCheck3] = useState({
    "": true,
    "Price: Low to High": false,
    "Price: High to Low": false,
    "Free": false,
  });

  const { "": All3, "Price: Low to High": Low, "Price: High to Low": High, "Free": Free } = check3;

  const [price, setPrice] = useState('');

  const changeHandler3 = (e) => {
    const { name, checked } = e.target;

    // If the checkbox is being checked, set its value to true and set all other checkboxes to false
    if (checked) {
      setCheck3((prevState) => ({
        ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])),

        [name]: true,
      }));

      setPrice(name);
    }
    // else {
    //   // If the checkbox is being unchecked, set its value to false
    //   setCheck3((prevState) => ({
    //     ...prevState,
    //     [name]: false,
    //   }));

    //   setPrice('');
    // }
  };


  const applyFilters3 = (e) => {
    setCheck({
      ...check3,
      "": false,
      "Price: Low to High": false,
      "Price: High to Low": false,
      "Free": false,
    });
  };


  // useEffect(() => {
  //   console.log("Category: " + category);
  //   console.log("Price: " + price);
  //   console.log("Sort by: " + sortby);
  // }, [category, price, sortby]);



  const apply = (e) => {
    fetchData();
  };



  // const getTimeDifference = (timestamp, time) => {
  //   const currentTime = moment(timestamp);
  //   const oldTime = moment(time);
  //   const duration = moment.duration(currentTime.diff(oldTime));

  //   if (duration.asMinutes() < 60) {
  //     return `${Math.round(duration.asMinutes())} min ago`;
  //   } else if (duration.asHours() < 24) {
  //     return `${Math.round(duration.asHours())} hour ago`;
  //   } else if (duration.asDays() < 7) {
  //     return `${Math.round(duration.asDays())}d ago`;
  //   } else if (duration.asMonths() < 1) {
  //     return `${Math.round(duration.asWeeks())}w ago`;
  //   } else if (duration.asYears() < 1) {
  //     return `${Math.round(duration.asMonths())} month ago`;
  //   } else {
  //     return `${Math.round(duration.asYears())} year ago`;
  //   }
  // };

  const getTimeDifference = (timestamp, time) => {
    const currentTime = moment.utc(timestamp);
    const oldTime = moment.utc(time, "YYYY-MM-DD HH:mm:ss");
    const duration = moment.duration(currentTime.diff(oldTime));

    if (duration.asMinutes() < 60) {
      return `${Math.round(duration.asMinutes())} min ago`;
    } else if (duration.asHours() < 24) {
      return `${Math.round(duration.asHours())} hour ago`;
    } else if (duration.asDays() < 7) {
      return `${Math.round(duration.asDays())}d ago`;
    } else if (duration.asMonths() < 1) {
      return `${Math.round(duration.asWeeks())}w ago`;
    } else if (duration.asYears() < 1) {
      return `${Math.round(duration.asMonths())} month ago`;
    } else {
      return `${Math.round(duration.asYears())} year ago`;
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm, offset, category]);




  return (
    // <div className="bg-black font-inter antialiased bg-black text-gray-200 tracking-tight">
    <div className="bg-gray-950 font-medium">
      <Header />

      <div className="relative w-full opacity-20 rounded pointer-events-none z-10">
        <div className="relative">
          {/* <video className="w-full" controls autoPlay muted loop>
                        <source src={background} type="video/mp4" />
                    </video> */}
          <img src={background} className="w-full" />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-950 via-transparent to-transparent opacity-200"></div>

          <div className="absolute inset-0 bg-gradient-to-t from-gray-950 via-transparent to-transparent opacity-200"></div>

        </div>
      </div>

      <div className="container mx-auto min-h-screen px-4 lg:px-16 xl:px-24 -mt-[55%] ">








        {/* Filters */}

        <div className="2xl:container">
          <div className=" md:py-12 lg:px-20 md:px-6 py-4">







            {/* <p className=" text-sm leading-3 text-gray-600 font-normal mb-4 md:mb-2">Home - Content - Projects - Filters</p> */}

            <div class="hidden md:flex space-x-4 text-gray-400 mb-8 md:mb-4">
              <span>Home</span>
              <svg aria-hidden="true" width="24" height="24" fill="none" class="flex-none text-gray-400">
                <path d="M10.75 8.75l3.5 3.25-3.5 3.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              <span class="block w-max text-white border-b-2 border-cyan-400">Projects</span>
            </div>
            <div className=" flex justify-between items-center mb-4">
              <h2 className=" lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-200 font-semibold hidden md:block">Projects</h2>

              {/*  filters Button (md and plus Screen) */}
              <button onClick={() => setShowfilters(!showFilters)} className=" cursor-pointer sm:flex hidden hover:bg-gray-700 focus:ring focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-4 px-6 bg-gray-800 rounded-full flex text-base leading-4 font-normal text-white justify-center items-center ">
                <svg className=" mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 12C7.10457 12 8 11.1046 8 10C8 8.89543 7.10457 8 6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6 4V8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6 12V20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 4V14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 18V20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M18 9C19.1046 9 20 8.10457 20 7C20 5.89543 19.1046 5 18 5C16.8954 5 16 5.89543 16 7C16 8.10457 16.8954 9 18 9Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M18 4V5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M18 9V20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Filters
              </button>
            </div>
            <p className="hidden md:block text-sm leading-5 text-gray-400 font-medium">
              {data[0]?.totalrows ? (
                data[0].totalrows < 10 ? '0' + data[0].totalrows.toString() : data[0].totalrows.toString()
              ) : null} Result<b className="text-sm">(s)</b></p>

            {/* Filters Button (Small Screen)  */}

            <button onClick={() => setShowfilters(!showFilters)} className="cursor-pointer mt-6 block sm:hidden hover:bg-gray-700 focus:ring focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-2 w-full bg-gray-800 flex text-base leading-4 rounded font-normal text-white justify-center items-center">
              <svg className=" mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 12C7.10457 12 8 11.1046 8 10C8 8.89543 7.10457 8 6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 4V8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 12V20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 4V14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 18V20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18 9C19.1046 9 20 8.10457 20 7C20 5.89543 19.1046 5 18 5C16.8954 5 16 5.89543 16 7C16 8.10457 16.8954 9 18 9Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18 4V5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18 9V20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Filters
            </button>
          </div>

          <div id="filterSection" className={"relative md:py-10 lg:px-20 md:px-6 py-9 px-4 bg-gray-50 rounded w-full " + (showFilters ? "block" : "hidden")}>
            {/* Cross button Code  */}
            <div onClick={() => setShowfilters(false)} className=" cursor-pointer absolute right-0 top-0 md:py-10 lg:px-20 md:px-6 py-9 px-4">
              <svg className=" lg:w-6 lg:h-6 w-4 h-4" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25 1L1 25" stroke="#1F2937" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1 1L25 25" stroke="#27272A" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>








            <div>
              <div className=" flex space-x-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 5H14" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 7L14 5L12 3" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 3L3 5L5 7" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M19 10V21" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M17 19L19 21L21 19" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M21 12L19 10L17 12" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 10H5C3.89543 10 3 10.8954 3 12V19C3 20.1046 3.89543 21 5 21H12C13.1046 21 14 20.1046 14 19V12C14 10.8954 13.1046 10 12 10Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="  lg:text-2xl text-xl lg:leading-6 leading-5 font-medium text-gray-800 ">Order by</p>
              </div>
              <div className=" md:flex md:space-x-6 mt-8 grid grid-cols-3 gap-y-8 flex-wrap">
                <div className=" flex md:justify-center md:items-center items-center justify-start ">
                  <input className="w-4 h-4 mr-2" type="checkbox" id="" name="" value="" checked={Relevance} onChange={changeHandler2} />
                  <div className=" inline-block">
                    <div className=" flex space-x-6 justify-center items-center">
                      <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Relevance">
                        Relevance
                      </label>
                    </div>
                  </div>
                </div>
                <div className=" flex justify-center items-center ">
                  <input className="w-4 h-4 mr-2" type="checkbox" id="Upload date: New to Old" name="Upload date: New to Old" value="Upload date: New to Old" checked={New} onChange={changeHandler2} />
                  <div className=" inline-block">
                    <div className=" flex space-x-6 justify-center items-center">
                      <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Upload date: New to Old">
                        New to Old
                      </label>
                    </div>
                  </div>
                </div>
                <div className=" flex md:justify-center md:items-center items-center justify-end ">
                  <input className="w-4 h-4 mr-2" type="checkbox" id="Upload date: Old to New" name="Upload date: Old to New" value="Upload date: Old to New" checked={Old} onChange={changeHandler2} />
                  <div className=" inline-block">
                    <div className=" flex space-x-6 justify-center items-center">
                      <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Upload date: Old to New">
                        Old to New
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr className=" bg-gray-200 lg:w-6/12 w-full md:my-10 my-8" />



            {/* Collection Section */}

            <div>
              <div className=" flex space-x-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.8">
                    <path d="M9 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H9C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9 14H5C4.44772 14 4 14.4477 4 15V19C4 19.5523 4.44772 20 5 20H9C9.55228 20 10 19.5523 10 19V15C10 14.4477 9.55228 14 9 14Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M19 14H15C14.4477 14 14 14.4477 14 15V19C14 19.5523 14.4477 20 15 20H19C19.5523 20 20 19.5523 20 19V15C20 14.4477 19.5523 14 19 14Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 7H20" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17 4V10" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                </svg>
                <p className=" lg:text-2xl text-xl lg:leading-6 leading-5 font-medium text-gray-800 ">Collection</p>
              </div>
              <div className=" flex mt-8 space-x-7">
                <div className=" flex justify-center items-center">
                  <input className="w-4 h-4 mr-2" type="checkbox" id="" name="" checked={All3} onChange={changeHandler3} value="" />
                  <div className=" inline-block">
                    <div className=" flex space-x-6 justify-center items-center">
                      <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="All">
                        All
                      </label>
                    </div>
                  </div>
                </div>
                <div className=" flex justify-center items-center">
                  <input className="w-4 h-4 mr-2" type="checkbox" id="Free" name="Free" value="Free" checked={Free} onChange={changeHandler3} />
                  <div className=" inline-block">
                    <div className=" flex space-x-6 justify-center items-center">
                      <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Free">
                        Free
                      </label>
                    </div>
                  </div>
                </div>
                <div className=" flex justify-center items-center">
                  <input className="w-4 h-4 mr-2" type="checkbox" id="Price: Low to High" name="Price: Low to High" checked={Low} onChange={changeHandler3} value="Price: Low to High" />
                  <div className=" inline-block">
                    <div className=" flex space-x-6 justify-center items-center">
                      <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Price: Low to High">
                        Price ↑
                      </label>
                    </div>
                  </div>
                </div>
                <div className=" flex justify-center items-center">
                  <input className="w-4 h-4 mr-2" type="checkbox" id="Price: High to Low" name="Price: High to Low" checked={High} onChange={changeHandler3} value="Price: High to Low" />
                  <div className=" inline-block">
                    <div className=" flex space-x-6 justify-center items-center">
                      <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Price: High to Low">
                        Price ↓
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-0 mt-10 w-full md:w-auto md:mt-0 md:absolute md:right-0 md:bottom-0 md:py-10 lg:px-20 md:px-6">
              <button onClick={() => { setShowfilters(!showFilters); apply(); }} className="rounded w-full hover:bg-gray-700 focus:ring focus:ring-offset-2 focus:ring-gray-800 text-base leading-4 font-medium py-4 px-10 text-white bg-gray-800">
                Apply Filter
              </button>
            </div>
          </div>
        </div>





        <div class="md:flex md:items-center md:justify-between md:space-x-8 mb-16 md:mb-20">


          <div id="journal-scroll" className="text-sm scrollable-container flex items-center space-x-4 overflow-y-auto whitespace-nowrap">
            <button
              name="All"
              onClick={changeHandler}
              className={`duration-300 px-3 py-1.5 hover:bg-gray-100 rounded-lg capitalize ${selectedButton === "All" ? "text-gray-900 bg-gray-100" : "text-gray-400"
                }`}
            >
              All
            </button>
            <button
              title="Tailwind Grid"
              name="Programming/Software Development"
              onClick={changeHandler}
              className={`duration-300 px-3 py-1.5 capitalize hover:bg-gray-100 rounded-lg ${selectedButton === "Programming/Software Development" ? "text-gray-900 bg-gray-100" : "text-gray-400"
                }`}
            >
              Programming / Software Development
            </button>
            <button
              title="Tailwind Menu"
              name="Art/Creative Design"
              onClick={changeHandler}
              className={`duration-300 px-3 py-1.5 capitalize hover:bg-gray-100 rounded-lg ${selectedButton === "Art/Creative Design" ? "text-gray-900 bg-gray-100" : "text-gray-400"
                }`}
            >
              Art / Creative Design
            </button>
            <button
              title="Tailwind Tabs"
              name="Business/Entrepreneurship"
              onClick={changeHandler}
              className={`duration-300 px-3 py-1.5 capitalize hover:bg-gray-100 rounded-lg ${selectedButton === "Business/Entrepreneurship" ? "text-gray-900 bg-gray-100" : "text-gray-400"
                }`}
            >
              Business / Entrepreneurship
            </button>
            <button
              title="Tailwind Images"
              name="Engineering/Technology"
              onClick={changeHandler}
              className={`duration-300 px-3 py-1.5 rounded-lg capitalize hover:bg-gray-100 ${selectedButton === "Engineering/Technology" ? "text-gray-900 bg-gray-100" : "text-gray-400"
                }`}
            >
              Engineering / Technology
            </button>
            <button
              title="Tailwind Tables"
              name="Science/Research"
              onClick={changeHandler}
              className={`duration-300 px-3 py-1.5 rounded-lg capitalize hover:bg-gray-100 ${selectedButton === "Science/Research" ? "text-gray-900 bg-gray-100" : "text-gray-400"
                }`}
            >
              Science / Research
            </button>
            <button
              title="Tailwind Modals"
              name="Education"
              onClick={changeHandler}
              className={`duration-300 px-3 py-1.5 capitalize hover:bg-gray-100 rounded-lg ${selectedButton === "Education" ? "text-gray-900 bg-gray-100" : "text-gray-400"
                }`}
            >
              Education
            </button>
            <button
              title="Tailwind Badges"
              name="Health/Medicine"
              onClick={changeHandler}
              className={`duration-300 px-3 py-1.5 capitalize hover:bg-gray-100 rounded-lg ${selectedButton === "Health/Medicine" ? "text-gray-900 bg-gray-100" : "text-gray-400"
                }`}
            >
              Health / Medicine
            </button>
            <button
              title="Tailwind Widget"
              name="Social Sciences"
              onClick={changeHandler}
              className={`duration-300 px-3 py-1.5 capitalize hover:bg-gray-100 rounded-lg ${selectedButton === "Social Sciences" ? "text-gray-900 bg-gray-100" : "text-gray-400"
                }`}
            >
              Social Sciences
            </button>
            <button
              title="Tailwind Alerts"
              name="Environment/Sustainability"
              onClick={changeHandler}
              className={`duration-300 px-3 py-1.5 capitalize hover:bg-gray-100 rounded-lg ${selectedButton === "Environment/Sustainability" ? "text-gray-900 bg-gray-100" : "text-gray-400"
                }`}
            >
              Environment / Sustainability
            </button>
            <button
              title="Tailwind Logins"
              name="Gaming"
              onClick={changeHandler}
              className={`duration-300 px-3 py-1.5 capitalize hover:bg-gray-100 rounded-lg ${selectedButton === "Gaming" ? "text-gray-900 bg-gray-100" : "text-gray-400"
                }`}
            >
              Gaming
            </button>
            <button
              title="Tailwind Inputs"
              name="Entertainment"
              onClick={changeHandler}
              className={`duration-300 px-3 py-1.5 capitalize hover:bg-gray-100 rounded-lg ${selectedButton === "Entertainment" ? "text-gray-900 bg-gray-100" : "text-gray-400"
                }`}
            >
              Entertainment
            </button>
            <button
              title="Tailwind Cursor"
              name="Other"
              onClick={changeHandler}
              className={`duration-300 px-3 py-1.5 capitalize hover:bg-gray-100 rounded-lg ${selectedButton === "Other" ? "text-gray-900 bg-gray-100" : "text-gray-400"
                }`}
            >
              Other
            </button>
          </div>

          <nav className="flex mt-8 md:mt-0">
            {/* other navbar content */}
            <form onSubmit={handleSearchSubmit} className="flex items-center">
              <SearchIcon className="h-4 w-4 text-gray-500" />
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className="ml-2 pl-3 pr-14 py-1 rounded border border-gray-300"
              />
            </form>
          </nav>

          {/* 
<div className="relative inline-block w-auto mb-16 mt-8">
    <div>
        <button
            type="button"
            className="flex items-center justify-center px-4 py-2 mt-4 space-x-3 text-gray-600 transition-colors duration-300 transform border rounded-lg md:mt-0 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-primary dark:focus:border-primary focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-20"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 h-4"
            >
                <path
                    fillRule="evenodd"
                    d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                    clipRule="evenodd"
                ></path>
            </svg>
            <span>All version</span>
        </button>
    </div>
    <div className="absolute left-0 z-20 py-1 mt-2 bg-yellow-500 border border-gray-100 rounded-md shadow-xl xl:left-auto xl:right-0" style={{ display: "none" }}>
        <div className="w-32">
            <a href="https://tailwindcomponents.com/components/grid" className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-300 transform hover:text-primary">
                All version
            </a>
            <a href="https://tailwindcomponents.com/components/grid?version=3" className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-300 transform hover:text-primary">
                version 3
            </a>
            <a href="https://tailwindcomponents.com/components/grid?version=2" className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-300 transform hover:text-primary">
                version 2
            </a>
            <a href="https://tailwindcomponents.com/components/grid?version=1" className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-300 transform hover:text-primary">
                version 1
            </a>
            <a href="https://tailwindcomponents.com/components/grid?version=0" className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-300 transform hover:text-primary">
                Beta version
            </a>
            <div id="extwaiokist" style={{ display: "none" }} v="dbhdf" q="36f0aeaa" c="53.89" i="64" u="12.90" s="06182305" sg="svr_undefined-ga_06182305-bai_06032321" d="1" w="false" e="" a="2" m="BMe=" vn="9zsmd">
                <div id="extwaigglbit" style={{ display: "none" }} v="dbhdf" q="36f0aeaa" c="53.89" i="64" u="12.90" s="06182305" sg="svr_undefined-ga_06182305-bai_06032321" d="1" w="false" e="" a="2" m="BMe="></div>
            </div>
        </div>
    </div>
</div> */}


        </div>

        <style>
          {`
#journal-scroll {
height: 75px;
overflow-y: auto;
}

#journal-scroll::-webkit-scrollbar {
height: 2px;
width: 16px;
}

#journal-scroll::-webkit-scrollbar-thumb {
background: white;
border-radius: 41px;
}

#journal-scroll:hover::-webkit-scrollbar-thumb {
background: ; /* Change the scrollbar color to red on hover */
}

#journal-scroll::-webkit-scrollbar-track {
background: transparent;
}
`}
        </style>




    

    <div className="flex flex-row justify-between lg:mb-24">



                    <div className="lg:flex-col hidden lg:flex">
                        <div class="space-y-4 mb-8">
                            <h2 class="text-2xl font-semibold text-white md:text-4xl">
                                Search Precisely
                                <span class="text-white"> AI Assistance</span>
                            </h2>
                            <p className="block antialiased font-sans mb-6 text-md font-light text-gray-400 lg:pr-12">
                                Looking to find what you need? Ask
                                <b className="text-white text-lg font-poppins">
                                    <b className="text-red-600"> .</b>
                                    den</b>
                                , our AI-powered chatbot, for assistance!
                            </p>


                            <div class="gap-6 flex">
                                <a href="/chat.den.ai" title="customize" aria-label="customize" class="w-max relative flex gap-2 h-9 items-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-gray-900 before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95">
                                    <svg class="relative w-4" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path class="fill-white" opacity="0.3" d="M6 14C6 13.45 5.55 13 5 13C4.45 13 4 13.45 4 14C4 14.74 3.81 15.4 3.5 15.95C3.67 15.98 3.83 16 4 16C5.1 16 6 15.1 6 14Z" fill="currentColor"></path>
                                        <path class="fill-white" d="M9.75 12L18.71.55 13 6 13.45 6 14C6 15.1 5.1 16 4 16C3.83 16 3.67 15.98 3.5 15.95C3.81 15.4 4 14.74 4 14Z" fill="currentColor"></path>
                                    </svg>
                                    <span class="w-max relative text-sm font-semibold text-white">
                                        AI ChatBot
                                    </span>
                                </a>
                                <button class="block-switcher group relative flex h-9 w-9 rounded-full before:absolute before:inset-0 before:rounded-full before:border before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 before:border-gray-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="sun relative transistion m-auto h-5 w-5 duration-300 group-hover:rotate-180 fill-gray-300 group-hover:fill-yellow-400 hidden" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clip-rule="evenodd"></path>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="moon relative transistion m-auto h-5 w-5 duration-300 group-hover:-rotate-90 fill-gray-300 group-hover:fill-white block" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>




                        <div class="relative hide-with-banner mr-6 mt-auto"><a href="/console-home" target="_blank" class="flex flex-col w-full h-full py-12"><img src="https://i.pinimg.com/736x/40/fe/96/40fe967674ec495f73d6f40793e2bbdd.jpg" alt="" class="absolute bottom-0 left-0 object-cover w-full h-full overflow-hidden rounded-md opacity-50" /> <div class="z-10 flex-col items-start justify-center w-2/3 px-6 xl:px-8">
                            {/* <img src="/images/ap/tailwind-logo.png" alt="tailwind-logo" class="h-8"/> */}
                            <p class="mt-3 text-sm tracking-wide text-white">Are you a Developer?</p> <p class="mb-1 text-base font-bold tracking-wide text-white md:text-xl">Share your project with the community <br />on DevDen Console</p> <button class="px-5 py-2 mt-2 text-xs font-bold text-white rounded-lg shadow-md bg-gradient-to-tl from-purple-700 to-pink-500 uppercase">
                                Console
                            </button></div></a></div>

                    </div>





                    <div class="items-center justify-center font-poppins rounded hidden lg:flex">

                        {/* <!-- Card --> */}
                        <div class='flex items-center justify-center px-2'>
                          <div class='w-full max-w-md  mx-auto bg-white rounded-3xl shadow-xl overflow-hidden'>
                            <div class='max-w-md mx-auto'>
                              {/* <div class='h-[236px]' style={{
                                backgroundImage: 'url(https://img.freepik.com/free-photo/pasta-spaghetti-with-shrimps-sauce_1220-5072.jpg?w=2000&t=st=1678041911~exp=1678042511~hmac=e4aa55e70f8c231d4d23832a611004f86eeb3b6ca067b3fa0c374ac78fe7aba6)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                              }}
                              >
                              </div> */}
                              <div class='p-4 sm:p-6'>
                                <p class='font-bold text-gray-700 text-[22px] leading-7 mb-1'>GameDen Android Studio Base App</p>
                                <div class='flex flex-row'>
                                  <p class='text-[#3C3C4399] text-[17px] mr-2 line-through'>$ 154</p>
                                  <p class='text-[17px] font-bold text-[#0FB478]'>$ 5</p>
                                </div>
                                <p class='text-[#7C7C80] font-[15px] mt-6'>Android stucio project for sale (Java): MySQL Database</p>


                                <a target='_blank' href='' class='block mt-10 w-full px-4 py-3 font-medium tracking-wide text-center capitalize transition-colors duration-300 transform bg-[#FFC933] rounded-[14px] hover:bg-[#FFC933DD] focus:outline-none focus:ring focus:ring-teal-300 focus:ring-opacity-80'>
                                  View Project
                                </a>
                                <a target='_blank' href="/" class='block mt-1.5 w-full px-4 py-3 font-medium tracking-wide text-center capitalize transition-colors duration-300 transform rounded-[14px] hover:bg-[#F2ECE7] hover:text-[#000000dd] focus:outline-none focus:ring focus:ring-teal-300 focus:ring-opacity-80'>
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>


                    </div>


                </div>


                <div class='lg:hidden flex items-center justify-center px-2'>
                          <div class='w-full max-w-md  mx-auto bg-white rounded-3xl shadow-xl overflow-hidden'>
                            <div class='max-w-md mx-auto'>
                              {/* <div class='h-[236px]' style={{
                                backgroundImage: 'url(https://img.freepik.com/free-photo/pasta-spaghetti-with-shrimps-sauce_1220-5072.jpg?w=2000&t=st=1678041911~exp=1678042511~hmac=e4aa55e70f8c231d4d23832a611004f86eeb3b6ca067b3fa0c374ac78fe7aba6)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                              }}
                              >
                              </div> */}
                              <div class='p-4 sm:p-6'>
                                <p class='font-bold text-gray-700 text-[22px] leading-7 mb-1'>GameDen Android Studio Base App</p>
                                <div class='flex flex-row'>
                                  <p class='text-[#3C3C4399] text-[17px] mr-2 line-through'>$ 154</p>
                                  <p class='text-[17px] font-bold text-[#0FB478]'>$ 5</p>
                                </div>
                                <p class='text-[#7C7C80] font-[15px] mt-6'>Android stucio project for sale (Java): MySQL Database</p>


                                <a target='_blank' href='' class='block mt-10 w-full px-4 py-3 font-medium tracking-wide text-center capitalize transition-colors duration-300 transform bg-[#FFC933] rounded-[14px] hover:bg-[#FFC933DD] focus:outline-none focus:ring focus:ring-teal-300 focus:ring-opacity-80'>
                                  View Project
                                </a>
                                <a target='_blank' href="/" class='block mt-1.5 w-full px-4 py-3 font-medium tracking-wide text-center capitalize transition-colors duration-300 transform rounded-[14px] hover:bg-[#F2ECE7] hover:text-[#000000dd] focus:outline-none focus:ring focus:ring-teal-300 focus:ring-opacity-80'>
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>



        {loading ? (
          <div className="flex justify-center items-center h-screen">
            {/* <div className="w-8 h-8 border-2 border-t-2 border-gray-300 rounded-full animate-spin"></div> */}
            <>
              <div className="bg-transparent">
                <div className="px-4 py-12 bg-transparent">
                  <div className="mx-auto flex justify-center">
                    <div className="relative">
                      <div className="w-[160px] h-[160px] border border-sky-500 rounded-full" />
                      <div className="w-[140px] h-[140px] border border-sky-500 rounded-full absolute top-2.5 right-2.5" />
                      <div>
                        <svg
                          className="absolute top-[22px] right-[26px] animate-spin infinite"
                          width={113}
                          height={113}
                          viewBox="0 0 113 113"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M56.7631 110.374C46.061 110.374 35.5993 107.2 26.7008 101.255C17.8023 95.3088 10.8668 86.8579 6.77128 76.9704C2.67576 67.083 1.60419 56.2031 3.69207 45.7066C5.77994 35.2102 10.9335 25.5686 18.501 18.001C26.0686 10.4335 35.7102 5.27994 46.2066 3.19207C56.7031 1.10419 67.583 2.17576 77.4704 6.27128C87.3579 10.3668 95.8088 17.3023 101.755 26.2008C107.7 35.0993 110.874 45.561 110.874 56.2631"
                            stroke="#0ea5e9"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeDasharray="16 16"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  {/* <p className="text-center text-gray-600 text-base mt-4">
                Your Request Is Being Loaded, Please Wait
              </p> */}
                </div>
              </div>
            </>
          </div>



        ) : (
          <div className="grid grid-cols-1 gap-8 mt-4 md:mt-8 2xl:mx-16">
            {data.map((item, index) => (










              <div class="container mx-auto my-5 font-none z-20">

                <div class="relative rounded-lg flex flex-col md:flex-row items-center md:shadow-xl md:h-80 mx-2">

                  <div class="z-0 order-1 md:order-2 relative w-full md:w-2/5 h-80 md:h-full overflow-hidden rounded-lg md:rounded-none md:rounded-r-lg">
                    <div class="absolute inset-0 w-full h-full object-fill object-center bg-white bg-opacity-30 bg-cover bg-bottom"

                      style={{ backgroundImage: 'url(https://th.bing.com/th/id/R.615c76ccfe6b71c9027216abc5e3e8d9?rik=M2WnKZEDRnqO1g&pid=ImgRaw&r=0)', backgroundBlendMode: '' }}
                    >
                      <img
                        src={`https://www.gigadevden.com${item.image}`}
                        alt="Sample Image"
                        className="object-cover h-full w-full bg-blend-lighten"
                      />
                    </div>
                    <div class="md:hidden absolute inset-0 h-full p-6 pb-6 flex flex-col-reverse justify-start items-start bg-gradient-to-b from-transparent via-transparent to-gray-900">
                      <h3 onClick={() => setCurrentProjectId(item.id, item.title)}
                        class="w-full font-bold text-lg text-white leading-tight mb-4">{item.title}</h3>
                      {/* <h4 class="w-full text-xl text-gray-100 leading-tight">Bienvenido a</h4> */}
                    </div>
                    <svg class="hidden md:block absolute inset-y-0 h-full w-24 fill-current text-white -ml-12" viewBox="0 0 100 100" preserveAspectRatio="none">
                      <polygon points="50,0 100,0 50,100 0,100" />
                    </svg>

                    <div className="absolute top-1 mt-2 w-full h-6 pl-6 block md:hidden">

                      <div className="flex flex-row justify-end mr-4">



                        <div className="bg-blue-500 text-white p-2 rounded-full">
                          <p className="text-xs font-bold text-white">{getTimeDifference(item.timestamp, item.time)}</p>
                        </div>

                      </div>

                    </div>


                  </div>

                  <div class="z-10 order-2 md:order-1 w-full h-full md:w-3/5 flex items-center -mt-6 md:mt-0 relative">
                    <div class="p-8 md:pr-18 md:pl-14 md:py-12 mx-2 md:mx-0 h-full bg-white rounded-lg md:rounded-none md:rounded-l-lg shadow-xl md:shadow-none">

                      <div className="flex items-center space-x-4 mb-4">
                        <button
                          onClick={() => {
                            const formattedName = data[0].devname
                              .toLowerCase()
                              .replace(/[^a-zA-Z0-9\s]/g, ' ')
                              .trim()
                              .replace(/\s+/g, '-');
                            window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
                          }}
                          className="flex-shrink-0 w-10 h-10 overflow-hidden rounded-full shadow-md z-20"
                        >
                          <img
                            src={`https://www.gigadevden.com${item.devimage}`}
                            alt={item.devname}
                            className="bg-white object-cover w-full h-full"
                          />
                        </button>
                        <div className="flex flex-col space-y-1 z-10">
                          <button
                            onClick={() => {
                              const formattedName = data[0].devname
                                .toLowerCase()
                                .replace(/[^a-zA-Z0-9\s]/g, ' ')
                                .trim()
                                .replace(/\s+/g, '-');
                              window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
                            }}
                            className="text-sm text-gray-950 border-b-2 border-gray-950 pb-1"
                          >
                            {item.devname}
                          </button>
                        </div>
                      </div>
                      <h3 onClick={() => setCurrentProjectId(item.id, item.title)}
                        class="hidden md:block font-bold text-lg text-gray-700 cursor-pointer">{item.title}</h3>
                      <p onClick={() => setCurrentProjectId(item.id, item.title)}
                        class="text-gray-600 text-justify text-sm">  {
                          ((<Markup content={item.description.replace(/\\/g, '')} />).props.content).length > 242
                            ? <Markup content={((<Markup content={item.description.replace(/\\/g, '')} />).props.content).substring(0, 242).split(' ').slice(0, -1).join(' ') + ' ...'} />
                            : <Markup content={item.description.replace(/\\/g, '')} />
                        }</p>
                      <button class="flex items-baseline mt-3 text-blue-600 hover:text-blue-900 focus:text-blue-900" onClick={() => setCurrentProjectId(item.id, item.title)}>
                        <span>Download</span>
                        <span class="text-xs ml-1">&#x279c;</span>
                      </button>
                    </div>

                    <div className="absolute top-1 mt-2 w-full h-6 pl-6">

                      <div className="flex flex-row justify-between">

                        <div className="flex items-center text-xs text-gray-500">
                          <div className="flex items-center mr-4">
                            <FaCloudDownloadAlt />
                            <p className="ml-2">{item.downloads} downloads</p>
                          </div>

                          <div className="flex items-center">
                            <FaRegEye />
                            <p className="ml-2">{item.views} views</p>
                          </div>
                        </div>

                        <div className="bg-blue-500 text-white p-2 rounded-full hidden md:block">
                          <p className="text-xs font-bold text-white">{getTimeDifference(item.timestamp, item.time)}</p>
                        </div>

                      </div>

                    </div>



                  </div>




                </div>
              </div>








            ))}
          </div>
        )}
      </div>

      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />

      <Footer />
    </div>
  );
};

export default EmployeeData;