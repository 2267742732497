import React, { useState, useRef, useEffect } from 'react';
import { SearchIcon } from '@heroicons/react/solid';

const Filters = () => {

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };
  
    const handleSearchSubmit = (event) => {
      event.preventDefault();
      // handle search submit here
      window.location.href = "/error";
    };

  return (
    <div className="flex flex-wrap right-0 items-center p-4 bg-gray-100">
      <div className="w-full sm:w-auto mb-2 ml-5">
        <label htmlFor="filter" className="mr-2">
          Filter:
        </label >
        <select id="filter" className="border rounded-lg p-1 bg-black text-white">
          <option value="">All</option>
          <option value="electronics">Electronics</option>
          <option value="fashion">Fashion</option>
          <option value="home">Home</option>
        </select>
      </div>
      <div className="w-full sm:w-auto mb-2 ml-5">
        <label htmlFor="country" className="mr-2">
          Country:
        </label>
        <select id="country" className="border rounded p-1">
          <option value="">All</option>
          {/* <option value="us"></option>
          <option value="uk"></option>
          <option value="ca"></option> */}
        </select>
      </div>
      <div className="w-full sm:w-auto mb-2 ml-5">
        <label htmlFor="sort" className="mr-2">
          Sort by:
        </label>
        <select id="sort" className="border rounded p-1">
          <option value="">Relevance</option>
          <option value="priceLowToHigh">Price: Low to High</option>
          <option value="priceHighToLow">Price: High to Low</option>
        </select>
      </div>
      <nav className="w-full sm:w-auto mb-2 ml-5">
      {/* other navbar content */}
      <form onSubmit={handleSearchSubmit} className="flex items-center relative">
        <input
          type="text"
          placeholder="Realme earbuds Pro"
          value={searchTerm}
          onChange={handleSearchChange}
          className="ml-2 px-3 py-1 rounded border border-gray-300 pl-8"
        />
        <SearchIcon className="pl-2 h-5 w-5 absolute top-1/2 left-2 transform -translate-y-1/2 text-gray-400" />
      </form>
    </nav>
    </div>
  );
};

export default Filters;