import React from "react";
import {BrowserRouter as Router, Routes, Route} from  'react-router-dom'
import Home from './pages/Home.jsx'
import SignIn from './pages/SignIn.jsx';
import GameDen from './pages/Gameden.jsx';
import About from './pages/About.jsx';
import Products from './pages/Products.jsx'
import Error from './pages/Error.jsx'
import Barters from './pages/Bartersden.jsx'
import SignUp from "./pages/SignUp.jsx";
import ConsoleLogin from "./pages/Console.jsx";
import Emp from "./pages/Emp.jsx"
import Projects from './pages/Projects.jsx'
import Sitemap from "./Sitemap.jsx"
import ConsoleHome from "./pages/ConsoleHome.jsx"
import ProjectPage from './pages/Project.jsx'
import './css/style.css';
import CreateConsole from "./pages/CreateConsole.jsx";
import DevPublicProfile from "./pages/DevPublicProfile.jsx";
import Articles from './pages/Articles.jsx'
import ArticlePage from './pages/Article.jsx'
import ChatBot from "./pages/AIChatbot.jsx";
import PublicProfile from "./pages/PublicProfile.jsx";
import DeleteGameDenAccount from "./pages/DeleteGameDenAccount.jsx";
import Weather from './pages/Weather.jsx'

function App()
{
  return(
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/gameden" element={<GameDen />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/error" element={<Error />} />
        <Route path="/barters" element={<Barters />} />
        <Route path="/console-home" element={<ConsoleHome />} />
        <Route path="/emp" element={<Emp />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/infodexeus/articles" element={<Articles />} />
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/console-login" element={<ConsoleLogin />} />
        <Route path="/console-signup" element={<CreateConsole />} />
        <Route path="/projects/:id/:title?" element={<ProjectPage />} />
        <Route path="/infodexeus/articles/:id/:title?" element={<ArticlePage />} />
        <Route path="/developers/profile/:id/:name?" element={<DevPublicProfile />} />
        <Route path="/chat.den.ai" element={<ChatBot />} />
        <Route path="/profile" element={<PublicProfile />} />
        <Route path="/gameden-account-delete" element={<DeleteGameDenAccount />} />
        <Route path="/weather" element={<Weather />} />
      </Routes>
    </Router>
  );
}

export default App;