import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { HiOutlineDownload } from 'react-icons/hi';
import { SiGoogledrive } from 'react-icons/si';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import { FaCircle, FaCloudDownloadAlt, FaPowerOff, FaRProject, FaRegCircle, FaRegEye, FaRegHeart, FaUser, FaWindowClose } from 'react-icons/fa';
import TestimonialImage01 from '../images/alirubass.jpeg';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { FaClipboard } from "react-icons/fa";
import { Interweave, Markup } from 'interweave';
import AutoAds from '../ads/autoAds';
import GoogleAd from '../ads/ads';


const EmployeeData = () => {

  const websitePrefix = 'www.gigadevden.com';

  const { id, title } = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();

    // const loadAds = () => {
    //   if (window.adsbygoogle) {
    //     window.adsbygoogle.push({});
    //   } else {
    //     setTimeout(loadAds, 100);
    //   }
    // };

    // const script = document.createElement('script');
    // script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    // script.crossOrigin = 'anonymous';
    // script.async = true;
    // script.onload = loadAds;

    // document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // };


  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://gigadevden.com/webprojectpage.php?id=${encodeURIComponent(id)}`
      );
      const json = await response.json();
      if (Object.keys(json).length === 0) {

      }
      else {

        setData(json);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getFileIcon = (driveLink) => {
    const fileExtension = driveLink.split('.').pop().toLowerCase();

    if (fileExtension === 'pdf') {
      return <SiGoogledrive className="w-6 h-6 text-red-500" />;
    } else if (fileExtension === 'doc' || fileExtension === 'docx') {
      return <SiGoogledrive className="w-6 h-6 text-blue-500" />;
    } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
      return <SiGoogledrive className="w-6 h-6 text-green-500" />;
    } else {
      return <SiGoogledrive className="w-6 h-6 text-gray-500" />;
    }
  };


  const [downloaded, setDownloaded] = useState(0);

  const increaseDownload = async (driveLink) => {
    if (sessionStorage.getItem('${websitePrefix}-projectdownload' + id) !== '1') {
      if (navigator.onLine) {
        try {
          const response = await fetch(
            `https://gigadevden.com/webincreasedownload.php?id=${encodeURIComponent(id)}`
          );
          const text = await response.text();
          if (text === 'Successful') {
            sessionStorage.setItem('${websitePrefix}-projectdownload' + id, '1');
            window.open(driveLink, '_blank');
          } else {
            toast(
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FaWindowClose className="mr-2 text-red-600" />
                <span>Server error</span>
              </div>,
              {
                className: 'custom-toast-success', // Add a custom CSS class for styling
              }
            );
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else {
        toast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaWindowClose className="mr-2 text-red-600" />
            <span>Connection failed</span>
          </div>,
          {
            className: 'custom-toast-success', // Add a custom CSS class for styling
          }
        );
      }
    }
    else {
      window.open(driveLink, '_blank');
    }
  };


  const downloadFile = (driveLink) => {

    increaseDownload(driveLink);

  };

  const [rotate, setRotate] = useState(false);
  const [count, setCount] = useState(0);

  const addCount = () => {
    setCount((prev) => prev + 1);
  };

  const minusCount = () => {
    if (count > 0) {
      setCount((prev) => prev - 1);
    }
  };



  const handleShareClick = () => {

    const linkToCopy = window.location.href;

    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        console.log('Link copied to clipboard:', linkToCopy);
        toast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaClipboard className="mr-2 text-green-600" />
            <span>Link copied to clipboard</span>
          </div>,
          {
            className: 'custom-toast-success', // Add a custom CSS class for styling
          }
        );
      })
      .catch((error) => {
        console.error('Error copying link to clipboard:', error);
        toast.error('Error copying link to clipboard');
      });
  };

  return (
    <div className="bg-white">

      <Header />





      <Helmet>
        <title>{data[0]?.title || ''}</title>
        <meta name="description" content={data[0]?.description.replace(/(<([^>]+)>)/gi, '').replace(/\\/g, '') || ''} />
        <link
          rel="canonical"
          href={`https://gigadevden.com/projects/${data[0]?.id || ''}/${data[0]?.title?.toLowerCase()?.replace(/[^a-zA-Z0-9\s]/g, ' ').trim().replace(/\s+/g, '-') || ''}`}
        />
        <meta name="robots" content="index,follow" />
      </Helmet>


      <script async src="https://www.googletagmanager.com/gtag/js?id=G-S7ER3Z65YR"></script>
      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
        
            gtag('config', 'G-S7ER3Z65YR');
          `}
      </script>

      {/* ProjectPageBanner */}

      {/* <div className="w-full d-flex justify-content-center">
        <ins
          className="adsbygoogle"
          style={{ display: 'block', maxWidth: '100%' }}
          data-ad-client="ca-pub-6636754181323634"
          data-ad-slot="7182570307"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div> */}



      {/* <AutoAds googleAdId="ca-pub-6636754181323634" /> */}
      {/* <GoogleAd slot="9201971168" googleAdId="ca-pub-6636754181323634"/> */}




      <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6">
        <div className="flex  lg:flex-row flex-col gap-8">
          {/* <!-- Description Div --> */}

          <div className="px-6 bg-gray-800 md:rounded-l-lg w-full lg:w-2/3 md:px-12 border-t border-b lg:border-t-0 lg:border-b-0 lg:border-l lg:border-r border-gray-300 flex flex-col items-center py-10">


            <p className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 font-normal text-base leading-4 text-gray-200">{data[0]?.category ? data[0].category : ''}</p>

            {data[0]?.downloads ? (
              <div className="flex items-center text-xs text-gray-500 mt-8">
                <div className="flex items-center mr-4">
                  <FaCloudDownloadAlt />
                  <p className="ml-2">{data[0].downloads} downloads</p>
                </div>

                <div className="flex items-center">
                  <FaRegEye />
                  <p className="ml-2">{data[0].views} views</p>
                </div>
              </div>
            ) : (<div></div>)}


            <div className="relative mb-2 mt-12">
              <div className="w-full h-48">
                <div className="w-full h-full overflow-hidden shadow-lg rounded-full">
                  {data[0]?.image ? (
                    <img
                      src={`https://gigadevden.com${data[0].image}`}
                      alt="Sample Image"
                      className="object-cover h-full w-full rounded-full bg-white p-2"
                    />
                  ) : (
                    <div className="w-48 h-48 bg-gray-300 animate-pulse rounded-md" />
                  )}
                </div>
              </div>
            </div>






            {data[0]?.title ? (
              <div className="flex flex-row pt-5 pb-2 px-2 w-full items-center">
                <h1 className="font-semibold text-base lg:text-2xl text-xl leading-6 text-gray-200 break-words mt-5">
                  {data[0].title}
                </h1>

                <div className="flex items-center ml-auto">
                  <div>
                    <div
                      className="text-green-600 hover:text-green-500 cursor-pointer"
                      onClick={handleShareClick}
                    >
                      <svg
                        className="feather feather-share-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx={18} cy={5} r={3} />
                        <circle cx={6} cy={12} r={3} />
                        <circle cx={18} cy={19} r={3} />
                        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
                        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

            ) : (
              <div className="mt-3 animate-pulse bg-gray-300 h-8 w-full rounded"></div>
            )}










            {data[0]?.description ? (




              <div className="w-full shadow-lg bg-white mt-7 overflow-y-auto max-h-80 md:max-h-56 lg:max-h-72 xl:max-h-80 p-5 rounded-lg" style={{ border: '1px solid #ddd', scrollbarWidth: 'thin' }}>

                <style>
                  {`
        /* Hide the default scrollbar */
        nav::-webkit-scrollbar {
          width: 5px;
        }
  
        /* Track */
        nav::-webkit-scrollbar-track {
          background: transparent;
        }
  
        /* Handle */
        nav::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 2.5px;
        }
  
        /* Handle on hover */
        nav::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}
                </style>



                <div className="custom-scrollbar">
                  <p className="font-normal text-base leading-6 text-gray-600">
                    <Markup content={data[0].description.replace(/\\/g, '')} />
                    {/* <Interweave content={data[0].description.replace(/\\r\\n/g, '<br/>').replace(/\\/g, '')} /> */}
                  </p>
                </div>
              </div>



            ) : (

              <div className="mt-3 animate-pulse bg-gray-300 h-64 w-full rounded"></div>

            )}





            {/* <p className=" font-normal text-base leading-6 text-gray-600 mt-7">{item.description}</p> */}
            {/* <p className=" font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 mt-6 ">$ 790.89</p> */}



            {data.map((item) => (
              <>
                <div className="w-full px-9 bg-gray-200 rounded flex flex-col sm:flex-row items-start sm:items-center justify-center p-6 mt-16">
                  {/* Code block starts */}

                  <div className="flex sm:flex-row flex-col">



                    <div className="mr-8 mb-3">
                      <h2 className="text-s font-bold">Published</h2>
                      <p className="mr-4 text-xs">{item.time} <strong>UTC</strong></p>
                    </div>

                    <div className="flex items-start">
                      <div className="flex text-white shadow rounded">

                        <button className="py-3 bg-white px-5 flex items-center justify-center text-s focus:outline-none border rounded-l-lg text-green-600 border-green-600">
                          {/* <FaRegHeart className="h-5 w-5 text-green-600 mr-2" /> */}
                          Free</button>

                        {data.map((item) => (
                          <div>
                            {item.driveLink && (
                              <button className="py-3 bg-green-600 px-5 flex items-center justify-center text-s focus:outline-none border rounded-r-lg border-l border-r-0 border-b-0 border-t-0 border-indigo-100" onClick={() => downloadFile(item.driveLink)}>Download</button>
                            )}
                          </div>
                        ))}

                      </div>
                    </div>

                  </div>
                  {/* Code block ends */}

                </div>
              </>
            ))}





          </div>

          {/* <!-- Preview Images Div For larger Screen--> */}



          <div className="w-full lg:w-1/3 px-12 flex flex-col items-center py-10 bg-gray-800">

            <div className="w-24 h-24 mb-3 p-2 rounded-full bg-gray-600 bg-opacity-50 flex items-center justify-center">
              {data[0]?.image ? (
                <img className="w-full h-full overflow-hidden object-cover rounded-full" src={`https://gigadevden.com${data[0].devimage}`} alt="avatar" />

              ) : (

                <div className="animate-pulse bg-gray-300 h-full w-full rounded-full"></div>

              )}
            </div>
            {data[0]?.name ? (
              <h2 className="text-gray-200 text-xl tracking-normal font-semibold mb-2">{data[0].name}</h2>
            ) : (

              <div className="mt-3 animate-pulse bg-gray-300 h-5 w-1/2 rounded mb-5"></div>

            )}
            <p className="flex text-gray-200 text-sm tracking-normal font-normal mb-5 text-center">
              <span className="cursor-pointer mr-1 text-gray-600">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-pin" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <circle cx={12} cy={11} r={3} />
                  <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 0 1 -2.827 0l-4.244-4.243a8 8 0 1 1 11.314 0z" />
                </svg>
              </span>
              {data[0]?.regioncity ? (
                <div>{data[0].regioncity}, {data[0].country}</div>
              ) : (

                <div></div>

              )}
            </p>
            {data[0]?.bio ? (
              <p className="text-gray-300 text-sm tracking-normal font-normal mb-8 text-center w-10/12">
                {data[0].bio.split(/\s+/).map((word, index) => {
                  if (word.startsWith("#")) {
                    const hashtag = word.substring(1);
                    return (
                      <span key={index} onClick={() => window.open(`https://www.google.com/search?q=${hashtag}`)} className="text-blue-500 cursor-pointer">
                        {word}{" "}
                      </span>
                    );
                  } else {
                    return <span key={index}>{word} </span>;
                  }
                })}
              </p>

            ) : (

              <div className="mt-3 animate-pulse bg-gray-300 h-32 w-full rounded mb-5"></div>

            )}
            <div className="flex items-start">
              <div className>

                {data[0]?.articles ? (
                  <h2 className="text-gray-200 text-2xl leading-6 mb-2 text-center">{data[0].articles}</h2>
                ) : (

                  <div className="mt-3 animate-pulse bg-gray-300 h-12 w-full rounded mb-5"></div>

                )}
                <p className="text-gray-300 text-sm leading-5">Articles</p>
              </div>
              <div className="mx-6 lg:mx-3 xl:mx-6 px-8 lg:px-4 xl:px-8 border-l border-r">
                {data[0]?.projects ? (
                  <h2 className="text-gray-200 text-2xl leading-6 mb-2 text-center">{data[0].projects}</h2>
                ) : (

                  <div className="mt-3 animate-pulse bg-gray-300 h-12 w-full rounded mb-5"></div>

                )}
                <p className="text-gray-300 text-sm leading-5">Projects</p>
              </div>
              <div className>
                {data[0]?.research ? (
                  <h2 className="text-gray-200 text-2xl leading-6 mb-2 text-center">{data[0].research}</h2>
                ) : (

                  <div className="mt-3 animate-pulse bg-gray-300 h-12 w-12 rounded mb-5"></div>

                )}
                <p className="text-gray-300 text-sm leading-5">Researches</p>
              </div>
            </div>



            {data[0]?.name ? (
              <button className="py-3 bg-white px-5 flex items-center justify-center text-s focus:outline-none border hover:bg-green-600 hover:text-white rounded-lg text-green-600 border-green-600 mt-12"
                onClick={() => {
                  const formattedName =
                    data[0].name.toLowerCase() // Convert title to lowercase
                      .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
                      .trim()
                      .replace(/\s+/g, '-'); // Replace spaces with dashes
                  window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
                }}
              >
                <FaRegCircle className="h-5 w-5 text-gray-800 mr-2" />
                More by {data[0].name}
              </button>



            ) : (

              <div className="mt-12 animate-pulse bg-gray-300 h-12 w-48 rounded"></div>

            )}





          </div>

        </div>

      </div>




      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />


      <Footer />



    </div>
  );
};

export default EmployeeData;