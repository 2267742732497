import React, { useState, useEffect } from 'react';
import { HiOutlineDownload } from 'react-icons/hi';
import { SiGoogledrive } from 'react-icons/si';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import Pagination from '../partials/Pagination';
import AddDevProject from '../partials/AddDevProjects'
import { SearchIcon } from '@heroicons/react/solid';
import { Markup } from 'interweave';
import { FaCloudDownloadAlt, FaRegEye } from 'react-icons/fa';
import moment from 'moment';

const EmployeeData = (props) => {

    console.log(props);
    const adminId = props.id;

    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        fetchData();
    };

    useEffect(() => {
        fetchData();
    }, [searchTerm]);


    // Total number of pages
    const [totalPages, setTotalPages] = useState(1);
    const [offset, setOffset] = useState(0);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                `https://gigadevden.com/webmyarticles.php?search=${encodeURIComponent(searchTerm)}&offset=${encodeURIComponent(offset)}&id=${encodeURIComponent(adminId)}`
            );
            const json = await response.json();
            console.log(json);

            if (Object.keys(json).length === 0) {
                setTotalPages(0);
            }
            else {

                if (json[0].total_rows % 12 === 0) {
                    setTotalPages((json[0].total_rows) / 12);
                }
                else {
                    setTotalPages(Math.floor(json[0].total_rows / 12) + 1);
                }
                console.log(json);
                setData(json);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const getFileIcon = (driveLink) => {
        const fileExtension = driveLink.split('.').pop().toLowerCase();

        if (fileExtension === 'pdf') {
            return <SiGoogledrive className="w-6 h-6 text-red-500" />;
        } else if (fileExtension === 'doc' || fileExtension === 'docx') {
            return <SiGoogledrive className="w-6 h-6 text-blue-500" />;
        } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
            return <SiGoogledrive className="w-6 h-6 text-green-500" />;
        } else {
            return <SiGoogledrive className="w-6 h-6 text-gray-500" />;
        }
    };

    const downloadFile = (driveLink) => {
        window.open(driveLink, '_blank');
    };

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchData();
    }, [offset]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setOffset((page - 1) * 12);
            setCurrentPage(page);
        }

        // Perform any other actions or fetch data based on the new page
    };

    function setCurrentProjectId(id, title) {
        const formattedTitle = title
            .toLowerCase() // Convert title to lowercase
            .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
            .trim()
            .replace(/\s+/g, '-'); // Replace spaces with dashes
        window.location.href = '/infodexeus/articles/' + id + '/' + formattedTitle;
    }


    // const getTimeDifference = (timestamp, time) => {
    //   const currentTime = moment(timestamp);
    //   const oldTime = moment(time);
    //   const duration = moment.duration(currentTime.diff(oldTime));

    //   if (duration.asMinutes() < 60) {
    //     return `${Math.round(duration.asMinutes())} min ago`;
    //   } else if (duration.asHours() < 24) {
    //     return `${Math.round(duration.asHours())} hour ago`;
    //   } else if (duration.asDays() < 7) {
    //     return `${Math.round(duration.asDays())}d ago`;
    //   } else if (duration.asMonths() < 1) {
    //     return `${Math.round(duration.asWeeks())}w ago`;
    //   } else if (duration.asYears() < 1) {
    //     return `${Math.round(duration.asMonths())} month ago`;
    //   } else {
    //     return `${Math.round(duration.asYears())} year ago`;
    //   }
    // };

    const getTimeDifference = (timestamp, time) => {
        const currentTime = moment.utc(timestamp);
        const oldTime = moment.utc(time, "YYYY-MM-DD HH:mm:ss");
        const duration = moment.duration(currentTime.diff(oldTime));

        if (duration.asMinutes() < 60) {
            return `${Math.round(duration.asMinutes())} min ago`;
        } else if (duration.asHours() < 24) {
            return `${Math.round(duration.asHours())} hour ago`;
        } else if (duration.asDays() < 7) {
            return `${Math.round(duration.asDays())}d ago`;
        } else if (duration.asMonths() < 1) {
            return `${Math.round(duration.asWeeks())}w ago`;
        } else if (duration.asYears() < 1) {
            return `${Math.round(duration.asMonths())} month ago`;
        } else {
            return `${Math.round(duration.asYears())} year ago`;
        }
    };


    return (
        <div className="overflow-y-auto" style={{ scrollbarWidth: 'thin' }}>



            <div className="container mx-auto min-h-screen px-4">


                <div className="ml-1 px-4">

                    <nav className="flex mt-4 mb-6">
                        {/* other navbar content */}
                        <form onSubmit={handleSearchSubmit} className="flex items-center">
                            <SearchIcon className="h-4 w-4 text-gray-500" />
                            <input
                                type="text"
                                placeholder="C++ Tower of Hanoai?"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className="ml-2 pl-3 pr-14 py-1 rounded border border-gray-300"
                            />
                        </form>
                    </nav>



                    {/* <p className=" text-sm leading-3 text-gray-200 font-normal mb-4 md:mb-2">Developer - Profile - Articles</p> */}
                    <div className=" flex justify-between items-center mb-4">
                        {/* <h2 className=" lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-100 font-semibold">Articles</h2> */}
                        {/* <h2 className="text-red-600 text-3xl md:text-5xl font-bold leading-none rounded-full">☉ Infodexeus</h2> */}


                        {/*  filters Button (md and plus Screen) */}
                        {/* <button className=" cursor-pointer sm:flex hidden hover:bg-gray-700 focus:ring focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-4 px-6 bg-gray-800 rounded-full flex text-base leading-4 font-normal text-white justify-center items-center ">
            <svg className=" mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 12C7.10457 12 8 11.1046 8 10C8 8.89543 7.10457 8 6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M6 4V8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M6 12V20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12 4V14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12 18V20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M18 9C19.1046 9 20 8.10457 20 7C20 5.89543 19.1046 5 18 5C16.8954 5 16 5.89543 16 7C16 8.10457 16.8954 9 18 9Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M18 4V5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M18 9V20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Filters
          </button> */}
                    </div>
                    <p className="text-sm mt-2 md:mt-0 md:text-md leading-5 text-gray-500 font-medium">{data.length < 10 ? '0' + data.length.toString() : data.length.toString()} Result<b className="text-sm">(s)</b></p>

                </div>

                {loading ? (
                    <div className="flex justify-center items-center h-screen">
                        {/* <div className="w-8 h-8 border-2 border-t-2 border-gray-300 rounded-full animate-spin"></div> */}
                        <>
                            <div className="bg-transparent">
                                <div className="px-4 py-12 bg-transparent">
                                    <div className="mx-auto flex justify-center">
                                        <div className="relative">
                                            <div className="w-[160px] h-[160px] border border-sky-500 rounded-full" />
                                            <div className="w-[140px] h-[140px] border border-sky-500 rounded-full absolute top-2.5 right-2.5" />
                                            <div>
                                                <svg
                                                    className="absolute top-[22px] right-[26px] animate-spin infinite"
                                                    width={113}
                                                    height={113}
                                                    viewBox="0 0 113 113"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M56.7631 110.374C46.061 110.374 35.5993 107.2 26.7008 101.255C17.8023 95.3088 10.8668 86.8579 6.77128 76.9704C2.67576 67.083 1.60419 56.2031 3.69207 45.7066C5.77994 35.2102 10.9335 25.5686 18.501 18.001C26.0686 10.4335 35.7102 5.27994 46.2066 3.19207C56.7031 1.10419 67.583 2.17576 77.4704 6.27128C87.3579 10.3668 95.8088 17.3023 101.755 26.2008C107.7 35.0993 110.874 45.561 110.874 56.2631"
                                                        stroke="#0ea5e9"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeDasharray="16 16"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <p className="text-center text-gray-600 text-base mt-4">
                Your Request Is Being Loaded, Please Wait
              </p> */}
                                </div>
                            </div>
                        </>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mt-16 2xl:mx-16">
                        {data.map((item) => (




<div className="duration-300 hover:-translate-y-2 -translate-y-1 rounded bg-opacity-100 shadow-xl bg-slate-900 font-poppins">
<div>

    <div className="relative">
        <button onClick={() => setCurrentProjectId(item.id, item.title)} className="relative block overflow-hidden aspect-w-16 aspect-h-10">
            <div className="relative h-52 w-full">
                <img
                    src={`https://www.gigadevden.com${item.image}`}
                    className="rounded-tl rounded-tr object-contain hover:object-cover"
                    alt="Post"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-200"></div>
            </div>
        </button>

        <div className="flex items-center justify-between absolute bottom-4 left-4 ml-2">
            <div className="flex items-center justify-between space-x-4">

                <button
                    onClick={() => {
                        const formattedName =
                            data[0].devname.toLowerCase() // Convert title to lowercase
                                .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
                                .trim()
                                .replace(/\s+/g, '-'); // Replace spaces with dashes
                        window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
                    }}
                    className="flex-shrink-0 w-10 h-10 overflow-hidden rounded-full shadow-md">
                    <img
                        src={`https://www.gigadevden.com${item.devimage}`}
                        alt={item.devname}
                        className="bg-white object-cover w-full h-full"
                    />
                </button>


                <div className="flex flex-col space-y-1">

                    <button
                        onClick={() => {
                            const formattedName =
                                data[0].devname.toLowerCase() // Convert title to lowercase
                                    .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
                                    .trim()
                                    .replace(/\s+/g, '-'); // Replace spaces with dashes
                            window.location.href = '/developers/profile/' + data[0].devid + '/' + formattedName;
                        }}
                        className="text-sm text-gray-400 hover:underline">
                        {item.devname}
                    </button>

                </div>
            </div>

        </div>


        <div className="flex items-center space-x-2 text-gray-400 -mb-14 absolute bottom-2 right-5">
            <div className="flex items-center space-x-1">
                {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
            <path
                fillRule="evenodd"
                d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                clipRule="evenodd"
            ></path>
        </svg> */}
                <FaRegEye />
                <span className="text-xs font-medium">{item.views}</span>
            </div>
            {/* <div className="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                <path
                    fillRule="evenodd"
                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                    clipRule="evenodd"
                ></path>
            </svg>
            <span className="text-sm font-medium">{item.downloads}</span>
        </div> */}
        </div>

        <div className="absolute top-1 right-1 mt-2 mr-2 bg-white text-black rounded-full w-auto p-2 h-6 flex items-center justify-center opacity-70">
            <a className="text-xs font-bold text-black">{getTimeDifference(item.timestamp, item.time)}</a>
        </div>

    </div>


</div>
<div onClick={() => setCurrentProjectId(item.id, item.title)} className="px-6 pb-4 cursor-pointer bg-slate-900 rounded-b-lg">

    <h3 className="text-gray-400 w-1/2 text-sm font-bold tracking-widest pb-2 mb-6 pt-8 border-b border-red-500">

        {item.topic ? (
            item.topic.toUpperCase()
        ) : (
            <div className="flex flex-col mb-2">
                <div className="animate-pulse bg-gray-300 h-5 w-32 rounded" />
            </div>

        )}

    </h3>

    <a

        className="font-semibold text-gray-200 tracking-widest transition-colors duration-300 text-sm hover:text-primary hover:text-primary"
    >
        {item.title}
    </a>

    <br></br>


    <div className="mt-2">
        <small className="leading-5 text-gray-400 tracking-widest">
            {/* {
            ((<Markup content={item.description.replace(/\\/g, '')} />).props.content).length > 242
                ? <Markup content={((<Markup content={item.description.replace(/\\/g, '')} />).props.content).substring(0, 242).split(' ').slice(0, -1).join(' ') + ' ...'} />
                : <Markup content={item.description.replace(/\\/g, '')} />
        } */}
            {item.one_liner}
        </small>
    </div>



</div>
</div>

                        ))}
                    </div>
                )}
            </div>



            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />


        </div>


        //     <div className="">
        //     <div className="mx-auto container bg-white shadow rounded">
        //         <div className="flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-start lg:items-stretch w-full">
        //             <div className="w-full lg:w-1/3 flex flex-col lg:flex-row items-start lg:items-center">
        //                 <div className="flex items-center">
        //                     <a className="text-gray-600 dark:text-gray-400 p-2 border-transparent border bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-gray-200 cursor-pointer rounded focus:outline-none focus:border-gray-800 focus:shadow-outline-gray" href="javascript: void(0)">
        //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon cursor-pointer icon-tabler icon-tabler-edit" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                             <path stroke="none" d="M0 0h24v24H0z" />
        //                             <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
        //                             <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
        //                             <line x1={16} y1={5} x2={19} y2={8} />
        //                         </svg>
        //                     </a>
        //                     <a className="text-gray-600 dark:text-gray-400 mx-2 p-2 border-transparent border bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-gray-200 cursor-pointer rounded focus:outline-none focus:border-gray-800 focus:shadow-outline-gray" href="javascript: void(0)">
        //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon cursor-pointer icon-tabler icon-tabler-settings" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                             <path stroke="none" d="M0 0h24v24H0z" />
        //                             <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
        //                             <circle cx={12} cy={12} r={3} />
        //                         </svg>
        //                     </a>
        //                     <a className="text-gray-600 dark:text-gray-400 mr-2 p-2 border-transparent border bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-gray-200 cursor-pointer rounded focus:outline-none focus:border-gray-800 focus:shadow-outline-gray" href="javascript: void(0)">
        //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bookmark" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                             <path stroke="none" d="M0 0h24v24H0z" />
        //                             <path d="M9 4h6a2 2 0 0 1 2 2v14l-5-3l-5 3v-14a2 2 0 0 1 2 -2" />
        //                         </svg>
        //                     </a>
        //                     <a className="text-gray-600 dark:text-gray-400 mr-2 p-2 border-transparent border bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-gray-200 cursor-pointer rounded focus:outline-none focus:border-gray-800 focus:shadow-outline-gray" href="javascript: void(0)">
        //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                             <path stroke="none" d="M0 0h24v24H0z" />
        //                             <rect x={8} y={8} width={12} height={12} rx={2} />
        //                             <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
        //                         </svg>
        //                     </a>
        //                     <a className="text-red-500 p-2 border-transparent border bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-gray-200 cursor-pointer rounded focus:outline-none focus:border-gray-800 focus:shadow-outline-gray" href="javascript: void(0)">
        //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon cursor-pointer icon-tabler icon-tabler-trash" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                             <path stroke="none" d="M0 0h24v24H0z" />
        //                             <line x1={4} y1={7} x2={20} y2={7} />
        //                             <line x1={10} y1={11} x2={10} y2={17} />
        //                             <line x1={14} y1={11} x2={14} y2={17} />
        //                             <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
        //                             <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
        //                         </svg>
        //                     </a>
        //                 </div>
        //             </div>
        //             <div className="w-full lg:w-2/3 flex flex-col lg:flex-row items-start lg:items-center justify-end">
        //                 <div className="flex items-center lg:border-l lg:border-r border-gray-300 dark:border-gray-200 py-3 lg:py-0 lg:px-6">
        //                     <p className="text-base text-gray-600 dark:text-gray-400" id="page-view">
        //                         Viewing 1 - 20 of 60
        //                     </p>
        //                     <a className="text-gray-600 dark:text-gray-400 ml-2 border-transparent border cursor-pointer rounded" onclick="pageView(false)">
        //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                             <path stroke="none" d="M0 0h24v24H0z" />
        //                             <polyline points="15 6 9 12 15 18" />
        //                         </svg>
        //                     </a>
        //                     <a className="text-gray-600 dark:text-gray-400 border-transparent border rounded focus:outline-none cursor-pointer" onclick="pageView(true)">
        //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                             <path stroke="none" d="M0 0h24v24H0z" />
        //                             <polyline points="9 6 15 12 9 18" />
        //                         </svg>
        //                     </a>
        //                 </div>
        //                 <div className="flex items-center lg:border-r border-gray-300 dark:border-gray-200 pb-3 lg:pb-0 lg:px-6">
        //                     <div className="relative w-32 z-10">
        //                         <div className="pointer-events-none text-gray-600 dark:text-gray-400 absolute inset-0 m-auto mr-2 xl:mr-4 z-0 w-5 h-5">
        //                             <svg xmlns="http://www.w3.org/2000/svg" className="icon cursor-pointer icon-tabler icon-tabler-chevron-down" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                                 <path stroke="none" d="M0 0h24v24H0z" />
        //                                 <polyline points="6 9 12 15 18 9" />
        //                             </svg>
        //                         </div>
        //                         <select aria-label="Selected tab" className="focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray text-base form-select block w-full py-2 px-2 xl:px-3 rounded text-gray-600 dark:text-gray-400 appearance-none bg-transparent">
        //                             <option>List View</option>
        //                             <option>Grid View</option>
        //                         </select>
        //                     </div>
        //                 </div>
        //                 <div className="lg:ml-6 flex items-center">
        //                     <button className="bg-gray-200 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-gray-300 rounded text-indigo-700 px-5 h-8 flex items-center text-sm">Download All</button>
        //                     <div className="text-white ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 w-8 h-8 rounded flex items-center justify-center">
        //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                             <path stroke="none" d="M0 0h24v24H0z" />
        //                             <line x1={12} y1={5} x2={12} y2={19} />
        //                             <line x1={5} y1={12} x2={19} y2={12} />
        //                         </svg>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="w-full overflow-x-scroll xl:overflow-x-hidden">
        //             <table className="min-w-full bg-white dark:bg-gray-800">
        //                 <thead>
        //                     <tr className="w-full h-16 border-gray-300 dark:border-gray-200 border-b py-8">
        //                         <th className="pl-8 text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">
        //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="checkAll(this)" />
        //                         </th>
        //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">
        //                             <div className="text-gray-600 dark:text-gray-400 opacity-0 cursor-default relative w-10">
        //                                 <div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-1 rounded-full bg-indigo-700 text-white flex justify-center items-center text-xs">3</div>
        //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                                     <path stroke="none" d="M0 0h24v24H0z" />
        //                                     <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        //                                     <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
        //                                 </svg>
        //                             </div>
        //                         </th>
        //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">Invoice Number</th>
        //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">Client</th>
        //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">Company Contact</th>
        //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">Amount</th>
        //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">Date</th>
        //                         <th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">
        //                             <div className="opacity-0 w-2 h-2 rounded-full bg-indigo-400" />
        //                         </th>
        //                         <td className="text-gray-600 dark:text-gray-400 font-normal pr-8 text-left text-sm tracking-normal leading-4">More</td>
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     <tr className="h-24 border-gray-300 dark:border-gray-200 border-b">
        //                         <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4">
        //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="tableInteract(this)" />
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
        //                             <div className="relative w-10 text-gray-600 dark:text-gray-400">
        //                                 <div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-1 rounded-full bg-indigo-700 text-white flex justify-center items-center text-xs">3</div>
        //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                                     <path stroke="none" d="M0 0h24v24H0z" />
        //                                     <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        //                                     <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
        //                                 </svg>
        //                             </div>
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">#MC10023</td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">Toyota Motors</td>
        //                         <td className="pr-6 whitespace-no-wrap">
        //                             <div className="flex items-center">
        //                                 <div className="h-8 w-8">
        //                                     <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/advance_tables/at_1.png" alt className="h-full w-full rounded-full overflow-hidden shadow" />
        //                                 </div>
        //                                 <p className="ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm">Carrie Anthony</p>
        //                             </div>
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">$2,500</td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">02.03.20</td>
        //                         <td className="pr-6">
        //                             <div className="w-2 h-2 rounded-full bg-indigo-400" />
        //                         </td>
        //                         <td className="pr-8 relative">
        //                             <div className="dropdown-content mt-8 absolute left-0 -ml-12 shadow-md z-10 hidden w-32">
        //                                 <ul className="bg-white dark:bg-gray-800 shadow rounded py-1">
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Edit</li>
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Delete</li>
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Duplicate</li>
        //                                 </ul>
        //                             </div>
        //                             <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
        //                                 <svg xmlns="http://www.w3.org/2000/svg" onclick="dropdownFunction(this)" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                                     <path stroke="none" d="M0 0h24v24H0z" />
        //                                     <circle cx={12} cy={12} r={1} />
        //                                     <circle cx={12} cy={19} r={1} />
        //                                     <circle cx={12} cy={5} r={1} />
        //                                 </svg>
        //                             </button>
        //                         </td>
        //                     </tr>
        //                     <tr className="h-24 border-gray-300 dark:border-gray-200 border-b">
        //                         <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4">
        //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="tableInteract(this)" />
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
        //                             <div className="text-gray-400 relative w-10">
        //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                                     <path stroke="none" d="M0 0h24v24H0z" />
        //                                     <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        //                                     <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
        //                                 </svg>
        //                             </div>
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">#MC10023</td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">Toyota Motors</td>
        //                         <td className="pr-6 whitespace-no-wrap">
        //                             <div className="flex items-center">
        //                                 <div className="h-8 w-8">
        //                                     <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/advance_tables/at_2.png" alt className="h-full w-full rounded-full overflow-hidden shadow" />
        //                                 </div>
        //                                 <p className="ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm">Carrie Anthony</p>
        //                             </div>
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">$2,500</td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">02.03.20</td>
        //                         <td className="pr-6">
        //                             <div className="w-2 h-2 rounded-full bg-red-400" />
        //                         </td>
        //                         <td className="pr-8 relative">
        //                             <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none ">
        //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" onclick="dropdownFunction(this)">
        //                                     <path stroke="none" d="M0 0h24v24H0z" />
        //                                     <circle cx={12} cy={12} r={1} />
        //                                     <circle cx={12} cy={19} r={1} />
        //                                     <circle cx={12} cy={5} r={1} />
        //                                 </svg>
        //                             </button>
        //                             <div className="dropdown-content mt-1 absolute left-0 -ml-12 shadow-md z-10 hidden w-32">
        //                                 <ul className="bg-white dark:bg-gray-800 shadow rounded py-1">
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Edit</li>
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Delete</li>
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Duplicate</li>
        //                                 </ul>
        //                             </div>
        //                         </td>
        //                     </tr>
        //                     <tr className="h-24 border-gray-300 dark:border-gray-200 border-b">
        //                         <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4">
        //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="tableInteract(this)" />
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
        //                             <div className="text-gray-400 relative w-10">
        //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                                     <path stroke="none" d="M0 0h24v24H0z" />
        //                                     <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        //                                     <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
        //                                 </svg>
        //                             </div>
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">#MC10023</td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">Toyota Motors</td>
        //                         <td className="pr-6 whitespace-no-wrap">
        //                             <div className="flex items-center">
        //                                 <div className="h-8 w-8">
        //                                     <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/advance_tables/at_3.png" alt className="h-full w-full rounded-full overflow-hidden shadow" />
        //                                 </div>
        //                                 <p className="ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm">Carrie Anthony</p>
        //                             </div>
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">$2,500</td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">02.03.20</td>
        //                         <td className="pr-6">
        //                             <div className="w-2 h-2 rounded-full bg-indigo-400" />
        //                         </td>
        //                         <td className="pr-8 relative">
        //                             <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
        //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" onclick="dropdownFunction(this)">
        //                                     <path stroke="none" d="M0 0h24v24H0z" />
        //                                     <circle cx={12} cy={12} r={1} />
        //                                     <circle cx={12} cy={19} r={1} />
        //                                     <circle cx={12} cy={5} r={1} />
        //                                 </svg>
        //                             </button>
        //                             <div className="dropdown-content mt-1 absolute left-0 -ml-12 shadow-md z-10 hidden w-32">
        //                                 <ul className="bg-white dark:bg-gray-800 shadow rounded py-1">
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Edit</li>
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Delete</li>
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Duplicate</li>
        //                                 </ul>
        //                             </div>
        //                         </td>
        //                     </tr>
        //                     <tr className="h-24 border-gray-300 dark:border-gray-200 border-b">
        //                         <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4">
        //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="tableInteract(this)" />
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
        //                             <div className="text-gray-400 relative w-10">
        //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                                     <path stroke="none" d="M0 0h24v24H0z" />
        //                                     <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        //                                     <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
        //                                 </svg>
        //                             </div>
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">#MC10023</td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">Toyota Motors</td>
        //                         <td className="pr-6 whitespace-no-wrap">
        //                             <div className="flex items-center">
        //                                 <div className="h-8 w-8">
        //                                     <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/advance_tables/at_1.png" alt className="h-full w-full rounded-full overflow-hidden shadow" />
        //                                 </div>
        //                                 <p className="ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm">Carrie Anthony</p>
        //                             </div>
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">$2,500</td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">02.03.20</td>
        //                         <td className="pr-6">
        //                             <div className="w-2 h-2 rounded-full bg-indigo-400" />
        //                         </td>
        //                         <td className="pr-8 relative">
        //                             <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
        //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" onclick="dropdownFunction(this)">
        //                                     <path stroke="none" d="M0 0h24v24H0z" />
        //                                     <circle cx={12} cy={12} r={1} />
        //                                     <circle cx={12} cy={19} r={1} />
        //                                     <circle cx={12} cy={5} r={1} />
        //                                 </svg>
        //                             </button>
        //                             <div className="dropdown-content mt-1 absolute left-0 -ml-12 shadow-md z-10 hidden w-32">
        //                                 <ul className="bg-white dark:bg-gray-800 shadow rounded py-1">
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Edit</li>
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Delete</li>
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Duplicate</li>
        //                                 </ul>
        //                             </div>
        //                         </td>
        //                     </tr>
        //                     <tr className="h-24 border-gray-300 dark:border-gray-200 border-b">
        //                         <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4">
        //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="tableInteract(this)" />
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
        //                             <div className="relative w-10">
        //                                 <div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-1 rounded-full bg-indigo-700 text-white flex justify-center items-center text-xs">1</div>
        //                                 <div className="text-gray-600 dark:text-gray-400">
        //                                     <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                                         <path stroke="none" d="M0 0h24v24H0z" />
        //                                         <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        //                                         <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
        //                                     </svg>
        //                                 </div>
        //                             </div>
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">#MC10023</td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">Toyota Motors</td>
        //                         <td className="pr-6 whitespace-no-wrap">
        //                             <div className="flex items-center">
        //                                 <div className="h-8 w-8">
        //                                     <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/advance_tables/at_2.png" alt className="h-full w-full rounded-full overflow-hidden shadow" />
        //                                 </div>
        //                                 <p className="ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm">Carrie Anthony</p>
        //                             </div>
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">$2,500</td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">02.03.20</td>
        //                         <td className="pr-6">
        //                             <div className="w-2 h-2 rounded-full bg-red-400" />
        //                         </td>
        //                         <td className="pr-8 relative">
        //                             <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
        //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" onclick="dropdownFunction(this)">
        //                                     <path stroke="none" d="M0 0h24v24H0z" />
        //                                     <circle cx={12} cy={12} r={1} />
        //                                     <circle cx={12} cy={19} r={1} />
        //                                     <circle cx={12} cy={5} r={1} />
        //                                 </svg>
        //                             </button>
        //                             <div className="dropdown-content mt-1 absolute left-0 -ml-12 shadow-md z-10 hidden w-32">
        //                                 <ul className="bg-white dark:bg-gray-800 shadow rounded py-1">
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Edit</li>
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Delete</li>
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Duplicate</li>
        //                                 </ul>
        //                             </div>
        //                         </td>
        //                     </tr>
        //                     <tr className="h-24 border-gray-300 dark:border-gray-200 border-b">
        //                         <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4">
        //                             <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none" onclick="tableInteract(this)" />
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
        //                             <div className="relative w-10">
        //                                 <div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-1 rounded-full bg-indigo-700 text-white flex justify-center items-center text-xs">5</div>
        //                                 <div className="text-gray-600 dark:text-gray-400">
        //                                     <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        //                                         <path stroke="none" d="M0 0h24v24H0z" />
        //                                         <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        //                                         <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
        //                                     </svg>
        //                                 </div>
        //                             </div>
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">#MC10023</td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">Toyota Motors</td>
        //                         <td className="pr-6 whitespace-no-wrap">
        //                             <div className="flex items-center">
        //                                 <div className="h-8 w-8">
        //                                     <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/advance_tables/at_3.png" alt className="h-full w-full rounded-full overflow-hidden shadow" />
        //                                 </div>
        //                                 <p className="ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm">Carrie Anthony</p>
        //                             </div>
        //                         </td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">$2,500</td>
        //                         <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">02.03.20</td>
        //                         <td className="pr-6">
        //                             <div className="w-2 h-2 rounded-full bg-gray-600" />
        //                         </td>
        //                         <td className="pr-8 relative">
        //                             <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
        //                                 <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" onclick="dropdownFunction(this)">
        //                                     <path stroke="none" d="M0 0h24v24H0z" />
        //                                     <circle cx={12} cy={12} r={1} />
        //                                     <circle cx={12} cy={19} r={1} />
        //                                     <circle cx={12} cy={5} r={1} />
        //                                 </svg>
        //                             </button>
        //                             <div className="dropdown-content mt-1 absolute left-0 -ml-12 shadow-md z-10 hidden w-32">
        //                                 <ul className="bg-white dark:bg-gray-800 shadow rounded py-1">
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Edit</li>
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Delete</li>
        //                                     <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Duplicate</li>
        //                                 </ul>
        //                             </div>
        //                         </td>
        //                     </tr>
        //                 </tbody>
        //             </table>
        //         </div>
        //     </div>
        // </div>
    );
};

export default EmployeeData;