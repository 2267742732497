import React from "react";
export default function IndexPage() {

    const name = localStorage.getItem('${websitePrefix}-name');
    const developerName = name ? name.split(" ")[0] : 'Developer';

    return (
        <>
        <div className="flex w-full m-12">
            <div className="max-w-sm rounded shadow bg-white dark:bg-gray-800">
                <div className="flex">
                    <div className="px-6 py-5">
                        <p className="text-base font-medium leading-none text-gray-800 dark:text-gray-100">Develop for others</p>
                        <p className="text-xs leading-3 text-gray-500 dark:text-gray-400 pt-2">Thank You for Signing Up</p>
                        <p className="text-xl font-semibold leading-tight text-indigo-700 dark:text-gray-100 pt-6 pt-3">Developer</p>
                    </div>
                    <div className="px-3">
                        <img src="https://i.ibb.co/34gPtCT/bg.png" alt="medal" />
                    </div>
                </div>
            </div>
        </div>
    </>

        
    );
}
