import React from 'react';

import HeaderBlack from '../partials/HeaderBlack.jsx';
import Products from '../partials/FeaturesZigzag.jsx';
import Footer from '../partials/Footer.jsx';
import { Helmet } from 'react-helmet';


function About() {
  return (


    <div className='flex flex-col min-h-screen border-red-500 border-t-4'>

      {/* <Helmet>
        <script>
          {`
            (function(s,u,z,p){
              s.src=u;
              s.setAttribute('data-zone',z);
              p.appendChild(s);
            })(document.createElement('script'),'https://inklinkor.com/tag.min.js',6057053,document.body||document.documentElement)
          `}
        </script>
        <script>{` (function(s,u,z,p){s.src=u,s.setAttribute('data-zone',z),p.appendChild(s);})(document.createElement('script'),'https://inklinkor.com/tag.min.js',6057208,document.body||document.documentElement)`}</script>
      </Helmet> */}



      <div className="flex flex-col md:flex-row h-auto min-h-0">

        {/* Main content */}
        <div className="bg-black w-full md:w-[100%] min-h-screen flex-grow">
          <HeaderBlack />
          <Products />
          <Footer />


        </div>
      </div>
    </div>
  );
}

export default About;