import React from 'react';
import { useState, useEffect } from 'react';

import TestimonialImage01 from '../images/alirubass.jpeg';
import TestimonialImage02 from '../images/rehan1.jpeg';
import TestimonialImage03 from '../images/inara-sheikh.jpeg';

function Testimonials() {

  function TextBlock({ text, limit = 400 }) {
    const [showFullText, setShowFullText] = useState(false);

    const handleReadMore = () => {
      setShowFullText(true);
    };

    const handleReadLess = () => {
      setShowFullText(false);
    };

    const displayText = showFullText ? text : text.slice(0, limit) + '...';

    return (
      <div>
        <p className="text-sm leading-6 text-white 2xl:pr-12 mt-4" data-aos="fade-up" data-aos-delay="200">
          {displayText}
        </p>
        <div className="flex"> {/* Container for centered buttons */}
          {!showFullText && (

            <div className="md:mt-12 mt-20">
              <button onClick={handleReadMore}
                className="text-base font-medium leading-4 text-black bg-white sm:w-auto w-full rounded p-4 focus:outline-none hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-white">Read More</button>
            </div>
          )}
          {showFullText && (
            <div className="md:mt-12 mt-20">
              <button onClick={handleReadLess}
                className="text-base font-medium leading-4 text-black bg-white sm:w-auto w-full rounded p-4 focus:outline-none hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-white">Read Less</button>
            </div>
          )}
        </div>
      </div>
    );

  }


  return (
    <section className="bg-black font-inter antialiased bg-black text-gray-200 tracking-tight">


   
      <div className="max-w-6xl mx-auto px-4 sm:px-6">


      <div>
            <div className="2xl:mx-auto 2xl:container md:px-20 px-4 md:py-12 py-9">
              <div className="relative rounded-md">
                {/* <img src="https://gigadevden.com/dotden.png" alt="city view" className="w-auto h-full rounded-md  object-center object-fill absolute sm:block hidden" />
                    <img src="https://gigadevden.com/dotden.png" alt="city view" className="w-full h-full rounded-md absolute object-center object-fill sm:hidden" /> */}
                <div className="text-xl relative z-20  to-transparent w-full h-full z-40 top-0 md:p-16 p-6 flex flex-col justify-between rounded-md ">
                  <div>
                    <h1 className="md:text-5xl text-3xl font-bold md:leading-10 leading-9 text-white sm:w-auto w-64">Stand Where it Starts.</h1>
                    {/* <p className="text-lg leading-6 text-white xl:w-5/12 lg:w-8/12 md:w-10/12  2xl:pr-12 mt-4">A good idiom for kids is "It's raining cats and dogs." Kids know what both cats and dogs are from an early age.</p> */}
                    <TextBlock text=" GigaDevDen, the premier technology company that specializes in developing cutting-edge platforms for businesses of all kinds. We offer a full suite of services that includes game development, graphics design, software engineering, website design, mobile app development, and more. Our team of experts is skilled in various fields and has experience working with a wide range of brands and industries.

At GigaDevDen, we're dedicated to staying ahead of the curve and meeting the ever-changing demands of our customers. We work closely with our clients to understand their unique needs and goals, and we use that information to create custom solutions that deliver results. Our commitment to quality, innovation, and customer satisfaction sets us apart from the competition.

If you're a startup looking to build your first platform or an established company looking to modernize your technology stack, GigaDevDen is the technology partner you need. We pride ourselves on our ability to create innovative solutions that help businesses thrive in today's fast-paced digital landscape.

To learn more about our services and how we can help take your business to the next level, contact GigaDevDen today. Our team of experts is standing by to answer your questions and provide you with the information you need to make an informed decision.
"/>
                  </div>
                  {/* <div className="md:mt-12 mt-20">
                            <button className="text-base font-medium leading-4 text-indigo-700 bg-white sm:w-auto w-full rounded p-4 focus:outline-none hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-white">Explore More</button>
                        </div> */}
                </div>
              </div>
            </div>
          </div>



        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">



            <h2 className="h2 text-red-500 mb-4">The DEVDEN™ Squad</h2>
            <p className="text-l text-gray-400 mr-5 ml-5">Devden Squad, a team of tech whizzes,
              Crafting software solutions with wizardry fizz.

              From running a company to serving clients,
              Their technical prowess leaves no room for defiance.</p>
          </div>

          {/* Testimonials */}
          <div className="max-w-sm mx-auto grid gap-8 lg:grid-cols-2 lg:gap-6 items-start lg:max-w-none"

          // style={{
          //   backgroundImage: "url('https://img.freepik.com/premium-vector/rectangular-design-element-silhouette-tech-style-isolated-white-long-tech-plate-with-copy-space-clipart_337410-3006.jpg?w=1060')",
          //   backgroundSize: 'contain',
          //   backgroundPosition: 'center',

          // }}
          >

            {/* 1st testimonial */}
            <div className="flex flex-col h-full p-6 bg-gray-800" data-aos="fade-up">
              <div>
                <div className="relative inline-flex flex-col mb-4">
                  <img className="rounded-full" src={TestimonialImage01} width="48" height="48" alt="Testimonial 01" />
                  <svg className="absolute top-0 right-0 -mr-3 w-6 h-5 fill-current text-purple-600" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 13.517c0-2.346.611-4.774 1.833-7.283C3.056 3.726 4.733 1.648 6.865 0L11 2.696C9.726 4.393 8.777 6.109 8.152 7.844c-.624 1.735-.936 3.589-.936 5.56v4.644H0v-4.531zm13 0c0-2.346.611-4.774 1.833-7.283 1.223-2.508 2.9-4.586 5.032-6.234L24 2.696c-1.274 1.697-2.223 3.413-2.848 5.148-.624 1.735-.936 3.589-.936 5.56v4.644H13v-4.531z" />
                  </svg>
                </div>
              </div>
              <blockquote className="text-lg text-gray-400 grow">— This savvy individual blends trademark ownership and software development expertise to create innovative solutions that bridge technology and branding. They bring valuable skills to protect and promote intellectual property.</blockquote>
              <div className="text-gray-700 font-medium mt-6 pt-5 border-t border-gray-700">
                <cite className="text-gray-200 not-italic">Ali Rubass</cite> - <a className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">TMO / dev</a>
              </div>
            </div>

            {/* 2nd testimonial */}
            {/* <div className="flex flex-col h-full p-6 bg-gray-800" data-aos="fade-up" data-aos-delay="200">
              <div>
                <div className="relative inline-flex flex-col mb-4">
                  <img className="rounded-full" src={TestimonialImage02} width="48" height="48" alt="Testimonial 02" />
                  <svg className="absolute top-0 right-0 -mr-3 w-6 h-5 fill-current text-purple-600" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 13.517c0-2.346.611-4.774 1.833-7.283C3.056 3.726 4.733 1.648 6.865 0L11 2.696C9.726 4.393 8.777 6.109 8.152 7.844c-.624 1.735-.936 3.589-.936 5.56v4.644H0v-4.531zm13 0c0-2.346.611-4.774 1.833-7.283 1.223-2.508 2.9-4.586 5.032-6.234L24 2.696c-1.274 1.697-2.223 3.413-2.848 5.148-.624 1.735-.936 3.589-.936 5.56v4.644H13v-4.531z" />
                  </svg>
                </div>
              </div>
              <blockquote className="text-lg text-gray-400 grow">— This visionary design leader delivers stunning visual experiences with deep expertise in design thinking, user experience, and brand identity, making a lasting impact on any organization's creative output.</blockquote>
              <div className="text-gray-700 font-medium mt-6 pt-5 border-t border-gray-700">
                <cite className="text-gray-200 not-italic">Rehan Tariq</cite> - <a className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">CDO</a>
              </div>
            </div> */}

            {/* 3rd testimonial */}
            <div className="flex flex-col h-full p-6 bg-gray-800" data-aos="fade-up" data-aos-delay="400">
              <div>
                <div className="relative inline-flex flex-col mb-4">
                  <img className="rounded-full" src={TestimonialImage03} width="48" height="48" alt="Testimonial 03" />
                  <svg className="absolute top-0 right-0 -mr-3 w-6 h-5 fill-current text-purple-600" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 13.517c0-2.346.611-4.774 1.833-7.283C3.056 3.726 4.733 1.648 6.865 0L11 2.696C9.726 4.393 8.777 6.109 8.152 7.844c-.624 1.735-.936 3.589-.936 5.56v4.644H0v-4.531zm13 0c0-2.346.611-4.774 1.833-7.283 1.223-2.508 2.9-4.586 5.032-6.234L24 2.696c-1.274 1.697-2.223 3.413-2.848 5.148-.624 1.735-.936 3.589-.936 5.56v4.644H13v-4.531z" />
                  </svg>
                </div>
              </div>
              <blockquote className="text-lg text-gray-400 grow">— This skilled technical professional delivers cutting-edge software solutions with strategic thinking, bringing invaluable expertise to any company seeking to stay ahead of the curve in innovation and strategy.</blockquote>
              <div className="text-gray-700 font-medium mt-6 pt-5 border-t border-gray-700">
                <cite className="text-gray-200 not-italic">Inara Sheikh</cite> - <a className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">Manager</a>
              </div>
            </div>

          </div>


        
          {/* <img src="https://gigadevden.com/dotden.png" alt="Product 1" className="w-full h-auto" />
          <h2 className="h2 text-white mb-4"></h2>
          <p className="text-l text-white mb-10 p-4 text-center border border-white tracking-wide" data-aos="fade-up" data-aos-delay="200">Experience the unfettered realization of your maximum potential as a developer.</p> */}


          {/* <p className="text-l text-gray-500 mb-10 text-justify tracking-wide" data-aos="fade-up" data-aos-delay="200">
                GigaDevDen, the premier technology company that specializes in developing cutting-edge platforms for businesses of all kinds. We offer a full suite of services that includes game development, graphics design, software engineering, website design, mobile app development, and more. Our team of experts is skilled in various fields and has experience working with a wide range of brands and industries.

                At GigaDevDen, we're dedicated to staying ahead of the curve and meeting the ever-changing demands of our customers. We work closely with our clients to understand their unique needs and goals, and we use that information to create custom solutions that deliver results. Our commitment to quality, innovation, and customer satisfaction sets us apart from the competition.

                If you're a startup looking to build your first platform or an established company looking to modernize your technology stack, GigaDevDen is the technology partner you need. We pride ourselves on our ability to create innovative solutions that help businesses thrive in today's fast-paced digital landscape.

                To learn more about our services and how we can help take your business to the next level, contact GigaDevDen today. Our team of experts is standing by to answer your questions and provide you with the information you need to make an informed decision.

              </p> */}



        </div>
      </div>
    </section>
  );
}

export default Testimonials;
