import React from 'react';

import FeatImage01 from '../images/features-03-image-01.png';
import FeatImage02 from '../images/reecho.png';
import FeatImage03 from '../images/NUHostel.png';
import { Helmet } from 'react-helmet';

function FeaturesZigzag() {
  return (
    <div>
      <Helmet>
        <title>One product, unlimited Features</title>
        <meta name="description" content="A product with unlimited features is a versatile offering that can cater to a wide range of needs and requirements. It can streamline workflows, boost productivity, and enhance efficiency, making it an essential tool for various industries. Its flexibility and customization options provide a tailored experience, making it a valuable investment." />

        <link
          rel="canonical"
          href="https://gigadevden.com/products"
        />

      </Helmet>
      <section className="bg-black font-inter antialiase text-gray-200 tracking-tight">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="py-12 md:py-20">

            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
              <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">New Arrivals</div>
              <h1 className="h2 mb-4">One product, unlimited Features</h1>
              <p className="text-l text-gray-400">A product with unlimited features is a versatile offering that can cater to a wide range of needs and requirements. It can streamline workflows, boost productivity, and enhance efficiency, making it an essential tool for various industries. Its flexibility and customization options provide a tailored experience, making it a valuable investment.</p>
            </div>

            {/* Items */}
            <div className="grid gap-20 m-6">

              {/* 1st item */}
              <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                {/* Image */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                  <img className="max-w-full mx-auto md:max-w-none h-auto w-[50%] h-[50%]" src={FeatImage01} width="540" height="405" alt="Features 01" />
                </div>
                {/* Content */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
                  <div className="md:pr-4 lg:pr-12 xl:pr-16">
                    <div className="font-architects-daughter text-xl text-purple-600 mb-2">Arcade adventure

                      <a class="ml-6 whitespace-nowrap text-sm font-semibold text-sky-500 hover:text-sky-600" href="https://play.google.com/store/apps/details?id=com.giga.gameden&pli=1">Get it now <span aria-hidden="true">→</span></a>

                    </div>
                    <h3 className="h3 mb-3">GAME DEN - Hare meets Tortoise</h3>
                    <p className="text-l text-gray-400 mb-4">Step into the world of Den and experience a unique blend of arcade gaming and a marketplace where you can trade items with players from all over the world!</p>
                    <ul className="text-lg text-gray-400 -mb-2">
                      <li className="flex items-center mb-2">
                        <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>One Platform, Many Games</span>
                      </li>
                      <li className="flex items-center mb-2">
                        <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>In-Game MarketPlace</span>
                      </li>
                      <li className="flex items-center">
                        <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>P2 Data Protection & Privacy</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* 2nd item */}
              <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                {/* Image */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl" data-aos="fade-up">
                  <img className="max-w-full mx-auto md:max-w-none h-auto w-[70%] h-[70%]" src={FeatImage02} width="540" height="405" alt="Features 02" />
                </div>
                {/* Content */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-left">
                  <div className="md:pl-4 lg:pl-12 xl:pl-16">
                    <div className="font-architects-daughter text-xl text-purple-600 mb-2">More features, better sound
                      <a class="ml-6 whitespace-nowrap text-sm font-semibold text-sky-500 hover:text-sky-600" href="https://play.google.com/store/apps/details?id=com.giga.reecho">Get it now <span aria-hidden="true">→</span></a>
                    </div>
                    <h3 className="h3 mb-3">Reecho - Music & Audio Player</h3>
                    <p className="text-l text-gray-400 mb-4">Powerful and versatile music player for your Android device. Look no further than Reecho! Our app offers a stunning interface that is both easy to use and highly customizable. With features like artist and album pages, auto-equalizer, gapless playback, and more, you can enjoy your music like never before. Whether you prefer to listen to your own music library, Reecho has everything you need to create the perfect audio experience.</p>
                    <ul className="text-lg text-gray-400 -mb-2">
                      <li className="flex items-center mb-2">
                        <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>45 Languages</span>
                      </li>
                      <li className="flex items-center mb-2">
                        <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>17 theme colors</span>
                      </li>
                      <li className="flex items-center">
                        <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>Enhancing audio tools</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* 3rd item */}
              <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                {/* Image */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                  <img className="max-w-full mx-auto md:max-w-none h-auto w-[70%] h-[70%] rounded-full" src={FeatImage03} width="540" height="405" alt="Features 03" />
                </div>
                {/* Content */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
                  <div className="md:pr-4 lg:pr-12 xl:pr-16">
                    <div className="font-architects-daughter text-xl text-purple-600 mb-2">Keep your records in touch.
                      <a class="ml-6 whitespace-nowrap text-sm font-semibold text-sky-500 hover:text-sky-600" href="https://play.google.com/store/apps/details?id=com.nucesmess.nucesmess">Get it now <span aria-hidden="true">→</span></a>
                    </div>
                    <h3 className="h3 mb-3">FAST-NUCES Hostels</h3>
                    <p className="text-l text-gray-400 mb-4">NU Hostel mobile application is a comprehensive tool that facilitates students of FAST-NUCES residing in the hostel. The app helps students in keeping track of mess attendance, room allotment, and fee payments, making their lives simpler and more organized. With the ability to store and retrieve data in real-time, students can easily access important information about their hostel, and make informed decisions accordingly. The app's user-friendly interface and easy navigation enable students to use it effortlessly.</p>
                    <ul className="text-lg text-gray-400 -mb-2">
                      <li className="flex items-center mb-2">
                        <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>All-In-One data platform</span>
                      </li>
                      <li className="flex items-center mb-2">
                        <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>In-app payments</span>
                      </li>
                      <li className="flex items-center">
                        <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>P2 Data Protection & Privacy</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>
    </div>
  );
}

export default FeaturesZigzag;