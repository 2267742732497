import React, { useState } from 'react';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import '../css/circles.css'; 

const games = [
  {
    id: 1,
    title: 'Tic-Tac-Toe',
    description: "Experience the sheer joy of outsmarting your opponents as you strategically place your X's and O's on the grid. Will you claim victory with a flawless line of three or be forced to strategize for a well-timed block? Every move counts, and each decision brings you one step closer to triumph!",
    genre: 'Board Game',
    releaseDate: '2022-01-01',
    imageUrl: 'https://user-images.githubusercontent.com/34218434/34315815-e87c761c-e764-11e7-95fc-32c8f07b7552.png',
  },
  // Add more game objects as needed
];

const GameCard = ({ game, onClick }) => {
  return (
    <div className="p-4">
      <div
        className="h-full border-2 border-gray-200 rounded-lg overflow-hidden cursor-pointer"
        onClick={() => onClick(game.id)}
      >
        <img
          className="h-48 w-full object-cover object-center"
          src={game.imageUrl}
          alt={game.title}
        />
        <div className="p-6">
          <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">
            {game.genre}
          </h2>
          <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
            {game.title}
          </h1>
          <p className="leading-relaxed mb-3">{game.description}</p>
        </div>
      </div>
    </div>
  );
};

const GamePage = ({ game }) => {
  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-4">{game.title}</h1>
      <img
        className="h-48 w-full object-cover object-center mb-4"
        src={game.imageUrl}
        alt={game.title}
      />
      <p className="text-lg mb-4">{game.description}</p>
      <p className="text-gray-500 italic">Genre: {game.genre}</p>
      <p className="text-gray-500 italic">Release Date: {game.releaseDate}</p>
      {/* Add more details as needed */}
    </div>
  );
};

const App = () => {
  const [selectedGameId, setSelectedGameId] = useState(null);

  const handleGameClick = (gameId) => {
    setSelectedGameId(gameId);
  };

  return (
    <div><Header />
    <div className="container mx-auto px-4 py-8">
      {selectedGameId ? (
        <GamePage game={games.find((game) => game.id === selectedGameId)} />
      ) : (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mt-32">
          {games.map((game) => (
            <GameCard key={game.id} game={game} onClick={handleGameClick} />
          ))}
        </div>
      )}
    </div>
    <Footer /></div>
  );
};

export default App;
