import React from 'react';
import { FaCircleNotch, FaSync } from 'react-icons/fa';
import { FadeLoader } from 'react-spinners';

const DevResearch = () => {
    return (
        <div id="devscrollblack" className="w-full h-screen flex flex-col items-center justify-between">
            <div className="xl:w-1/2 flex-1 flex flex-col items-center justify-center text-center px-4 lg:px-0">
                <svg className="w-3/4" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 945 583.2243" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <path id="b0cf38c7-358d-48dc-a444-e9782686dfa5-94" data-name="Path 438" d="M222.88482,693.5243a24.21463,24.21463,0,0,0,23.38269-4.11877c8.18977-6.87442,10.758-18.196,12.84671-28.68191l6.17972-31.01657-12.93769,8.90836c-9.30465,6.40641-18.81827,13.01866-25.26012,22.29785s-9.25222,21.94708-4.07792,31.988" transform="translate(-127.5 -158.38785)" fill="#e6e6e6"></path>
                    {/* Other SVG paths */} 
                </svg>
                <FaSync className="text-gray-800" />
                <p className="text-4xl font-bold text-gray-700 capitalize tracking-wide mt-8">Section under maintenance!</p>
                <p className="text-xl text-gray-700 uppercase mt-4">We'll be back soon</p>
            </div>
            <div className="w-full py-4 border-t border-gray-300">
                <div className="container mx-auto flex flex-col md:flex-row items-center justify-center text-gray-600 text-sm md:space-x-8 space-y-1 md:space-y-0">
                    <span className="font-bold">You can contact us:</span>
                    {/* <a href="#" className="flex items-center space-x-1" target="_blank" title="Call">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                        </svg>
                        <span>+1 123-123-1234</span>
                    </a> */}
                    <a href="#" className="flex items-center space-x-1" target="_blank" title="Email">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                        </svg>
                        <span>contact@gigadevden.com</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default DevResearch;