import React from 'react';

import HeaderBlack from '../partials/HeaderBlack.jsx';
import Testimonials from '../partials/Testimonials.jsx';
import Footer from '../partials/Footer.jsx';


function DeleteGameDenAccount() {
    return (


        <div className='flex flex-col min-h-screen justify-center items-center border-red-500 border-t-4'>
    <div className="flex">
        {/* Main content */}
        <div className="bg-black">
            <p className="text-white text-center">Mail at contact@gigadevden.com to get your gameden account data deleted</p>
        </div>
    </div>
</div>

    );
}

export default DeleteGameDenAccount;