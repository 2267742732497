import { useState } from 'react';

function SidebarMenu() {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const [isOpen2, setIsOpen2] = useState(false);

    const openConsole = (event) => {
        event.preventDefault();
        window.location.href = "/console-home";
      };

    return (
        <div className="relative z-50">
            
            <button className="flex items-center px-7 py-2 text-gray-200 border-teal-400 hover:text-red-500 lg:hidden" onClick={handleToggle}>
                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-6z" />
                </svg>
            </button>
            {isOpen && (
                <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a href="/" className="block px-4 py-2 text-md border text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                            <b>Home</b>
                        </a>
                       
                        {/* <p className="block px-4 py-2 text-sm text-gray-700" role="menuitem">
                            Services
                        </p>
                        <ul>
                            <li>
                            <a href="#" className="ml-5 rounded-tl-lg bg-red-500 block px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-gray-900 hover:ml-10" role="menuitem">
                            GameDen
                           </a>
                           <a href="#" className="ml-5 bg-red-500 block px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-gray-900 hover:ml-10" role="menuitem">
                            Edulabour
                           </a>
                           <a href="#" className="ml-5 rounded-bl-lg bg-red-500 block px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-gray-900 hover:ml-10" role="menuitem">
                            Piclet
                           </a>
                            </li>
                        </ul> */}
                        <p className="block px-4 py-2 text-sm text-gray-700" role="menuitem">
                            Content
                        </p>
                        <ul>
                            <li>
                            <a href="/projects" className="ml-5 rounded-tl-lg bg-red-500 block px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-gray-900 duration-300" role="menuitem">
                            Projects
                           </a>
                           <a href="/infodexeus/articles" className="ml-5 rounded-bl-lg bg-red-500 block px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-gray-900 duration-300" role="menuitem">
                            Articles
                           </a>
                           {/* <a href="#" className="ml-5 bg-red-500 block px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-gray-900 hover:ml-10" role="menuitem">
                            Articles
                           </a>
                           <a href="#" className="ml-5 bg-red-500 block px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-gray-900 hover:ml-10" role="menuitem">
                            Research
                           </a>
                           <a href="#" className="ml-5 rounded-bl-lg bg-red-500 block px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-gray-900 hover:ml-10" role="menuitem">
                            Downloads
                           </a> */}
                            </li>
                        </ul>
                        <a href="/products" className="block px-4 mt-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                            Products
                        </a>
                        <a href="/about" className="pt-4 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                            About us
                        </a>

                        <a href="/chat.den.ai" className="pt-4 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                        AI Chatbot

                        <span class="ml-2 rounded-full bg-pink-500 px-1.5 py-0.5 text-xs text-white">Beta</span>

                        </a>
                        
                        {/* <a
                            href="/barters"
                            className="mt-5 mr-10 rounded-tr-md rounded-br-md bg-black block px-4 py-2 text-sm text-white hover:bg-red-500 md:hidden"
                            role="menuitem"
                        >
                            Barters
                        </a> */}
                        {/* <form method="POST" action="#">
                            <button type="submit" className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                Sign in
                            </button>
                        </form> */}
                        <div className="xl:hidden">
            <button
              type="button"
              className="m-5 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-red-500"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded="true"
              onClick={openConsole}
            >
             

              
              Go to console
           
            
              <svg
                className="-mr-1 ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                />
                <path
                  fillRule="evenodd"
                  d="M3 10a2 2 0 114 0 2 2 0 01-4 0zM10 10a2 2 0 114 0 2 2 0 01-4 0zM17 10a2 2 0 114 0 2 2 0 01-4 0z"
                />
              </svg>
            </button>
          </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SidebarMenu;